import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  IconButton,
  Typography,
  DialogActions,
  DialogContent,
  DialogContentText,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { SECONDARY_UI_COLOR } from '../../config';

const styles = theme => ({
  root: {
    color: '#ffffff',
    margin: 0,
  },
  closeButton: {
    right: theme.spacing(1),
    top: theme.spacing(1),
    position: 'absolute',
    color: '#ffffff',
  },
});

const DialogTitle = withStyles(styles)(props => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function ConfirmButtonV2Dialog({
  iconButton, buttonText, buttonProps, confirmText, confirmButtonText, onConfirm,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();

    handleClose();
  };

  return (
    <>
      {iconButton && (
        <IconButton onClick={() => setOpen(true)} {...buttonProps}>
          {buttonText}
        </IconButton>
      )}

      {!iconButton && (
        <Button onClick={() => setOpen(true)} {...buttonProps}>
          {buttonText}
        </Button>
      )}

      <Dialog
        open={open}
        maxWidth="sm"
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle
          style={{ background: SECONDARY_UI_COLOR }}
          onClose={handleClose}
        >
          CAUTION
        </DialogTitle>

        <DialogContent style={{ background: '#F8F8F8' }}>
          <DialogContentText style={{ color: '#000000', margin: '12px 0' }}>
            {confirmText}
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ padding: '0 24px 24px 24px', background: '#F8F8F8' }}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                style={{ color: '#ffffff' }}
                onClick={handleConfirm}
                variant="contained"
                className="button"
                color="secondary"
                autoFocus
              >
                {confirmButtonText}
              </Button>
              <Button
                style={{ marginLeft: '10px' }}
                onClick={handleClose}
                className="button"
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

ConfirmButtonV2Dialog.defaultProps = {
  iconButton: false,
  buttonProps: {},
};

ConfirmButtonV2Dialog.propTypes = {
  iconButton: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  confirmTitle: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  buttonProps: PropTypes.object, // eslint-disable-line
};

export default ConfirmButtonV2Dialog;
