import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import {
  Container,
  makeStyles,
  CssBaseline,
  ThemeProvider,
} from '@material-ui/core';
import AppMenu from './menu/AppMenu';
import Notifier from './common/Notifier';
import { theme } from './common/theme';
import Router from './Router';
import { fetchUserCurrent } from '../redux/actions/iamActions';
import { fetchFileStorageConfiguration } from '../redux/actions/fileStorageActions';
import {
  IS_DESKTOP, IS_MOBILE, MENU_CLOSE, MENU_OPEN,
} from '../redux/actionTypes';
import { DEFAULT_BACK_PATHNAME, MAIN_UI_COLOR } from '../config';

const useStyles = makeStyles(t => ({
  root: {
    display: 'flex',
  },
  drawerOpen: {
    width: '241px',
  },
  drawerClose: {
    width: 0,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    minWidth: '241px',
    paddingBottom: t.spacing(2),
    backgroundColor: MAIN_UI_COLOR,
    color: '#ffffff',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    position: 'relative',
    background: '#f8f8f8',
  },
  iconProfileButton: {
    padding: 0,
    margin: '12px 0',
  },
  iconMenuButton: {
    padding: 0,
    margin: '12px 12px 12px 0',
  },
  iconMenu: {
    fontSize: '2rem',
  },
  snackSuccess: {
    background: MAIN_UI_COLOR,
    color: '#ffffff',
  },
}));

function Main() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isMenuOpen = useSelector(state => state.app.isMenuOpen);

  const isMobile = useMediaQuery({ query: '(max-width: 1020px)' });

  const { isSignedIn } = useSelector(state => state.auth);

  useEffect(() => {
    if (isSignedIn) {
      dispatch(fetchUserCurrent());
      dispatch(fetchFileStorageConfiguration());
    }
  }, [isSignedIn, dispatch]);

  useEffect(() => dispatch({ type: isMobile ? IS_MOBILE : IS_DESKTOP }), [isMobile]);
  useEffect(() => {
    if (!isMobile && !isMenuOpen) dispatch({ type: MENU_OPEN });
  }, [isMobile]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        classes={{
          variantSuccess: classes.snackSuccess,
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <div className={clsx('App', classes.root)}>
          <CssBaseline />

          {isSignedIn && (
            <AppMenu
              open={isMenuOpen}
              variant={isMobile ? 'temporary' : 'persistent'}
              onOpenChange={val => dispatch({ type: val ? MENU_OPEN : MENU_CLOSE })}
            />
          )}

          <main className={classes.content}>

            <Container style={{ padding: 0 }} maxWidth={false}>

              <Router />

              {DEFAULT_BACK_PATHNAME && <Redirect to={DEFAULT_BACK_PATHNAME} />}

            </Container>

            <Notifier />
          </main>
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default Main;
