import React, { useState } from 'react';
import {
  parseDiff, Diff, Hunk, Decoration,
} from 'react-diff-view';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

const useStyles = makeStyles(() => ({
  fileDiff: {
    border: '1px solid #ddd',
    marginBottom: '10px',
    width: '100%',
  },
  diffHeader: {
    borderBottom: '1px solid #ddd',
    backgroundColor: '#f6f8fa',
    padding: '5px 10px',
    fontSize: '12px',
    lineHeight: 2,
  },
  hunkHeader: {
    backgroundColor: '#f0f8ff',
    color: '#1b1f2380',
    padding: '5px 20px',
  },
  binFile: {
    color: '#00530e80',
  },
  diff: {
    fontSize: '12px',
  },
}));

function DiffDisplayer({ diffText }) {
  const classes = useStyles();
  const files = parseDiff(diffText);
  const [hidden, setHidden] = useState(false);

  const renderFile = ({
    oldPath,
    newPath,
    type,
    hunks,
    isBinary,
  }) => (
    <div key={uuidv4()} className={classes.fileDiff}>

      <div className={classes.diffHeader} role="button" onClick={() => setHidden(!hidden)} tabIndex={0} onKeyDown={() => null}>
        <span className="pr-2">
          {!hidden && (
            <FaArrowDown />
          )}

          {hidden && (
            <FaArrowUp />
          )}
        </span>
        {isBinary ? (<span className={`${classes.binFile} pr-2`}>BIN</span>) : ''}
        {oldPath === newPath ? oldPath : `${oldPath} -> ${newPath}`}
      </div>

      {!hidden && (
        <>
          <Diff viewType="split" diffType={type} hunks={hunks} className={classes.diff}>
            {items => items.map(item => [
              <Decoration key={`deco-${item.content}`}>
                <div className={classes.hunkHeader}>{item.content}</div>
              </Decoration>,
              <Hunk key={item.content} hunk={item} />,
            ])}
          </Diff>

          {isBinary && (
            <span className="m-2">Binary file not shown.</span>
          )}
        </>
      )}
    </div>
  );

  return (diffText !== '') ? (files.map(renderFile)) : null;
}

DiffDisplayer.propTypes = {
  diffText: PropTypes.string.isRequired,
};

export default DiffDisplayer;
