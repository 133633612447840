import {
  IS_MOBILE,
  IS_DESKTOP,
  MENU_OPEN,
  MENU_CLOSE,
  CLOSE_SNACKBAR,
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
} from '../actionTypes';

const initialState = {
  notifications: [],
  isMenuOpen: true,
  isMobile: false,
  appData: {},
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case IS_MOBILE:
      return {
        ...state,
        isMobile: true,
      };
    case IS_DESKTOP:
      return {
        ...state,
        isMobile: false,
      };
    case MENU_OPEN:
      return {
        ...state,
        isMenuOpen: true,
      };
    case MENU_CLOSE:
      return {
        ...state,
        isMenuOpen: false,
      };
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map(notification => (
          (action.dismissAll || notification.key === action.key)
            ? { ...notification, dismissed: true }
            : { ...notification }
        )),
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key,
        ),
      };
    default:
      return state;
  }
}
