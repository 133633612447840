import React from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import prettyBytes from 'pretty-bytes';
import { Grid, Link } from '@material-ui/core';
import { TextLabel, TextValue } from '../common/Utility';
import {
  FileEntity,
  TaskEntity,
} from '../../core/entities';
import { formatDateStr } from '../../core/services/commonService';
import DownloadLink from '../common/DownloadLink';
import PathBreadcrumbs from '../common/PathBreadcrumbs';
import {
  TASKS_ROUTE,
  FILE_STORAGE_ROUTE,
} from '../../core/constants';
import { MAIN_UI_COLOR } from '../../config';

const generateItems = file => [
  { label: 'File name', node: <TextValue style={{ overflowWrap: 'break-word' }}>{file.name}</TextValue> },
  { label: 'Size', node: <TextValue style={{ overflowWrap: 'break-word' }}>{prettyBytes(file.size)}</TextValue> },
  { label: 'Last modified', node: <TextValue style={{ overflowWrap: 'break-word' }}>{formatDateStr(file.updated)}</TextValue> },
];

const getTaskLink = task => {
  const to = `${TASKS_ROUTE}/${task.id}`;

  return <Link to={to} component={RouterLink}><TextValue>{task.id}</TextValue></Link>;
};

function FileDetailsInfo({ file, task }) {
  const history = useHistory();
  const items = generateItems(file);

  if (task && task.id) items.push({ label: 'Task ID', node: getTaskLink(task) });
  if (task && task.owner) items.push({ label: 'Created by', node: <TextValue>{task.owner}</TextValue> });
  if (task && task.converter) items.push({ label: 'Conversion type', node: <TextValue>{task.converter}</TextValue> });
  if (task && task.tag && task.tag !== 'default') items.push({ label: 'Task release', node: <TextValue>{task.tag}</TextValue> });
  if (task && task.source && task.source !== 'N/A') {
    items.push({
      label: 'Task input file',
      node: <TextValue><DownloadLink filepath={task.source} displayValue={task.source.split('/').slice(-1)[0]} /></TextValue>,
    });
  }

  const onLocationChange = value => history.push(`${FILE_STORAGE_ROUTE}${value}`);

  items.push({
    label: 'Location',
    node: <TextValue><PathBreadcrumbs path={`/${file.basename}`} namesMap={{ '': 'File Storage' }} onItemClick={value => onLocationChange(value)} itemsProp={{ style: { color: MAIN_UI_COLOR } }} /></TextValue>,
  });

  return (
    <Grid
      container
      style={{
        border: '1px solid rgba(51, 51, 51, 0.3)',
        borderRadius: '8px',
        background: 'white',
        marginTop: '32px',
      }}
    >
      <Grid style={{ padding: '24px' }} container item spacing={2}>
        {items.map(item => (
          <>
            <Grid item xs={6} sm={3} md={2} alignItems="center" justifyContent="flex-start">
              <TextLabel>{item.label}</TextLabel>
            </Grid>
            <Grid item xs={6} sm={9} md={10} alignItems="center">{item.node}</Grid>
          </>
        ))}
      </Grid>
    </Grid>
  );
}

FileDetailsInfo.defaultProps = {
  task: undefined,
};

FileDetailsInfo.propTypes = {
  file: PropTypes.instanceOf(FileEntity).isRequired,
  task: PropTypes.instanceOf(TaskEntity),
};

export default FileDetailsInfo;
