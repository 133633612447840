import React from 'react';
import {
  IconButton, InputAdornment, makeStyles, TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 380,
    paddingRight: 0,
  },
}));

function SearchField(props) {
  const classes = useStyles();
  const { onChange, placeholder, value } = props;

  return (
    <TextField
      className={classes.root}
      placeholder={placeholder}
      variant="outlined"
      size="small"
      value={value}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

SearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

SearchField.defaultProps = {
  placeholder: 'Find something',
  value: '',
};

export default SearchField;
