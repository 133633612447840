export const isInclude = (str, substr) => str.toLowerCase().includes(substr.toLowerCase());

export const isObjectEmpty = obj => (
  obj && Object.keys(obj).length === 0 && obj.constructor === Object
);

export const formatDateStr = d => {
  let date = d;
  if (typeof d === 'string') {
    date = new Date(d);
  }

  return date && date.toLocaleString('en-US');
};

export const is404 = errors => errors.some(e => e.status === 404);

export const splitStringValue = (value, delimeter, maxLength) => {
  let line = '';
  let currentLength = 0;

  if (value) {
    value.split(delimeter).forEach(element => {
      if (line) {
        if (currentLength + element.length > maxLength || element.length > maxLength) {
          line += `/\n${element}`;
          currentLength = 0;
        } else {
          line += `/${element}`;
          currentLength += element.length;
        }
      } else {
        line = element;
        currentLength = element.length;
      }
    });
  }

  return line;
};
