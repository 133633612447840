import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import {
  MenuItem, Button, IconButton, Checkbox, ListItemText,
} from '@material-ui/core';
import * as yup from 'yup';
import { MdInfoOutline, MdOutlineAddCircle } from 'react-icons/md';
import Placeholder from '../common/Placeholder';
import CreateTaskDialog from './CreateTaskDialog';
import { showErrorSnackbar } from '../../redux/actions/appActions';
import {
  ENTITY_TYPE_FILE,
  TASK_CONVERTER_IXIASOFT_IMPORT,
  TASK_CONVERTER_IXIASOFT_EXPORT,
} from '../../core/entities';
import {
  createIxiasoftExportTask,
  createIxiasoftImportTask,
  fetchIxiasoftProducts,
  fetchIxiasoftObjectTypes,
  fetchIxiasoftStatuses,
  fetchIxiasoftUsers,
  fetchIxiasoftPing,
} from '../../redux/actions/taskActions';
import { applySearch, parsePath } from '../../core/services/fileStorageService';
import {
  checkFileExist,
  getObjectsFromPath,
  getPathEntity,
} from '../../redux/actions/fileStorageActions';
import ExportFiltersDialog from './ExportFiltersDialog';
import { TASKS_ROUTE } from '../../core/constants';
import { getCorrectPath } from '../common/Utility';

const STEP_FIELD_TYPE_MULTIPLE = 'multiple';
const STEP_FIELD_TYPE_PATH = 'path';

const TYPES = {
  Import: TASK_CONVERTER_IXIASOFT_IMPORT,
  Export: TASK_CONVERTER_IXIASOFT_EXPORT,
};

const SEARCH_FIELDS = {
  fulltext: 'Full Text',
  filename: 'Filename',
  title: 'Title',
};

function filterObjects(objects, searchValue) {
  return applySearch(objects, searchValue).sort((a, b) => b.type.localeCompare(a.type));
}

const validationSchema = taskType => {
  let schema = {
    username: yup.string().required('The username is required'),
    password: yup.string().required('The password is required'),
  };

  if (taskType === TASK_CONVERTER_IXIASOFT_IMPORT) {
    const taskImportSchema = {
      product: yup.string().required('The product is required'),
      release: yup.string().required('The release is required'),
      version: yup.string().required('The version is required'),
      sourceFilePath: yup.string()
        .required('Source file path is required')
        .test(
          'start_end_with_space',
          'The element of the source file path can not start or end with spaces',
          function (v) {
            const value = getCorrectPath(v, this.parent.sourceFilePathSearchValue);

            let result = true;
            value.split('/').forEach(path => {
              if (path.startsWith(' ') || path.endsWith(' ')) {
                result = false;
              }
            });

            return result;
          },
        )
        .test(
          'allowed-characters',
        <>
          Source file name can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        function (v) {
          const { sourceFilePathSearchValue } = this.parent;
          const value = `${v}/${sourceFilePathSearchValue}`;

          return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
        },
        )
        .test(
          'not-new-lines',
          'Source file name cannot contain the new lines',
          function (v) {
            const { sourceFilePathSearchValue } = this.parent;
            const value = `${v}/${sourceFilePathSearchValue}`;

            return value && !value.includes('\n');
          },
        )
        .test(
          'file-exist',
          'Source file does not exist or is not a file entity',
          async function (v) {
            const { sourceFilePathSearchValue } = this.parent;
            const value = `${v}/${sourceFilePathSearchValue}`;

            if (value) {
              const response = await getPathEntity(value);

              return response.status === 200 && response.data.data.type === ENTITY_TYPE_FILE;
            }

            return false;
          },
        ),
    };

    schema = Object.assign(schema, taskImportSchema);
  }

  if (taskType === TASK_CONVERTER_IXIASOFT_EXPORT) {
    const taskExportSchema = {
      outputFolderPath: yup.string()
        .test(
          'required',
          'Output folder path is required',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            return value && value !== '';
          },
        )
        .test(
          'start_end_with_space',
          'The element of the output folder path can not start or end with spaces',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            let result = true;
            value.split('/').forEach(path => {
              if (path.startsWith(' ') || path.endsWith(' ')) {
                result = false;
              }
            });

            return result;
          },
        )
        .test(
          'allowed-characters',
        <>
          Output folder can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        function (v) {
          const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

          return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
        },
        )
        .test(
          'not-new-lines',
          'Output folder name cannot contain the new lines',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            return value && !value.includes('\n');
          },
        )
        .test(
          'not-contain-extension',
          'Output folder name cannot contain the extensions',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            return value && !value.includes('.zip');
          },
        )
        .test(
          'folder-exist',
          'Output folder does not exist or is not a folder entity',
          async function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            if (value) {
              const parentResponse = await getPathEntity(value.split('/')[0]);
              if (!(
                parentResponse.status === 200
                  && parentResponse.data.data.type !== ENTITY_TYPE_FILE
              )
              ) {
                return this.createError({ message: 'Output parent folder does not exist' });
              }

              const valueResponse = await getPathEntity(value);

              return (
                valueResponse.status === 200 && valueResponse.data.data.type !== ENTITY_TYPE_FILE
              );
            }

            return false;
          },
        ),
      outputFilename: yup.string()
        .required('Output file name is required')
        .test(
          'start_end_with_space',
          'The output filename can not start or end with spaces',
          v => !(v.startsWith(' ') || v.endsWith(' ')),
        )
        .test(
          'allowed-characters',
        <>
          Output file name can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        v => /^[a-zA-Z0-9\s/\-._]+$/.test(v),
        )
        .test('not-new-lines', 'Output file name cannot contain the new lines', v => v && !v.includes('\n'))
        .test('is-zip', "Output file name should end with '.zip'", v => v && v.endsWith('.zip'))
        .test('not-starts-with-slash', "Output file name cannot contain '/'", v => v && !v.includes('/'))
        .test('is-file', 'Output file name cannot consist of an extension only', v => v && v !== '.zip'),
    };

    schema = Object.assign(schema, taskExportSchema);
  }

  return yup.object(schema);
};

const generateInitValues = task => {
  const config = JSON.parse(task.config || '{}');

  const [sourceFilePath, sourceFilename] = parsePath(task.source || '');
  const [outputFolderPath, outputFilename] = parsePath(task.output || '');

  return {
    // general
    password: '',
    username: config.user || '',
    serverUrl: config.server || '',

    // ixiasoft import
    product: '',
    release: '',
    version: '',
    prefix: config.prefix || undefined,

    replaceDoctypes: config.replace_doctypes || false,
    checkoutObjects: config.checkout_objects || false,
    insertContainerRef: config.insert_container_ref || false,
    replaceKefToKeyref: config.replace_kef_to_keyref || false,

    sourceFilePath: sourceFilePath || '',
    sourceFilePathSearchValue: sourceFilename || '',
    sourceFilePathObjects: [],

    // ixiasoft export
    maps: config.maps || [],
    status: config.status || [],
    topics: config.topics || [],
    images: config.iamges || [],
    products: config.products || [],
    branches: config.branches || [],
    searchField: config.search_field || 'fulltext',
    searchValue: config.search_value || '',
    assignedBy: config.assigned_by || [],
    assignedTo: config.assigned_to || [],
    asyncLoad: config.async_load || false,
    splitFiles: config.split_files || false,
    checkedOutBy: config.checked_out_by || [],
    lastModifiedBy: config.last_modified_by || [],
    generateReports: config.generate_reports || false,
    replaceToOasis: config.replace_to_oasis || false,
    lastModificationDate: config.last_modification_date || '',

    outputFolderPathSearchValue: '',
    outputFolderPathObjects: [],
    outputFolderPath,
    outputFilename,

    createFolders: false,
  };
};

function CreateIxiasoftDialog(props) {
  const {
    open,
    onClose,
    rerunTask,
    resetOnClose,
  } = props;

  const dispatch = useDispatch();

  const [products, setProducts] = useState([]);
  const [releases, setReleases] = useState([]);
  const [versions, setVersions] = useState([]);

  const [users, setUsers] = useState([]);
  const [fetchingUsers, setFetchingUsers] = useState(false);

  const [statuses, setStatuses] = useState([]);
  const [fetchingStatuses, setFetchingStatuses] = useState(false);

  const [maps, setMaps] = useState([]);
  const [topics, setTopics] = useState([]);
  const [images, setImages] = useState([]);
  const [fecthingObjectTypes, setFetchingObjectTypes] = useState(false);

  const [branches, setBranches] = useState([]);
  const [fetchingProducts, setFetchingProducts] = useState(false);

  const [filters, setFilters] = useState([]);
  const [creatingTask, setCreatingTask] = useState(false);
  const [filtersDialogOpen, setFiltersDialogOpen] = useState(false);

  const [taskType, setTaskType] = useState(rerunTask.converter || '');

  const [loadingOutputFolderObjects, setLoadingOutputFolderObjects] = useState(false);
  const [loadingSourceFileObjects, setLoadingSourceFileObjects] = useState(false);

  const [enabledService, setEnabledService] = useState(false);

  const [firstStepError, setFirstStepError] = useState('');
  const [creatingStepError, setCreatingStepError] = useState(undefined);

  const [outputFileWarning, setOutputFileWarning] = useState(undefined);

  const formik = useFormik({
    initialValues: generateInitValues(rerunTask || {}),
    validationSchema: validationSchema(taskType),
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
  });

  const handleClose = link => {
    onClose(link);

    formik.resetForm();
  };

  const onFormSubmit = values => {
    setCreatingTask(true);

    if (taskType === TASK_CONVERTER_IXIASOFT_IMPORT) {
      const task = {
        source: `${values.sourceFilePath}${values.sourceFilePath.endsWith('/') ? '' : '/'}${values.sourceFilePathSearchValue}`,
        drm_version: `${values.product}/${values.release}/${values.version}`,
        replace_kef_to_keyref: values.replaceKefToKeyref,
        insert_container_ref: values.insertContainerRef,
        checkout_objects: values.checkoutObjects,
        replace_doctypes: values.replaceDoctypes,
        username: values.username,
        password: values.password,
        server: values.serverUrl,
        prefix: values.prefix,
      };

      dispatch(createIxiasoftImportTask(task))
        .then(res => handleClose(`${TASKS_ROUTE}/${res.id}`))
        .catch(() => setCreatingTask(false));
    }

    if (taskType === TASK_CONVERTER_IXIASOFT_EXPORT) {
      let outputFolder = `${values.outputFolderPath}${values.outputFolderPath.endsWith('/') ? '' : '/'}`;
      if (values.outputFolderPathSearchValue !== '') {
        outputFolder += `${values.outputFolderPathSearchValue}${values.outputFolderPathSearchValue.endsWith('/') ? '' : '/'}`;
      }

      const task = {
        output: `${outputFolder}${values.outputFilename}`,
        last_modification_date: values.lastModificationDate,
        generate_reports: values.generateReports,
        replace_to_oasis: values.replaceToOasis,
        last_modified_by: values.lastModifiedBy,
        checked_out_by: values.checkedOutBy,
        search_value: values.searchValue,
        search_field: values.searchField,
        assigned_to: values.assignedTo,
        assigned_by: values.assignedBy,
        split_files: values.splitFiles,
        async_load: values.asyncLoad,
        branches: values.branches,
        products: values.products,
        username: values.username,
        password: values.password,
        server: values.serverUrl,
        images: values.images,
        topics: values.topics,
        status: values.status,
        maps: values.maps,
      };

      dispatch(createIxiasoftExportTask(task))
        .then(res => handleClose(`${TASKS_ROUTE}/${res.id}`))
        .catch(() => setCreatingTask(false));
    }
  };

  const rerunTaskConfig = rerunTask && rerunTask.config ? JSON.parse(rerunTask.config) : null;

  const handleFiltersChange = values => {
    const diff = filters.filter(x => !values.includes(x));

    diff.forEach(filter => {
      const value = formik.values[filter];

      if (Array.isArray(value)) formik.setFieldValue(filter, []);
      else if (typeof value === 'boolean') formik.setFieldValue(filter, false);
      else formik.setFieldValue(filter, '');
    });

    setFilters(values);
  };

  useEffect(() => {
    if (open && enabledService && taskType === TASK_CONVERTER_IXIASOFT_EXPORT) {
      setFetchingStatuses(true);

      dispatch(fetchIxiasoftStatuses())
        .then(entities => {
          if (!entities) {
            dispatch(showErrorSnackbar('Can not load the statuses'));
            return [];
          }

          return entities;
        })
        .then(setStatuses)
        .finally(() => setFetchingStatuses(false));

      setFetchingObjectTypes(true);

      dispatch(fetchIxiasoftObjectTypes())
        .then(entities => {
          if (!entities) {
            dispatch(showErrorSnackbar('Can not load the object types'));
            return;
          }

          const [mapEntities, topicEntities, imageEntities] = [[], [], []];

          entities.forEach(entity => {
            switch (entity.type) {
              case 'map':
                mapEntities.push(entity);
                break;

              case 'topic':
                topicEntities.push(entity);
                break;

              case 'image':
                imageEntities.push(entity);
                break;

              default:
                break;
            }
          });

          setMaps(mapEntities);
          formik.setFieldValue('maps', mapEntities.map(o => o.value));

          setTopics(topicEntities);
          formik.setFieldValue('topics', topicEntities.map(o => o.value));

          setImages(imageEntities);
          formik.setFieldValue('images', imageEntities.map(o => o.value));

          setFetchingObjectTypes(false);
        });

      setFetchingProducts(true);

      dispatch(
        fetchIxiasoftProducts(
          formik.values.serverUrl,
          formik.values.username,
          formik.values.password,
        ),
      )
        .then(productEntities => {
          if (!productEntities) {
            dispatch(showErrorSnackbar('Can not load the products'));
            return;
          }

          setProducts(productEntities);

          const branchEntities = [];
          productEntities.forEach(entity => {
            entity.children.forEach(child => {
              branchEntities.push(...child.children);
            });
          });

          setBranches(branchEntities);
          setFetchingProducts(false);

          setFetchingUsers(true);

          dispatch(
            fetchIxiasoftUsers(
              formik.values.serverUrl,
              formik.values.username,
              formik.values.password,
            ),
          )
            .then(userEntities => {
              if (!userEntities) {
                dispatch(showErrorSnackbar('Can not load the users'));
                return;
              }

              userEntities.sort((a, b) => {
                const aName = a.name.toLowerCase();
                const bName = b.name.toLowerCase();

                if (aName > bName) return 1;
                if (bName > aName) return -1;
                return 0;
              });

              setUsers(userEntities);
              setFetchingUsers(false);
            });
        });
    }
  }, [open, taskType, enabledService]);

  useEffect(() => {
    if (open && enabledService && taskType === TASK_CONVERTER_IXIASOFT_IMPORT) {
      setFetchingProducts(true);

      dispatch(
        fetchIxiasoftProducts(
          formik.values.serverUrl,
          formik.values.username,
          formik.values.password,
        ),
      )
        .then(productsEntities => {
          if (!productsEntities) {
            dispatch(showErrorSnackbar('Can not load the products'));
            return [];
          }

          return productsEntities;
        })
        .then(values => {
          setProducts(values);

          if (rerunTaskConfig) {
            const [product, release, version] = rerunTaskConfig.drm_version.split('/');

            formik.setFieldValue('product', product);

            let releasesList = [];
            values.forEach(v => { if (product === v.title) releasesList = v.children; });
            setReleases(releasesList);

            formik.setFieldValue('release', release);

            let versionsList = [];
            releasesList.forEach(v => { if (release === v.title) versionsList = v.children; });
            setVersions(versionsList);

            formik.setFieldValue('version', version);
          }
        })
        .finally(() => setFetchingProducts(false));
    }
  }, [enabledService]);

  const onCreateButtonClick = async () => {
    if (creatingTask) return;
    setCreatingTask(true);
    setCreatingStepError(undefined);

    const value = await fetchIxiasoftPing(
      formik.values.serverUrl,
      formik.values.username,
      formik.values.password,
      taskType === TYPES.Import,
    );

    if (typeof value === 'boolean' && value) onFormSubmit(formik.values);
    else {
      setCreatingStepError(value);
      setCreatingTask(false);
    }
  };

  useEffect(() => {
    if (open && taskType === TASK_CONVERTER_IXIASOFT_EXPORT) {
      setLoadingOutputFolderObjects(true);

      const value = getCorrectPath(formik.values.outputFolderPath, '');

      getObjectsFromPath(`/${value}`)
        .then(objects => {
          formik.setFieldValue('outputFolderPathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('outputFolderPath').then(() => setLoadingOutputFolderObjects(false));
            });
        });
    }
  }, [enabledService, taskType, formik.values.outputFolderPath]);

  useEffect(() => {
    if (open && taskType === TASK_CONVERTER_IXIASOFT_IMPORT) {
      setLoadingSourceFileObjects(true);

      getObjectsFromPath(`/${formik.values.sourceFilePath}`)
        .then(objects => {
          formik.setFieldValue('sourceFilePathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('sourceFilePath').then(() => setLoadingSourceFileObjects(false));
            });
        });
    }
  }, [enabledService, taskType, formik.values.sourceFilePath]);

  const onProductChange = event => {
    formik.handleChange(event);

    let value = [];
    products.forEach(product => {
      if (event.target.value === product.title) value = product.children;
    });

    setReleases(value);

    formik.setFieldValue('release', '');

    setVersions([]);
    formik.setFieldValue('version', '');
  };

  const onReleaseChange = event => {
    formik.handleChange(event);

    let value = [];
    releases.forEach(release => {
      if (event.target.value === release.title) value = release.children;
    });

    setVersions(value);
    formik.setFieldValue('version', '');
  };

  const onChangePathValue = (value, fieldName, searchFieldName) => {
    const split = value.split('/');

    const search = split[split.length - 1];
    const path = `${split.slice(0, -1).join('/')}`;

    if (path !== '/' || formik.values[fieldName] !== path) formik.setFieldValue(fieldName, path);
    formik.setFieldValue(searchFieldName, search).then(() => formik.validateField(fieldName));
  };

  const config = {
    title: 'Create Ixiasoft',
    steps: [
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'General settings',
        fields: [
          {
            tooltip: (
              <>
                <p>
                  It should be the URL to the Ixiasoft REST server.
                </p>
                <p>
                  For example,
                </p>
                <p>
                  <b>https://ryffine.eval.ixiasoft.com/ixiasoft-ccms/rest/1.0</b>
                </p>
              </>
            ),
            onChange: event => formik.handleChange(event),
            value: formik.values.serverUrl,
            error: formik.errors.serverUrl,
            placeholder: 'Server url',
            label: 'Server url',
            type: 'serverUrl',
            key: 'serverUrl',
          },
          {
            tooltip: (
              <>
                <p>
                  The value that you use for authorization into the Ixiasoft CCMS Web application.
                </p>
                <p>
                  The value should be a combination of domain and username separated by a backslash.
                </p>
                <p>
                  <b>&apos;domain\first_name.last_name&apos;</b>
                </p>
                <p>
                  Or the value could be only username.
                </p>
                <p>
                  <b>&apos;first_name.last_name&apos;</b>
                  {' '}
                  or
                  {' '}
                  <b>&apos;username&apos;</b>
                </p>
              </>
            ),
            onChange: event => formik.handleChange(event),
            value: formik.values.username,
            error: formik.errors.username,
            placeholder: 'Username',
            label: 'Username',
            key: 'username',
          },
          {
            onChange: event => formik.handleChange(event),
            value: formik.values.password,
            error: formik.errors.password,
            placeholder: 'Password',
            label: 'Password',
            type: 'password',
            key: 'password',
          },
          {
            item: ([key, value]) => <MenuItem key={value} value={value}>{key}</MenuItem>,
            onChange: event => setTaskType(event.target.value),
            items: Object.entries(TYPES),
            renderValue: selected => {
              if (selected === '') return <Placeholder>Task type</Placeholder>;

              let returnValue = selected;
              Object.entries(TYPES).forEach(([key, value]) => {
                if (selected === value) returnValue = key;
              });

              return returnValue;
            },
            label: 'Task type',
            error: undefined,
            value: taskType,
            key: 'taskType',
          },
        ],
        isValid: taskType !== ''
          && (formik.values.username !== '' && formik.errors.username === undefined)
          && (formik.values.password !== '' && formik.errors.password === undefined)
          && (formik.values.serverUrl !== '' && formik.errors.serverUrl === undefined),
        async onSubmit() {
          setFirstStepError('');
          setEnabledService(false);

          const value = await fetchIxiasoftPing(
            formik.values.serverUrl,
            formik.values.username,
            formik.values.password,
            taskType === TYPES.Import,
          );

          if (typeof value === 'boolean' && value) setEnabledService(true);
          else setFirstStepError(value);

          return typeof value === 'boolean' ? value : false;
        },
        stepTooltip: taskType === TYPES.Import ? (
          <p style={{ margin: 0 }}>
            <span><MdInfoOutline size="16px" /></span>
            {' '}
            <span>
              The ixiasoft import process requires the templates, accessible by API. One template
              should be for the map files and one for the topic files.
            </span>
          </p>
        ) : undefined,
        allowContinue: taskType !== '',
        error: firstStepError,
        loading: false,
      },
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'Task details',
        fields: [],
        allowContinue: true,
        loading: false,
        isValid: true,
      },
    ],
    onSubmit: () => onCreateButtonClick(),
    loading: creatingTask,
    initActiveStep: 0,
  };

  if (taskType === TASK_CONVERTER_IXIASOFT_IMPORT) {
    config.steps[1] = {
      type: STEP_FIELD_TYPE_MULTIPLE,
      title: 'Task details',
      fields: [
        {
          tooltip: (
            <>
              <p>
                The first part of the ixiasoft DRM object (product or library).
              </p>
              <p>
                We require this value for the identified place where we need to import the content.
              </p>
              <p>
                The list of values is uploaded from the remote server by the server URL, username,
                and password from the previous step.
              </p>
            </>
          ),
          item: p => <MenuItem key={p.path} value={p.title}>{p.title}</MenuItem>,
          renderValue: selected => {
            if (selected === '') return <Placeholder>Product/Library</Placeholder>;

            let returnValue = selected;
            products.forEach(p => {
              if (selected === p.key) returnValue = p.title;
            });

            return returnValue;
          },
          onChange: event => onProductChange(event),
          value: formik.values.product,
          loading: fetchingProducts,
          label: 'Product/Library',
          items: products,
          key: 'product',
        },
        {
          tooltip: (
            <>
              <p>
                The second part of the ixiasoft DRM object (release).
              </p>
              <p>
                We require this value for the identified place where we need to import the content.
              </p>
              <p>
                The list of values is uploaded from the remote server by the server URL, username,
                and password from the previous step.
              </p>
            </>
          ),
          item: r => <MenuItem key={r.path} value={r.title}>{r.title}</MenuItem>,
          renderValue: selected => {
            if (selected === '') return <Placeholder>Release</Placeholder>;

            let returnValue = selected;
            releases.forEach(r => {
              if (selected === r.key) returnValue = r.title;
            });

            return returnValue;
          },
          onChange: event => onReleaseChange(event),
          value: formik.values.release,
          loading: fetchingProducts,
          label: 'Release',
          items: releases,
          key: 'release',
        },
        {
          tooltip: (
            <>
              <p>
                The last part of the ixiasoft DRM object (version).
              </p>
              <p>
                We require this value for the identified place where we need to import the content.
              </p>
              <p>
                The list of values is uploaded from the remote server by the server URL, username,
                and password from the previous step.
              </p>
            </>
          ),
          item: v => <MenuItem key={v.path} value={v.title}>{v.title}</MenuItem>,
          renderValue: selected => {
            if (selected === '') return <Placeholder>Version</Placeholder>;

            let returnValue = selected;
            versions.forEach(v => {
              if (selected === v.key) returnValue = v.title;
            });

            return returnValue;
          },
          onChange: event => formik.handleChange(event),
          value: formik.values.version,
          loading: fetchingProducts,
          label: 'Version',
          items: versions,
          key: 'version',
        },
        {
          tooltip: (
            <>
              <p>
                It is an optional field. The default value is a timestamp when the import process
                starts.
              </p>
              <p>
                Recommend using it when you&apos;re not sure that the content is valid and
                potentially could have a problem on the checkout step (if you select the
                &quot;Checkout imported objects&quot; (approve and validate content on
                the ixiasoft server) into configurations). It allows us to identify or
                search the incorrect files by searching this prefix value.
              </p>
            </>
          ),
          onChange: event => formik.handleChange(event),
          value: formik.values.prefix,
          placeholder: 'Prefix',
          label: 'Prefix',
          key: 'prefix',
        },
      ],
      configurations: [
        {
          tooltip: (
            <>
              <p>
                If the value equals True, the maps and topics will be unlocked (if
                the file is locked, only the user who edited the document can see
                the changes).
              </p>
              <p>
                <b>Notice.</b>
                {' '}
                Recommend using this value for all import requests. During
                the checkout of the objects, the server will validate the content in files.
                If some file has a problem with validation we will see it in the logs.
              </p>
            </>
          ),
          onChange: event => formik.handleChange(event),
          value: formik.values.checkoutObjects,
          label: 'Checkout imported objects',
          key: 'checkoutObjects',
        },
        {
          tooltip: (
            <>
              <p>
                If the value equals True, the
                {' '}
                <b>&apos;href(s)&apos;</b>
                {' '}
                and
                {' '}
                <b>&apos;conref(s)&apos;</b>
                {' '}
                will be replaced with
                {' '}
                <b>&apos;keyref(s)&apos;</b>
                {' '}
                and
                {' '}
                <b>&apos;conkeyref(s)&apos;</b>
                .
              </p>
              <p>
                <b>Notice.</b>
                {' '}
                Use this value for the content which contains references with href and conref
                attributes.
              </p>
            </>
          ),
          onChange: event => formik.handleChange(event),
          value: formik.values.replaceKefToKeyref,
          label: (
            <>
              Replace
              {' '}
              <b>&apos;ref&apos;</b>
              {' '}
              to
              {' '}
              <b>&apos;keyref&apos;</b>
              {' '}
            </>
          ),
          key: 'replaceKefToKeyref',
        },
        {
          tooltip: (
            <>
              <p>
                If the value equals True, the
                {' '}
                <b>&apos;containerref&apos;</b>
                {' '}
                element will be added to the maps.
              </p>
              <p>
                <b>Notice.</b>
                {' '}
                Use this value for the content which does not contain the &quot;containerref&quot;
                element for the DRM object that you select in this step (product/release/version).
              </p>
            </>
          ),
          onChange: event => formik.handleChange(event),
          value: formik.values.insertContainerRef,
          label: 'Insert container ref',
          key: 'insertContainerRef',
        },
        {
          tooltip: (
            <>
              <p>
                If the value equals True, the maps and topics doctypes will be replaced with the
                ixiasoft doctypes.
              </p>
              <p>
                <b>Notice.</b>
                {' '}
                Use this value for the content which does contain the OASIS doctypes or doctypes
                that are not supported by your Ixiasoft CCMS server.
              </p>
            </>
          ),
          onChange: event => formik.handleChange(event),
          value: formik.values.replaceDoctypes,
          label: 'Replace doctypes',
          key: 'replaceDoctypes',
        },
      ],
      allowContinue: formik.values.product !== '' && formik.values.release !== '' && formik.values.version !== '',
      isValid: formik.values.product !== '' && formik.values.release !== '' && formik.values.version !== '',
      onSubmit: () => Promise.resolve(true),
      loading: false,
    };

    config.steps.push({
      title: 'Source file path',
      type: STEP_FIELD_TYPE_PATH,
      pathField: {
        objects: filterObjects(
          formik.values.sourceFilePathObjects, formik.values.sourceFilePathSearchValue,
        ),
        onChange: value => onChangePathValue(value, 'sourceFilePath', 'sourceFilePathSearchValue'),
        value: formik.values.sourceFilePath !== ''
          ? `${formik.values.sourceFilePath}${formik.values.sourceFilePath.endsWith('/') ? '' : '/'}${formik.values.sourceFilePathSearchValue}`
          : formik.values.sourceFilePathSearchValue,
        error: formik.errors.sourceFilePath,
        loading: false,
      },
      allowContinue: formik.values.sourceFilePath !== '',
      isValid: formik.errors.sourceFilePath === undefined,
      onSubmit: () => Promise.resolve(true),
      loading: loadingSourceFileObjects,
      error: creatingStepError,
    });
  }

  const renderObjectTypeItem = (item, items) => (
    <MenuItem key={item.value} value={item.value}>
      <Checkbox style={{ padding: '0 9px 0 0' }} checked={items.indexOf(item.value) > -1} />
      <ListItemText primary={item.value} />
    </MenuItem>
  );

  const renderContextItem = (item, items) => (
    <MenuItem key={item.path} value={item.path}>
      <Checkbox style={{ padding: '0 9px 0 0' }} checked={items.indexOf(item.path) > -1} />
      <ListItemText primary={item.title} />
    </MenuItem>
  );

  const renderUserItem = (item, items) => (
    <MenuItem key={item.username} value={`${item.username}@${item.domain}`}>
      <Checkbox
        checked={items.indexOf(`${item.username}@${item.domain}`) > -1}
        style={{ padding: '0 9px 0 0' }}
      />
      <ListItemText primary={`${item.name} - ${item.email}`} />
    </MenuItem>
  );

  const renderUserValue = (selected, label) => {
    if (selected.length <= 0) return <Placeholder>{label || 'Select'}</Placeholder>;

    const displayValues = [];
    users.forEach(user => {
      const key = `${user.username}@${user.domain}`;

      if (selected.indexOf(key) > -1) displayValues.push(user.name);
    });

    return displayValues.join(', ');
  };

  useEffect(() => {
    const value = formik.values.products;
    const branchEntities = [];

    if (value.length > 0) {
      products.forEach(product => {
        if (value.indexOf(product.path) > -1) {
          product.children.forEach(child => branchEntities.push(...child.children));
        }
      });
    } else {
      products.forEach(product => {
        product.children.forEach(child => branchEntities.push(...child.children));
      });
    }

    setBranches(branchEntities);
    formik.setFieldValue('branches', []);
  }, [formik.values.products]);

  let outputFolder = `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}`;
  if (formik.values.outputFolderPathSearchValue !== '') {
    outputFolder += `${formik.values.outputFolderPathSearchValue}${formik.values.outputFolderPathSearchValue.endsWith('/') ? '' : '/'}`;
  }

  if (taskType === TASK_CONVERTER_IXIASOFT_EXPORT) {
    let allowCreateFolders = false;
    if (formik.errors.outputFolderPath !== undefined) {
      if (formik.errors.outputFolderPath === 'Output folder does not exist or is not a folder entity') {
        allowCreateFolders = true;
      }
    }

    const fields = [
      {
        item: key => <MenuItem key={key} value={key}>{SEARCH_FIELDS[key]}</MenuItem>,
        onChange: event => formik.handleChange(event),
        items: Object.keys(SEARCH_FIELDS),
        value: formik.values.searchField,
        error: formik.errors.searchField,
        label: 'Search in',
        key: 'searchField',
      },
      {
        onChange: event => formik.handleChange(event),
        value: formik.values.searchValue,
        error: formik.errors.searchValue,
        placeholder: 'Search for',
        label: 'Search for',
        key: 'searchValue',
      },
    ];

    if (filters.indexOf('objectTypes') > -1) {
      fields.push({
        item: map => renderObjectTypeItem(map, formik.values.maps),
        renderValue: selected => {
          if (selected === '' || (Array.isArray(selected) && selected.length === 0)) {
            return <Placeholder>Maps</Placeholder>;
          }

          return selected.join(', ');
        },
        onChange: event => {
          const { value } = event.target;
          if (Array.isArray(value) && value[value.length - 1] === 'all') {
            setTimeout(
              () => {
                const currentValue = formik.values.maps;

                let listValue = maps.map(m => m.value);
                if (
                  currentValue.length === maps.length
                  || (currentValue.length !== 0 && currentValue.length !== maps.length)
                ) {
                  listValue = [];
                }

                formik.setFieldValue('maps', [...listValue]);
              },
              0,
            );
            return;
          }

          formik.handleChange(event);
        },
        value: formik.values.maps,
        enableSelectAll: true,
        multiple: true,
        label: 'Maps',
        items: maps,
        key: 'maps',
      });

      fields.push({
        item: topic => renderObjectTypeItem(topic, formik.values.topics),
        renderValue: selected => {
          if (selected === '' || (Array.isArray(selected) && selected.length === 0)) {
            return <Placeholder>Topics</Placeholder>;
          }

          return selected.join(', ');
        },
        onChange: event => {
          const { value } = event.target;
          if (Array.isArray(value) && value[value.length - 1] === 'all') {
            setTimeout(
              () => {
                const currentValue = formik.values.topics;

                let listValue = topics.map(m => m.value);
                if (
                  currentValue.length === topics.length
                  || (currentValue.length !== 0 && currentValue.length !== topics.length)
                ) {
                  listValue = [];
                }

                formik.setFieldValue('topics', [...listValue]);
              },
              0,
            );
            return;
          }

          formik.handleChange(event);
        },
        value: formik.values.topics,
        enableSelectAll: true,
        multiple: true,
        label: 'Topics',
        items: topics,
        key: 'topics',
      });

      fields.push({
        item: image => renderObjectTypeItem(image, formik.values.images),
        renderValue: selected => {
          if (selected === '' || (Array.isArray(selected) && selected.length === 0)) {
            return <Placeholder>Images</Placeholder>;
          }

          return selected.join(', ');
        },
        onChange: event => {
          const { value } = event.target;
          if (Array.isArray(value) && value[value.length - 1] === 'all') {
            setTimeout(
              () => {
                const currentValue = formik.values.images;

                let listValue = images.map(m => m.value);
                if (
                  currentValue.length === images.length
                  || (currentValue.length !== 0 && currentValue.length !== images.length)
                ) {
                  listValue = [];
                }

                formik.setFieldValue('images', [...listValue]);
              },
              0,
            );
            return;
          }

          formik.handleChange(event);
        },
        value: formik.values.images,
        enableSelectAll: true,
        multiple: true,
        label: 'Images',
        items: images,
        key: 'images',
      });
    }

    if (filters.indexOf('context') > -1) {
      fields.push({
        renderValue: selected => {
          if (selected.length === 0) return <Placeholder>Products / Libraries</Placeholder>;

          const selectedProducts = products.filter(
            product => formik.values.products.indexOf(product.path) > -1,
          );

          const values = selectedProducts.map(product => product.title);

          return values.join(', ');
        },
        item: product => renderContextItem(product, formik.values.products),
        onChange: event => {
          const { value } = event.target;
          if (Array.isArray(value) && value[value.length - 1] === 'all') {
            setTimeout(
              () => {
                const currentValue = formik.values.products;

                let listValue = products.map(v => v.path);
                if (
                  currentValue.length === products.length
                  || (currentValue.length !== 0 && currentValue.length !== products.length)
                ) {
                  listValue = [];
                }

                formik.setFieldValue('products', [...listValue]);
              },
              0,
            );
            return;
          }

          formik.handleChange(event);
        },
        value: formik.values.products,
        label: 'Products / Libraries',
        loading: fecthingObjectTypes,
        enableSelectAll: true,
        items: products,
        key: 'products',
        multiple: true,
      });

      fields.push({
        renderValue: selected => {
          if (selected.length === 0) return <Placeholder>Branches</Placeholder>;

          const selectedBranches = branches.filter(
            branch => formik.values.branches.indexOf(branch.path) > -1,
          );

          const values = selectedBranches.map(branch => branch.title);

          return values.join(', ');
        },
        item: branch => renderContextItem(branch, formik.values.branches),
        onChange: event => {
          const { value } = event.target;
          if (Array.isArray(value) && value[value.length - 1] === 'all') {
            setTimeout(
              () => {
                const currentValue = formik.values.branches;

                let listValue = branches.map(v => v.path);
                if (
                  currentValue.length === branches.length
                  || (currentValue.length !== 0 && currentValue.length !== branches.length)
                ) {
                  listValue = [];
                }

                formik.setFieldValue('branches', [...listValue]);
              },
              0,
            );
            return;
          }

          formik.handleChange(event);
        },
        value: formik.values.branches,
        loading: fecthingObjectTypes,
        enableSelectAll: true,
        label: 'Branches',
        items: branches,
        key: 'branches',
        multiple: true,
      });
    }

    if (filters.indexOf('status') > -1) {
      fields.push({
        item: status => (
          <MenuItem key={status.status} value={status.status}>
            <Checkbox style={{ padding: '0 9px 0 0' }} checked={formik.values.status.indexOf(status.status) > -1} />
            <ListItemText primary={status.status} />
          </MenuItem>
        ),
        renderValue: selected => {
          if (selected === '' || (Array.isArray(selected) && selected.length === 0)) {
            return <Placeholder>Images</Placeholder>;
          }

          return selected.join(', ');
        },
        onChange: event => {
          const { value } = event.target;
          if (Array.isArray(value) && value[value.length - 1] === 'all') {
            setTimeout(
              () => {
                const currentValue = formik.values.status;

                let listValue = statuses.map(v => v.status);
                if (
                  currentValue.length === statuses.length
                  || (currentValue.length !== 0 && currentValue.length !== statuses.length)
                ) {
                  listValue = [];
                }

                formik.setFieldValue('status', [...listValue]);
              },
              0,
            );
            return;
          }

          formik.handleChange(event);
        },
        value: formik.values.status,
        loading: fetchingStatuses,
        enableSelectAll: true,
        items: statuses,
        label: 'Status',
        multiple: true,
        key: 'status',
      });
    }

    if (filters.indexOf('checkedOutBy') > -1) {
      fields.push({
        renderValue: selected => renderUserValue(selected, 'Checked out by'),
        item: checkedOutBy => renderUserItem(checkedOutBy, formik.values.checkedOutBy),
        onChange: event => {
          const { value } = event.target;
          if (Array.isArray(value) && value[value.length - 1] === 'all') {
            setTimeout(
              () => {
                const currentValue = formik.values.checkedOutBy;

                let listValue = users.map(m => `${m.username}@${m.domain}`);
                if (
                  currentValue.length === users.length
                  || (currentValue.length !== 0 && currentValue.length !== users.length)
                ) {
                  listValue = [];
                }

                formik.setFieldValue('checkedOutBy', [...listValue]);
              },
              0,
            );
            return;
          }

          formik.handleChange(event);
        },
        value: formik.values.checkedOutBy,
        label: 'Checked out by',
        loading: fetchingUsers,
        enableSelectAll: true,
        key: 'checkedOutBy',
        multiple: true,
        items: users,
      });
    }

    if (filters.indexOf('lastModifiedBy') > -1) {
      fields.push({
        renderValue: selected => renderUserValue(selected, 'Last modified by'),
        item: lastModifiedBy => renderUserItem(lastModifiedBy, formik.values.lastModifiedBy),
        onChange: event => {
          const { value } = event.target;
          if (Array.isArray(value) && value[value.length - 1] === 'all') {
            setTimeout(
              () => {
                const currentValue = formik.values.lastModifiedBy;

                let listValue = users.map(m => `${m.username}@${m.domain}`);
                if (
                  currentValue.length === users.length
                  || (currentValue.length !== 0 && currentValue.length !== users.length)
                ) {
                  listValue = [];
                }

                formik.setFieldValue('lastModifiedBy', [...listValue]);
              },
              0,
            );
            return;
          }

          formik.handleChange(event);
        },
        value: formik.values.lastModifiedBy,
        label: 'Last modified by',
        loading: fetchingUsers,
        enableSelectAll: true,
        key: 'lastModifiedBy',
        multiple: true,
        items: users,
      });
    }

    if (filters.indexOf('assignedBy') > -1) {
      fields.push({
        renderValue: selected => renderUserValue(selected, 'Assigned by'),
        item: assignedBy => renderUserItem(assignedBy, formik.values.assignedBy),
        onChange: event => {
          const { value } = event.target;
          if (Array.isArray(value) && value[value.length - 1] === 'all') {
            setTimeout(
              () => {
                const currentValue = formik.values.assignedBy;

                let listValue = users.map(m => `${m.username}@${m.domain}`);
                if (
                  currentValue.length === users.length
                  || (currentValue.length !== 0 && currentValue.length !== users.length)
                ) {
                  listValue = [];
                }

                formik.setFieldValue('assignedBy', [...listValue]);
              },
              0,
            );
            return;
          }

          formik.handleChange(event);
        },
        value: formik.values.assignedBy,
        loading: fetchingUsers,
        enableSelectAll: true,
        label: 'Assigned by',
        key: 'assignedBy',
        multiple: true,
        items: users,
      });
    }

    if (filters.indexOf('assignedTo') > -1) {
      fields.push({
        renderValue: selected => renderUserValue(selected, 'Assigned to'),
        item: assignedTo => renderUserItem(assignedTo, formik.values.assignedTo),
        onChange: event => {
          const { value } = event.target;
          if (Array.isArray(value) && value[value.length - 1] === 'all') {
            setTimeout(
              () => {
                const currentValue = formik.values.assignedTo;

                let listValue = users.map(m => `${m.username}@${m.domain}`);
                if (
                  currentValue.length === users.length
                  || (currentValue.length !== 0 && currentValue.length !== users.length)
                ) {
                  listValue = [];
                }

                formik.setFieldValue('assignedTo', [...listValue]);
              },
              0,
            );
            return;
          }

          formik.handleChange(event);
        },
        value: formik.values.assignedTo,
        loading: fetchingUsers,
        enableSelectAll: true,
        label: 'Assigned to',
        key: 'assignedTo',
        multiple: true,
        items: users,
      });
    }

    config.steps[1] = {
      title: 'Task details and filters settings',
      type: STEP_FIELD_TYPE_MULTIPLE,
      additionalButton: (
        <Button
          onClick={() => setFiltersDialogOpen(true)}
          className="button"
          color="primary"
        >
          <IconButton className="outlined-button-icon"><MdOutlineAddCircle /></IconButton>
          Additional filters
        </Button>
      ),
      configurations: [
        {
          tooltip: 'If the value equals True, the IXIASOFT content will be converter to the OASIS content.',
          onChange: event => formik.handleChange(event),
          value: formik.values.replaceToOasis,
          label: 'Convert to OASIS content',
          key: 'replaceToOasis',
        },
        {
          tooltip: 'If the value equals True, the export reports will be generated.',
          onChange: event => formik.handleChange(event),
          value: formik.values.generateReports,
          label: 'Generate reports',
          key: 'generateReports',
        },
        {
          tooltip: 'If the value equals True, the files will be loaded in a few processes.',
          onChange: event => formik.handleChange(event),
          value: formik.values.asyncLoad,
          label: 'Async load',
          key: 'asyncLoad',
        },
        {
          tooltip: 'If the value equals True, the files will be splitted into three folders: maps, topics and images.',
          onChange: event => formik.handleChange(event),
          value: formik.values.splitFiles,
          label: 'Split files',
          key: 'splitFiles',
        },
      ],
      onSubmit: () => Promise.resolve(true),
      allowContinue: true,
      loading: false,
      isValid: true,
      fields,
    };

    config.steps.push({
      title: 'Output file path',
      type: STEP_FIELD_TYPE_PATH,
      displayValue: `${outputFolder}${formik.values.outputFilename}`,
      displayNode: (
        <span
          dangerouslySetInnerHTML={{  // eslint-disable-line
            __html: `${outputFolder.replaceAll(' ', '&nbsp;').replaceAll('/', ' / ')}<b>${formik.values.outputFilename.replaceAll(' ', '&nbsp;')}</b>`,
          }}
        />
      ),
      fields: [
        {
          onChange: event => formik.handleChange(event),
          value: formik.values.outputFilename,
          error: formik.errors.outputFilename,
          placeholder: 'Example.zip',
          label: 'Output file name',
          key: 'outputFilename',
        },
      ],
      pathField: {
        label: 'Output folder path',
        placeholder: 'Search a folder by name',
        objects: filterObjects(
          formik.values.outputFolderPathObjects, formik.values.outputFolderPathSearchValue,
        ).filter(o => o.type === 'folder'),
        onChange: value => {
          onChangePathValue(value, 'outputFolderPath', 'outputFolderPathSearchValue');
          formik.setFieldValue('createFolders', false);
        },
        value: formik.values.outputFolderPath !== ''
          ? `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}${formik.values.outputFolderPathSearchValue}`
          : formik.values.outputFolderPathSearchValue,
      },
      configurations: allowCreateFolders ? [
        {
          tooltip: (
            <p>
              <p>
                The output folder does not exist. If you select this parameter,
                the folder will be created automatically.
              </p>
              <p>
                <b>Notice. </b>
                Use this parameter carefully.
              </p>
              <p>
                THE PARENT FOLDER MUST EXIST.
              </p>
            </p>
          ),
          onChange: event => formik.handleChange(event),
          value: formik.values.createFolders,
          label: 'Create Folders',
          key: 'createFolders',
        },
      ] : [],
      allowContinue: formik.values.outputFolderPath !== ''
        && (formik.errors.outputFolderPath === undefined || formik.values.createFolders)
        && (formik.values.outputFilename !== '' && formik.errors.outputFilename === undefined),
      isValid: formik.values.outputFolderPath !== ''
        && (formik.values.outputFilename !== '' && formik.errors.outputFilename === undefined),
      error: creatingStepError || formik.errors.outputFolderPath,
      onSubmit: () => Promise.resolve(true),
      loading: loadingOutputFolderObjects,
      warning: outputFileWarning,
    });
  }

  useEffect(() => {
    const fileId = `${outputFolder}${formik.values.outputFilename}`;

    if (formik.values.outputFolderPath === outputFolder) {
      const exist = formik.values.outputFolderPathObjects.some(file => file.id === fileId);

      if (exist) setOutputFileWarning('The output file already exists');
      else setOutputFileWarning(undefined);
    } else {
      dispatch(checkFileExist(fileId))
        .then(() => setOutputFileWarning('The output file already exists'))
        .catch(() => setOutputFileWarning(undefined));
    }
  }, [
    formik.values.outputFolderPath,
    formik.values.outputFolderPathSearchValue,
    formik.values.outputFolderPathObjects,
    formik.values.outputFilename,
  ]);

  useEffect(() => {
    if (open && formik.values.username !== '') formik.validateField('username');
  }, [formik.values.username]);

  useEffect(() => {
    if (open && formik.values.password !== '') formik.validateField('password');
  }, [formik.values.password]);

  useEffect(() => {
    if (open && formik.values.outputFilename !== '') formik.validateField('outputFilename');
  }, [formik.values.outputFilename]);

  return (
    <>
      <CreateTaskDialog
        open={open}
        config={config}
        onClose={() => {
          onClose();

          if (resetOnClose) {
            setTaskType('');
            setFirstStepError(undefined);
            formik.setValues(generateInitValues({}));
          }
        }}
        resetOnClose={resetOnClose}
      />

      <ExportFiltersDialog
        rerunTask={rerunTask}
        open={filtersDialogOpen}
        onSubmit={handleFiltersChange}
        onClose={() => setFiltersDialogOpen(false)}
      />
    </>

  );
}

CreateIxiasoftDialog.defaultProps = { rerunTask: {}, resetOnClose: true };

CreateIxiasoftDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  resetOnClose: PropTypes.bool,
  rerunTask: PropTypes.object,
};

export default CreateIxiasoftDialog;
