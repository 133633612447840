import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  MenuItem,
  FormControl,
  FormHelperText,
  Typography,
  TextField,
  makeStyles,
} from '@material-ui/core';
import * as yup from 'yup';
import RyffineDialogTitle from '../common/RyffineDialogTitle';
import { isObjectEmpty } from '../../core/services/commonService';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        padding: '8px 0px 8px 16px',
      },
      '& .MuiButtonBase-root': {
        padding: '8px',
      },
      background: '#FFFFFF',
      borderRadius: '4px',
      padding: 0,
    },
  },
  field: {
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        padding: '8px 0px 8px 16px',
      },
      '& .MuiButtonBase-root': {
        padding: '8px',
      },
      borderRadius: '4px',
      background: '#FFFFFF',
      padding: 0,
    },
  },
}));

const validationSchema = yup.object({
  parameterType: yup.string().required('The parameter type is required'),
  parameterName: yup.string()
    .required('Parameter name should be specified')
    .test(
      'reserved-names',
    <>
      The reserved parameters: &apos;input&apos; and &apos;output&apos;.
    </>,
    v => !['input', 'output'].includes(v),
    )
    .test(
      'allowed-characters',
    <>
      Parameter name can contain only the next characters:
      {' 0-9, a-z, A-Z, and \'_\'.'}
    </>,
    v => /^[a-zA-Z0-9_]+$/.test(v),
    ),
});

const ALLOWED_PARAMETER_TYPES = {
  text: 'Text Value',
  file: 'File',
};

const initValues = () => ({
  parameterType: Object.keys(ALLOWED_PARAMETER_TYPES)[0],
  parameterName: '',
});

function AddParameterDialog(props) {
  const {
    open,
    onClose,
    onSubmit,
    alreadyCreatedParams,
  } = props;

  const classes = useStyles();

  const onFormSubmit = values => {
    onSubmit(
      {
        type: values.parameterType,
        name: values.parameterName,
        default: values.default,
      },
    );
    onClose();
  };

  const formik = useFormik({
    initialValues: initValues(),
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onFormSubmit,
  });

  const clickAddButton = () => {
    if (alreadyCreatedParams.includes(formik.values.parameterName)) {
      const errors = {
        parameterName: `The parameter '${formik.values.parameterName}' already used`,
      };

      formik.setTouched(errors);
      formik.setErrors(errors);
    } else {
      formik.validateForm()
        .then(errors => {
          if (isObjectEmpty(errors)) {
            onFormSubmit(formik.values);
            formik.resetForm();
          } else {
            formik.setTouched(errors);
            formik.setErrors(errors);
          }
        });
    }
  };

  const fields = [
    {
      onChange: event => formik.handleChange(event),
      value: formik.values.parameterName,
      error: formik.errors.parameterName,
      key: 'parameterName',
      placeholder: 'Name',
      label: 'Name',
    },
    {
      items: Object.entries(ALLOWED_PARAMETER_TYPES)
        .map(([key, value]) => ({ label: value, name: key })),
      item: type => <MenuItem key={type.name} value={type.name}>{type.label}</MenuItem>,
      onChange: event => formik.handleChange(event),
      value: formik.values.parameterType,
      error: formik.errors.parameterType,
      key: 'parameterType',
      label: 'Type',
    },
  ];

  const errors = fields
    .filter(field => field.error && Boolean(field.error)).map(field => field.error)
    .sort((a, b) => b.length - a.length);

  return (
    <Dialog maxWidth="sm" fullWidth onClose={onClose} open={open}>
      <RyffineDialogTitle onClose={onClose}>
        Add new parameter
      </RyffineDialogTitle>

      <DialogContent style={{ background: '#F8F8F8' }}>
        <Grid container>
          {fields.map((field, i) => (
            <Grid
              style={
                i % 2
                  ? { paddingLeft: '12px', marginBottom: '10px' }
                  : { paddingRight: '12px', marginBottom: '10px' }
              }
              container
              xs={6}
              item
            >
              <Grid item container style={{ maxHeight: '21px' }}>
                <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
                  {field.label}
                </Typography>
              </Grid>

              <Grid item container style={{ padding: '5px 0' }}>
                <FormControl error={Boolean(field.error)} fullWidth>
                  <TextField
                    select={Object.keys(field).includes('items')}
                    error={field.error !== undefined}
                    placeholder={field.placeholder}
                    onChange={field.onChange}
                    className={classes.field}
                    value={field.value}
                    variant="outlined"
                    name={field.key}
                    id={field.key}
                  >
                    {field.items && field.items.map(field.item)}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          ))}

          {errors.length > 0
            && (errors.map(error => (
              <Grid item xs={12}>
                <FormHelperText error>{error}</FormHelperText>
              </Grid>
            ))
            )}

        </Grid>
      </DialogContent>

      <DialogActions style={{ padding: '10px 24px 24px 24px', background: '#F8F8F8' }}>
        <Grid container>
          <Grid item xs={12}>
            <Button
              onClick={clickAddButton}
              variant="contained"
              className="button"
              color="primary"
            >
              Add
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              className="button"
              onClick={onClose}
              color="primary"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

AddParameterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  alreadyCreatedParams: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AddParameterDialog;
