import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Loader from '../common/Loader';
import RyffineTable from '../common/RyffineTable';
import DownloadLink from '../common/DownloadLink';

const columns = [
  {
    field: 'key', headerName: 'Key', sortable: true, type: 'string',
  },
  {
    field: 'value', headerName: 'Value', sortable: true, type: 'string',
  },
];

const prepareTagsLinks = tags => {
  const getValue = obj => {
    if (obj.key === 'TASK_INPUT_FILE') {
      return (
        <DownloadLink filepath={obj.value} />
      );
    }

    return obj.value;
  };

  const returnValues = [];

  tags.map(tag => returnValues.push({
    key: tag.key,
    value: {
      value: tag.value,
      displayValue: getValue(tag),
    },
  }));

  return returnValues;
};

function FileDetailsTags({ tags, loading }) {
  if (loading) {
    return <Loader />;
  }

  return (
    <Grid container className="pt-3">
      <Grid item xs={12}>
        <RyffineTable
          tableContainerProps={{ style: { paddingTop: '24px' } }}
          rows={prepareTagsLinks(tags)}
          columns={columns}
        />
      </Grid>
    </Grid>
  );
}

FileDetailsTags.propTypes = {
  loading: PropTypes.bool.isRequired,
  tags: PropTypes.array.isRequired,
};

export default FileDetailsTags;
