import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  IconButton,
  Divider,
} from '@material-ui/core';
import { BiHelpCircle } from 'react-icons/bi';
import DOMPurify from 'dompurify';

function InfoDisplayer({ title, message }) {
  const [state, setState] = useState(false);

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  return (
    <React.Fragment key="right">
      <IconButton color="primary" size="small" onClick={toggleDrawer(true)}>
        <BiHelpCircle />
      </IconButton>

      <Drawer
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: '300px' }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <div className="p-2" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1rem' }}>
            {title}
          </div>
          <Divider />
          <div
            className="p-2"
            style={{ textAlign: 'justify', fontSize: '1rem' }}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} // eslint-disable-line
          />
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

InfoDisplayer.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default InfoDisplayer;
