import {
  FETCH_OBJECTS_FROM_PATH_START,
  FETCH_OBJECTS_FROM_PATH_SUCCESS,
  FETCH_OBJECTS_FROM_PATH_FAIL,
  DOWNLOADING_FILE_FINISH,
  DOWNLOADING_FILE_START,
  FETCH_FILE_TAGS_START,
  FETCH_FILE_TAGS_SUCCESS,
  FETCH_FILE_TAGS_FAIL,
  FETCH_ZIP_FILE_LIST_START,
  FETCH_ZIP_FILE_LIST_SUCCESS,
  FETCH_ZIP_FILE_LIST_FAIL,
  FETCH_FILE_STORAGE_CONFIGURATION_START,
  FETCH_FILE_STORAGE_CONFIGURATION_SUCCESS,
  FETCH_FILE_STORAGE_CONFIGURATION_FAIL,
} from '../actionTypes';
import {
  parseFileStorageConfigurationEntity,
  parseBasePathEntity,
  parseErrorEntity,
  parseTagEntity,
} from '../../core/parsers';

const initialState = {
  objects: [],
  file: {},
  errors: [],
  loading: false,
  downloading: false,
  tags: {
    loading: false,
    list: [],
  },
  zip: {
    loading: false,
    objects: [],
  },
  configuration: {
    loading: false,
    obj: {},
  },
};

export default function fileStorageReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_OBJECTS_FROM_PATH_START:
      return {
        ...state,
        objects: [],
        file: {},
        loading: true,
        errors: [],
      };
    case FETCH_OBJECTS_FROM_PATH_SUCCESS:
      return {
        ...state,
        loading: false,
        objects: Array.isArray(action.payload) ? action.payload.map(parseBasePathEntity) : [],
        file: Array.isArray(action.payload) ? {} : parseBasePathEntity(action.payload),
        errors: [],
      };
    case FETCH_OBJECTS_FROM_PATH_FAIL:
      return {
        ...state,
        objects: [],
        file: {},
        loading: false,
        errors: action.payload.map(parseErrorEntity),
      };

    case DOWNLOADING_FILE_START:
      return { ...state, downloading: true };
    case DOWNLOADING_FILE_FINISH:
      return { ...state, downloading: false };

    case FETCH_FILE_TAGS_START:
      return {
        ...state,
        tags: {
          loading: true,
          list: [],
        },
      };
    case FETCH_FILE_TAGS_SUCCESS:
      return {
        ...state,
        tags: {
          loading: false,
          list: action.payload.map(parseTagEntity),
        },
      };
    case FETCH_FILE_TAGS_FAIL:
      return {
        ...state,
        tags: {
          loading: false,
          list: [],
        },
      };

    case FETCH_ZIP_FILE_LIST_START:
      return {
        ...state,
        zip: {
          loading: true,
          objects: [],
        },
      };
    case FETCH_ZIP_FILE_LIST_SUCCESS:
      return {
        ...state,
        zip: {
          loading: false,
          objects: action.payload.map(parseBasePathEntity),
        },
      };
    case FETCH_ZIP_FILE_LIST_FAIL:
      return {
        ...state,
        zip: {
          loading: false,
          objects: [],
        },
      };

    case FETCH_FILE_STORAGE_CONFIGURATION_START:
      return {
        ...state,
        configuration: {
          obj: {},
          loading: true,
        },
      };
    case FETCH_FILE_STORAGE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        configuration: {
          obj: parseFileStorageConfigurationEntity(action.payload),
          loading: false,
        },
      };
    case FETCH_FILE_STORAGE_CONFIGURATION_FAIL:
      return {
        ...state,
        configuration: {
          obj: {},
          loading: false,
        },
      };

    default:
      return state;
  }
}
