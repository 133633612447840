import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Typography, Link } from '@material-ui/core';

const getBreadcrumbsItems = (path, onItemClick, namesMap, itemsProp) => {
  const validatedPath = path.endsWith('/') ? path.substring(0, path.length - 1) : path;
  const paths = validatedPath.split('/');

  const itemValues = [''];

  return paths.map((value, index) => {
    let linkText = value;
    if (value in namesMap) {
      linkText = namesMap[value];
    }

    if (index === paths.length - 1) {
      return (
        <Typography key={linkText} color="textPrimary">
          <span
            dangerouslySetInnerHTML={{ __html: linkText.replaceAll(' ', '&nbsp;') }}  // eslint-disable-line
          />
        </Typography>
      );
    }

    if (index > 0) {
      itemValues.push(`${itemValues[index - 1]}/${value}`);
    }

    return (
      <Link {...itemsProp} key={linkText} color="inherit" onClick={() => onItemClick(itemValues[index])}>
        <span
          dangerouslySetInnerHTML={{ __html: linkText.replaceAll(' ', '&nbsp;') }}  // eslint-disable-line
        />
      </Link>
    );
  });
};

function PathBreadcrumbs({
  path, onItemClick, namesMap, itemsProp,
}) {
  const items = getBreadcrumbsItems(path, onItemClick, namesMap, itemsProp);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {items.map(item => item)}
    </Breadcrumbs>
  );
}

PathBreadcrumbs.propTypes = {
  path: PropTypes.string.isRequired,
  onItemClick: PropTypes.func.isRequired,
  namesMap: PropTypes.objectOf(PropTypes.string),
  itemsProp: PropTypes.object,
};

PathBreadcrumbs.defaultProps = {
  namesMap: {},
  itemsProp: {},
};

export default PathBreadcrumbs;
