import React from 'react';
import { Typography } from '@material-ui/core';

function Validations() {
  return (
    <div>
      <Typography variant="h5">
        Validations page is coming soon.
      </Typography>
    </div>
  );
}

export default Validations;
