import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Box,
  List,
  Grow,
  Paper,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Drawer,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  makeStyles,
  createStyles,
} from '@material-ui/core';

import {
  MdAccountCircle,
  MdOutlineDashboard,
  MdOutlineTopic,
  MdOutlineAssessment,
} from 'react-icons/md';
import {
  BsGear,
  BsCheck2Circle,
} from 'react-icons/bs';
import { GiCrossMark } from 'react-icons/gi';
import { RiAdminLine } from 'react-icons/ri';

import logo from '../../assets/images/ryffine_big_logo.png';

import {
  FILE_STORAGE_ROUTE,
  TASKS_ROUTE,
  REPORTS_ROUTE,
  PREFERENCES_ROUTE,
  OXYGEN_COMPLETENESS_CHECK_ROUTE,
  OXYGEN_BATCH_CONVERTER_ROUTE,
  OXYGEN_METRIC_REPORT_ROUTE,
  ADMIN_REGRESSION_ROUTE,
  ADMIN_PACKAGES_ROUTE,
  ADMIN_USERS_ROUTE,
} from '../../core/constants';

import AppMenuItem from './AppMenuItem';
import { fetchUserCurrent } from '../../redux/actions/iamActions';
import {
  ENABLE_DASHBOARD, ENABLE_FILE_STORAGE_SERVICE, ENABLE_TASKS_SERVICE, MAIN_UI_COLOR,
} from '../../config';
import { userSignOut } from '../../redux/actions/authActions';

const ENV = process.env.REACT_APP_ENV || 'dev';

const appMenuItems = [];

if (ENABLE_DASHBOARD) {
  appMenuItems.push(
    {
      name: 'Dashboard',
      link: '/',
      Icon: MdOutlineDashboard,
    },
  );
}

if (ENABLE_FILE_STORAGE_SERVICE) {
  appMenuItems.push(
    {
      name: 'File Storage',
      link: FILE_STORAGE_ROUTE,
      Icon: MdOutlineTopic,
    },
  );
}

if (ENABLE_TASKS_SERVICE) {
  appMenuItems.push(
    {
      name: 'Tasks',
      link: TASKS_ROUTE,
      Icon: BsCheck2Circle,
    },
  );
}

if (ENV === 'dev') {
  appMenuItems.push(
    {
      name: 'Reports',
      link: REPORTS_ROUTE,
      Icon: MdOutlineAssessment,
    },
  );

  appMenuItems.push(
    {
      name: 'Oxygen',
      Icon: GiCrossMark,
      items: [
        {
          name: 'Completeness Check',
          link: OXYGEN_COMPLETENESS_CHECK_ROUTE,
        },
        {
          name: 'Batch Converter',
          link: OXYGEN_BATCH_CONVERTER_ROUTE,
        },
        {
          name: 'Metric Report',
          link: OXYGEN_METRIC_REPORT_ROUTE,
        },
      ],
    },
  );

  appMenuItems.push(
    {
      name: 'Admin',
      Icon: RiAdminLine,
      items: [
        {
          name: 'Regression',
          link: ADMIN_REGRESSION_ROUTE,
        },
        {
          name: 'Users',
          link: ADMIN_USERS_ROUTE,
        },
        {
          name: 'Packages',
          link: ADMIN_PACKAGES_ROUTE,
        },
      ],
    },
  );

  appMenuItems.push(
    {
      name: 'Preferences',
      link: PREFERENCES_ROUTE,
      Icon: BsGear,
    },
  );
}

const useStyles = makeStyles(() => createStyles({
  appMenu: {
    width: '100%',
  },
  menuItemIcon: {
    color: '#97c05c',
  },
  root: {
    display: 'flex',
  },
  drawerOpen: {
    width: '241px',
  },
  drawerClose: {
    width: 0,
  },
  drawerPaper: {
    backgroundColor: MAIN_UI_COLOR,
    position: 'relative',
    whiteSpace: 'nowrap',
    minWidth: '241px',
    color: '#ffffff',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    position: 'relative',
  },
  iconProfileButton: {
    padding: 0,
    margin: '12px 0',
  },
  iconMenuButton: {
    padding: 0,
    margin: '12px 12px 12px 0',
  },
  iconMenu: {
    fontSize: '2rem',
  },
}));

function AppMenu({ open, variant, onOpenChange }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, loading } = useSelector(state => state.iam.current_user);

  const [profileOpen, setProfileOpen] = useState(false);

  const anchorRef = useRef(null);

  useEffect(() => dispatch(fetchUserCurrent()), [dispatch]);

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setProfileOpen(false);
  };

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();

      setProfileOpen(false);
    }
  };

  const handleToggle = () => setProfileOpen(value => !value);

  const signOut = () => {
    dispatch(userSignOut());

    history.push('/');
  };

  return (
    <Drawer
      className={open ? classes.drawerOpen : classes.drawerClose}
      classes={{ paper: classes.drawerPaper }}
      onClose={() => onOpenChange(false)}
      variant={variant}
      open={open}
    >
      <Box
        component="img"
        sx={{
          marginTop: '25px',
          marginLeft: '25px',
          marginRight: '25px',
          marginBottom: '30px',
          backgroundColor: 'primary.dark',
          '&:hover': {
            backgroundColor: 'primary.main',
            opacity: [0.9, 0.8, 0.7],
          },
        }}
        alt="ryffine_logo"
        src={logo}
      />

      <List className={classes.appMenu} style={{ padding: 0 }}>
        {appMenuItems.map(item => (
          <AppMenuItem {...item} key={item.name} />
        ))}
      </List>

      {!loading && (
        <>
          <List component="div" style={{ marginTop: 'auto', padding: 0 }}>
            <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }} />

            <ListItem button ref={anchorRef} onClick={handleToggle}>
              <ListItemIcon style={{ minWidth: '40px', fontSize: '20px', color: '#fff' }}>
                <MdAccountCircle />
              </ListItemIcon>

              <ListItemText style={{ fontWeight: '500' }} primary={user.id} />
            </ListItem>
          </List>

          <Popper
            anchorEl={anchorRef.current}
            style={{ zIndex: 10, width: '220px' }}
            open={profileOpen}
            role={undefined}
            disablePortal
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList key="profileList" autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      <MenuItem key="profile" onClick={handleClose}>Profile</MenuItem>
                      <MenuItem key="signOut" onClick={signOut}>Sign out</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </Drawer>
  );
}

AppMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  onOpenChange: PropTypes.func.isRequired,
};

export default AppMenu;
