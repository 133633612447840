import { createTheme } from '@material-ui/core/styles';
import { MAIN_UI_COLOR, SECONDARY_UI_COLOR } from '../../config';

export const theme = createTheme({
  palette: {
    secondary: {
      main: SECONDARY_UI_COLOR,
    },
    primary: {
      main: MAIN_UI_COLOR,
    },
  },
  overrides: {
    MuiTab: {
      textColorPrimary: {
        color: 'rgba(0, 0, 0, 1)',
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '8px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '8px',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: 'black',
        '&:hover': {
          color: 'black',
          '&& $icon': {
            opacity: 1,
            color: 'black',
          },
        },
        '&$active': {
          color: 'black',
          '&& $icon': {
            opacity: 1,
            color: 'black',
          },
        },
      },
    },
    MuiTableCell: {
      head: {
        fontSize: '16px',
      },
      body: {
        fontSize: '14px',
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&.Mui-disabled': {
          color: '#FFFFFF',
          background: '#AAAAAA',
        },
      },
    },
    MuiStepConnector: {
      lineVertical: {
        minHeight: '12px',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1200,
      lg: 1500,
      xl: 1700,
    },
  },
});
