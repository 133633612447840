import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setToken } from '../../core/services/authService';
import { userSignIn } from '../../redux/actions/authActions';
import {
  DEFAULT_BACK_PATHNAME,
  configureAxios,
} from '../../config';
import { deleteCookie, getCookie } from '../../core/services/cookieService';
import { BACK_PATHNAME_COOKIE_NAME } from '../../core/constants';

function useQuery() {
  const hash = useLocation().hash.replace('#', '');
  return new URLSearchParams(hash);
}

function LoginSuccess() {
  const dispatch = useDispatch();
  const query = useQuery();

  let backPathname = getCookie(BACK_PATHNAME_COOKIE_NAME) || '';
  if (backPathname === '' || backPathname === '/') {
    if (DEFAULT_BACK_PATHNAME) backPathname = DEFAULT_BACK_PATHNAME;
  }

  const idToken = query.get('id_token');
  const expiresIn = query.get('expires_in');

  useEffect(() => {
    if (idToken && expiresIn) {
      setToken(idToken, expiresIn);
      configureAxios();

      deleteCookie(BACK_PATHNAME_COOKIE_NAME);
      dispatch(userSignIn({
        isSignedIn: true,
      }));
    }
  }, [idToken, expiresIn, dispatch]);

  return (
    <Redirect to={backPathname} />
  );
}

export default LoginSuccess;
