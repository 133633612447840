import { Tooltip, withStyles } from '@material-ui/core';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: '#333333',
    color: '#ffffff',
    maxWidth: 350,
  },
}))(Tooltip);

export default HtmlTooltip;
