import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { MenuItem, Grid, Typography } from '@material-ui/core';
import * as yup from 'yup';
import { MdInfoOutline } from 'react-icons/md';
import Placeholder from '../common/Placeholder';
import CreateTaskDialog from './CreateTaskDialog';
import { showErrorSnackbar } from '../../redux/actions/appActions';
import {
  ENTITY_TYPE_FILE,
  TASK_CONVERTER_PALIGO_PUBLISH,
  TASK_CONVERTER_PALIGO_IMPORT,
} from '../../core/entities';
import {
  createPaligoPublishTask,
  createPaligoImportTask,
  fetchPaligoPublishSettings,
  fetchPaligoShowDocument,
  fetchPaligoPing,
} from '../../redux/actions/taskActions';
import { applySearch, parsePath } from '../../core/services/fileStorageService';
import { checkFileExist, getObjectsFromPath, getPathEntity } from '../../redux/actions/fileStorageActions';
import { TASKS_ROUTE } from '../../core/constants';
import { getCorrectPath } from '../common/Utility';

const STEP_FIELD_TYPE_MULTIPLE = 'multiple';
const STEP_FIELD_TYPE_PATH = 'path';

const TYPES = {
  Publish: TASK_CONVERTER_PALIGO_PUBLISH,
  Import: TASK_CONVERTER_PALIGO_IMPORT,
};

const ALLOWED_SOURCE_TYPES = {
  file: {
    key: 'file',
    value: 'Single Archive File',
  },
  folder: {
    key: 'folder',
    value: 'Folder',
  },
};

function filterObjects(objects, searchValue) {
  return applySearch(objects, searchValue).sort((a, b) => b.type.localeCompare(a.type));
}

const validationSchema = (taskType, sourceDataType) => {
  let schema = {
    serverUrl: yup.string()
      .required('The server URL is required')
      .test(
        'allowed-value',
      <>
        The allowed value should be in the next format:
        <br />
        <b>http(s)://(domain or server_ip_address):(port is optional)</b>
      </>,
      v => /^(?:http(s)?:\/\/)[\w.-]+(:?[0-9]+)?$/.test(v),
      ),
    apiKey: yup.string().required('The server API key is required'),
    email: yup.string().required('The user email is required')
      .test(
        'valid-email',
      <>
        The email format is incorrect
      </>,
      v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v),
      ),
    folderLevels: yup.number()
      .required('The folder levels is required')
      .typeError('The folder levels value should be a integer')
      .integer('The folder levels value should be a integer'),
  };

  if (taskType === TASK_CONVERTER_PALIGO_IMPORT) {
    const taskImportSchema = {
      type: yup.string().required('The type is required'),
      openapiLanguages: yup.string()
        .test(
          'allowed-characters',
        <>
          The openapi languages field can contain only the next characters:
          <br />
          {'a-z and \';\'.'}
        </>,
        v => /^[a-z;]+$/.test(v),
        ),
      openapiMaxdepth: yup.number(),
      remotePath: yup.string()
        .required('The remote path is required')
        .test(
          'allowed-characters',
        <>
          Source file name can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        v => /^[a-zA-Z0-9\s/\-._]+$/.test(v),
        ),
    };

    if (sourceDataType === ALLOWED_SOURCE_TYPES.file.key) {
      schema.sourceFilePath = yup.string()
        .required('Source file path is required')
        .test(
          'start_end_with_space',
          'The element of the source file path can not start or end with spaces',
          function (v) {
            const value = getCorrectPath(v, this.parent.sourceFilePathSearchValue);

            let result = true;
            value.split('/').forEach(path => {
              if (path.startsWith(' ') || path.endsWith(' ')) {
                result = false;
              }
            });

            return result;
          },
        )
        .test(
          'allowed-characters',
        <>
          Source file name can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        function (v) {
          const { sourceFilePathSearchValue } = this.parent;
          const value = `${v}/${sourceFilePathSearchValue}`;

          return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
        },
        )
        .test(
          'not-new-lines',
          'Source file name cannot contain the new lines',
          function (v) {
            const { sourceFilePathSearchValue } = this.parent;
            const value = `${v}/${sourceFilePathSearchValue}`;

            return value && !value.includes('\n');
          },
        )
        .test(
          'file-exist',
          'Source file does not exist or is not a file entity',
          async function (v) {
            const { sourceFilePathSearchValue } = this.parent;
            const value = `${v}/${sourceFilePathSearchValue}`;

            if (value) {
              const response = await getPathEntity(value);

              return response.status === 200 && response.data.data.type === ENTITY_TYPE_FILE;
            }

            return false;
          },
        );
    }

    if (sourceDataType === ALLOWED_SOURCE_TYPES.folder.key) {
      schema.sourceFolderPath = yup.string()
        .required('Source folder path is required')
        .test(
          'start_end_with_space',
          'The element of the source folder path can not start or end with spaces',
          function (v) {
            const value = getCorrectPath(v, this.parent.sourceFilePathSearchValue);

            let result = true;
            value.split('/').forEach(path => {
              if (path.startsWith(' ') || path.endsWith(' ')) {
                result = false;
              }
            });

            return result;
          },
        )
        .test(
          'allowed-characters',
        <>
          Source folder can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        function (v) {
          const { sourceFolderPathSearchValue } = this.parent;
          const value = `${v}/${sourceFolderPathSearchValue}${sourceFolderPathSearchValue.endsWith('/') ? '' : '/'}`;

          return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
        },
        )
        .test(
          'not-new-lines',
          'Source folder name cannot contain the new lines',
          function (v) {
            const { sourceFolderPathSearchValue } = this.parent;
            const value = `${v}/${sourceFolderPathSearchValue}${sourceFolderPathSearchValue.endsWith('/') ? '' : '/'}`;

            return value && !value.includes('\n');
          },
        )
        .test(
          'folder-exist',
          'Source folder does not exist or is not a folder entity',
          async function (v) {
            const { sourceFolderPathSearchValue } = this.parent;
            const value = `${v}/${sourceFolderPathSearchValue}${sourceFolderPathSearchValue.endsWith('/') ? '' : '/'}`;

            if (value) {
              const response = await getPathEntity(value);

              return response.status === 200 && response.data.data.type !== ENTITY_TYPE_FILE;
            }

            return false;
          },
        );
    }

    schema = Object.assign(schema, taskImportSchema);
  }

  if (taskType === TASK_CONVERTER_PALIGO_PUBLISH) {
    const taskPublishSchema = {
      outputFilename: yup.string()
        .required('Output file name is required')
        .test(
          'start_end_with_space',
          'The output filename can not start or end with spaces',
          v => !(v.startsWith(' ') || v.endsWith(' ')),
        )
        .test(
          'allowed-characters',
        <>
          Output file name can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        v => /^[a-zA-Z0-9\s/\-._]+$/.test(v),
        )
        .test('not-new-lines', 'Output file name cannot contain the new lines', v => v && !v.includes('\n'))
        .test('is-zip', "Output file name should end with '.zip'", v => v && v.endsWith('.zip'))
        .test('not-starts-with-slash', "Output file name cannot contain '/'", v => v && !v.includes('/'))
        .test('is-file', 'Output file name cannot consist of an extension only', v => v && v !== '.zip'),
      outputFolderPath: yup.string()
        .test(
          'required',
          'Output folder path is required',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            return value && value !== '';
          },
        )
        .test(
          'start_end_with_space',
          'The element of the output folder path can not start or end with spaces',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            let result = true;
            value.split('/').forEach(path => {
              if (path.startsWith(' ') || path.endsWith(' ')) {
                result = false;
              }
            });

            return result;
          },
        )
        .test(
          'allowed-characters',
        <>
          Output folder can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        function (v) {
          const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

          return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
        },
        )
        .test(
          'not-new-lines',
          'Output folder name cannot contain the new lines',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            return value && !value.includes('\n');
          },
        )
        .test(
          'not-contain-extension',
          'Output folder name cannot contain the extensions',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            return value && !value.includes('.zip');
          },
        )
        .test(
          'folder-exist',
          'Output folder does not exist or is not a folder entity',
          async function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            if (value) {
              const parentResponse = await getPathEntity(value.split('/')[0]);
              if (!(
                parentResponse.status === 200
                  && parentResponse.data.data.type !== ENTITY_TYPE_FILE
              )
              ) {
                return this.createError({ message: 'Output parent folder does not exist' });
              }

              const valueResponse = await getPathEntity(value);

              return (
                valueResponse.status === 200 && valueResponse.data.data.type !== ENTITY_TYPE_FILE
              );
            }

            return false;
          },
        ),
    };

    schema = Object.assign(schema, taskPublishSchema);
  }

  return yup.object(schema);
};

const generateInitValues = task => {
  const config = JSON.parse(task.config || '{}');

  const [sourceFilePath, sourceFilename] = parsePath(task.source || '');
  const [outputFolderPath, outputFilename] = parsePath(task.output || '');

  return {
    // external settings
    serverUrl: config.url || '',
    email: config.email || '',
    apiKey: '',

    // paligo import
    type: config.type || '',
    remotePath: config.remote_path || '',

    createFolders: config.create_folders || false,
    ignoreWarnings: config.ignore_warnings || false,
    generateHazard: config.generate_hazard || false,
    matchComponents: config.match_components || false,

    folderNumbering: config.folder_numbering || true,
    useVarsetId: config.use_varset_id || undefined,
    titlePrefix: config.title_prefix || undefined,
    folderLevels: config.folder_levels || 1,

    sourceFilePath: sourceFilePath || '',
    sourceFilePathSearchValue: sourceFilename || '',
    sourceFilePathObjects: [],

    sourceFolderPathObjects: [],
    sourceFolderPathSearchValue: '',
    sourceFolderPath: task.source || '',

    // paligo publish
    outputFilename: outputFilename || '',
    publishSetting: config.publish_setting_id || '',

    outputFolderPath,
    outputFolderPathObjects: [],
    outputFolderPathSearchValue: '',

    createOutputFolders: false,
  };
};

const ALLOWED_PALIGO_IMPORT_TYPES = [
  {
    key: 'paligo',
    value: 'Paligo export file',
  },
  {
    key: 'db5',
    value: 'Docbook 5 (.xml)',
  },
  // {
  //   key: 'db4',
  //   value: 'Docbook 4 (.xml)',
  // },
  // {
  //   key: 'xhtml',
  //   value: 'HTML (index.html)',
  // },
  // {
  //   key: 'confluence',
  //   value: 'Confluence (index.html)',
  // },
  {
    key: 'dita',
    value: 'Dita (.ditamap)',
  },
  // {
  //   key: 'flare',
  //   value: 'Madcap Flare (.floc)',
  // },
  // {
  //   key: 'helpandmanual',
  //   value: 'Help and Manual (Maps/table_of_contents.xml)',
  // },
  {
    key: 'docx',
    value: 'Word (.docx)',
  },
  // {
  //   key: 'authorit',
  //   value: 'Author-it (XHTML)',
  // },
  // {
  //   key: 'openapi',
  //   value: 'Swagger OpenApi (.json, .yaml, .yml)',
  // },
];

function CreatePaligoDialog(props) {
  const {
    open,
    onClose,
    rerunTask,
    resetOnClose,
  } = props;

  const dispatch = useDispatch();

  const [creatingTask, setCreatingTask] = useState(false);

  const [taskType, setTaskType] = useState(rerunTask.converter || '');

  const [document, setDocument] = useState({});
  const [publishSetting, setPublishSetting] = useState({});
  const [publishSettings, setPublishSettings] = useState([]);

  const [loadingOutputFolderObjects, setLoadingOutputFolderObjects] = useState(false);
  const [loadingSourceFolderObjects, setLoadingSourceFolderObjects] = useState(false);
  const [loadingSourceFileObjects, setLoadingSourceFileObjects] = useState(false);

  const [outputFileWarning, setOutputFileWarning] = useState(undefined);

  const [sourceDataType, setSourceDataType] = useState(() => {
    if (rerunTask && rerunTask.source) {
      if (rerunTask.source.endsWith('.zip')) return ALLOWED_SOURCE_TYPES.file.key;

      return ALLOWED_SOURCE_TYPES.folder.key;
    }

    return ALLOWED_SOURCE_TYPES.file.key;
  });

  const [enabledService, setEnabledService] = useState(false);
  const [firstStepError, setFirstStepError] = useState('');

  const formik = useFormik({
    initialValues: generateInitValues(rerunTask || {}),
    validationSchema: validationSchema(taskType, sourceDataType),
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
  });

  const handleClose = link => {
    onClose(link);

    formik.resetForm();
  };

  const onFormSubmit = values => {
    setCreatingTask(true);

    const task = {
      api_key: values.apiKey,
      url: values.serverUrl,
      email: values.email,
    };

    if (taskType === TASK_CONVERTER_PALIGO_PUBLISH) {
      let outputFolder = `${values.outputFolderPath}${values.outputFolderPath.endsWith('/') ? '' : '/'}`;
      if (values.outputFolderPathSearchValue !== '') {
        outputFolder += `${values.outputFolderPathSearchValue}${values.outputFolderPathSearchValue.endsWith('/') ? '' : '/'}`;
      }

      task.output = `${outputFolder}${values.outputFilename}`;
      task.publish_setting_id = values.publishSetting;

      dispatch(createPaligoPublishTask(task))
        .then(res => handleClose(`${TASKS_ROUTE}/${res.id}`))
        .catch(() => setCreatingTask(false));
    }

    if (taskType === TASK_CONVERTER_PALIGO_IMPORT) {
      task.type = values.type;
      task.data_type = sourceDataType;
      task.remote_path = values.remotePath;
      task.title_prefix = values.titlePrefix;
      task.create_folders = values.createFolders;
      task.ignore_warnings = values.ignoreWarnings;
      task.generate_hazard = values.generateHazard;
      task.match_components = values.matchComponents;
      task.folder_numbering = values.folderNumbering;

      task.folder_levels = parseInt(values.folderLevels, 10) || 1;
      task.use_varset_id = parseInt(values.useVarsetId, 10) || undefined;

      if (sourceDataType === ALLOWED_SOURCE_TYPES.file.key) {
        task.source = `${values.sourceFilePath}${values.sourceFilePath.endsWith('/') ? '' : '/'}${values.sourceFilePathSearchValue}`;
      } else if (sourceDataType === ALLOWED_SOURCE_TYPES.folder.key) {
        task.source = values.sourceFolderPath;
      }

      dispatch(createPaligoImportTask(task))
        .then(res => handleClose(`${TASKS_ROUTE}/${res.id}`))
        .catch(() => setCreatingTask(false));
    }
  };

  const onCreateButtonClick = () => {
    if (creatingTask) return;
    setCreatingTask(true);

    onFormSubmit(formik.values);
  };

  const loadPulishSetting = (items, value) => {
    (items || publishSettings).forEach(entity => {
      if (entity.id === value) {
        setPublishSetting(entity);

        dispatch(
          fetchPaligoShowDocument(
            formik.values.serverUrl,
            formik.values.apiKey,
            formik.values.email,
            entity.resource,
          ),
        )
          .then(setDocument);
      }
    });
  };

  useEffect(() => {
    if (open && enabledService && taskType === TASK_CONVERTER_PALIGO_PUBLISH) {
      dispatch(
        fetchPaligoPublishSettings(
          formik.values.serverUrl,
          formik.values.apiKey,
          formik.values.email,
        ),
      )
        .then(entities => {
          if (!entities) {
            dispatch(showErrorSnackbar('Can not load the publish settings'));
            return;
          }

          setPublishSettings(entities);

          if (Object.keys(rerunTask).length > 0) {
            loadPulishSetting(entities, formik.values.publishSetting);
          }
        });
    }
  }, [open, enabledService, taskType]);

  const onPublishSettingChange = event => {
    setDocument({});
    formik.handleChange(event);

    loadPulishSetting(null, event.target.value);
  };

  const buildTooltipPublish = () => {
    if (Object.keys(document).length > 0 && Object.keys(publishSetting).length > 0) {
      return (
        <Grid container>
          <Grid container item xs={12}>
            <Typography align="center" style={{ fontSize: '14px' }}>
              Please click on this icon to open the document in a separate tab.
              <br />
              Publish settings details:
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              Publish format
            </Grid>
            <Grid item xs={6}>
              {publishSetting.format}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              Created at
            </Grid>
            <Grid item xs={6}>
              {new Date(publishSetting.createdAt * 1000).toLocaleString('en-US')}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              Modified At
            </Grid>
            <Grid item xs={6}>
              {new Date(publishSetting.modifiedAt * 1000).toLocaleString('en-US')}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              Document name
            </Grid>
            <Grid item xs={6}>
              {document.name}
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return undefined;
  };

  useEffect(() => {
    if (open && formik.publishSetting === '') {
      setPublishSetting({});
      setDocument({});
    }
  }, [open, formik.values.publishSetting]);

  useEffect(() => {
    if (open && taskType === TASK_CONVERTER_PALIGO_PUBLISH) {
      setLoadingOutputFolderObjects(true);

      const value = getCorrectPath(formik.values.outputFolderPath, '');

      getObjectsFromPath(`/${value}`)
        .then(objects => {
          formik.setFieldValue('outputFolderPathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('outputFolderPath').then(() => setLoadingOutputFolderObjects(false));
            });
        });
    }
  }, [enabledService, taskType, formik.values.outputFolderPath]);

  useEffect(() => {
    if (
      open && taskType === TASK_CONVERTER_PALIGO_IMPORT
      && sourceDataType === ALLOWED_SOURCE_TYPES.folder.key
    ) {
      setLoadingSourceFolderObjects(true);

      getObjectsFromPath(`/${formik.values.sourceFolderPath}`)
        .then(objects => {
          formik.setFieldValue('sourceFolderPathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('sourceFolderPath').then(() => setLoadingSourceFolderObjects(false));
            });
        });
    }
  }, [enabledService, taskType, formik.values.sourceFolderPath, sourceDataType]);

  useEffect(() => {
    if (
      open && taskType === TASK_CONVERTER_PALIGO_IMPORT
      && sourceDataType === ALLOWED_SOURCE_TYPES.file.key
    ) {
      setLoadingSourceFileObjects(true);

      getObjectsFromPath(`/${formik.values.sourceFilePath}`)
        .then(objects => {
          formik.setFieldValue('sourceFilePathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('sourceFilePath').then(() => setLoadingSourceFileObjects(false));
            });
        });
    }
  }, [enabledService, taskType, formik.values.sourceFilePath, sourceDataType]);

  const onChangePathValue = (value, fieldName, searchFieldName) => {
    const split = value.split('/');

    const search = split[split.length - 1];
    const path = `${split.slice(0, -1).join('/')}`;

    if (path !== '/' || formik.values[fieldName] !== path) formik.setFieldValue(fieldName, path);
    formik.setFieldValue(searchFieldName, search).then(() => formik.validateField(fieldName));
  };

  let outputFolder = `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}`;
  if (formik.values.outputFolderPathSearchValue !== '') {
    outputFolder += `${formik.values.outputFolderPathSearchValue}${formik.values.outputFolderPathSearchValue.endsWith('/') ? '' : '/'}`;
  }

  const config = {
    title: 'Create Paligo',
    steps: [
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'General settings',
        fields: [
          {
            tooltip: (
              <>
                <p>
                  The paligo server URL should be in the next format:
                </p>
                <p>
                  http(s)://(domain or server_ip_address):(port is optional)
                </p>
              </>
            ),
            onChange: event => formik.handleChange(event),
            value: formik.values.serverUrl,
            error: formik.errors.serverUrl,
            placeholder: 'Server URL',
            label: 'Server URL',
            key: 'serverUrl',
          },
          {
            tooltipOnClick: () => window.open('https://paligo.net/docs/en/api-keys.html', '_blank'),
            tooltip: (
              <>
                <p>
                  API key for accessing the server.
                </p>
                <p>
                  For details on how to generate a new API key, please click on this icon
                  (the new page should be open in a separate tab).
                </p>
                <p>
                  Or go to this link &quot;https://paligo.net/docs/en/api-keys.html&quot;
                </p>
              </>
            ),
            onChange: event => formik.handleChange(event),
            placeholder: 'Server API Key',
            value: formik.values.apiKey,
            error: formik.errors.apiKey,
            label: 'Server API Key',
            key: 'apiKey',
          },
          {
            tooltip: 'The user email is used in pair with the API key for identifying the user.',
            onChange: event => formik.handleChange(event),
            value: formik.values.email,
            error: formik.errors.email,
            placeholder: 'User Email',
            label: 'User Email',
            key: 'email',
          },
          {
            item: ([key, value]) => <MenuItem key={value} value={value}>{key}</MenuItem>,
            renderValue: selected => {
              if (selected === '') return <Placeholder>Task type</Placeholder>;

              let returnValue = selected;
              Object.entries(TYPES).forEach(([key, value]) => {
                if (selected === value) returnValue = key;
              });

              return returnValue;
            },
            onChange: event => setTaskType(event.target.value),
            items: Object.entries(TYPES),
            label: 'Task type',
            error: undefined,
            value: taskType,
            key: 'taskType',
          },
        ],
        async onSubmit() {
          setFirstStepError('');
          setEnabledService(false);

          const value = await fetchPaligoPing(
            formik.values.serverUrl, formik.values.apiKey, formik.values.email,
          );

          if (value) setEnabledService(true);
          else setFirstStepError('Unable to connect to the server. Please check if server is running and credentials are correct.');

          return value;
        },
        stepTooltip: taskType === TYPES.Publish ? (
          <p style={{ margin: 0 }}>
            <span><MdInfoOutline size="16px" /></span>
            {' '}
            <span>
              Before going to the next step, you should go to your Paligo server, create the
              &quot;publish&quot; settings, and associate it with the content. Then you will
              have the possibility to select the settings.
            </span>
          </p>
        ) : undefined,
        isValid: (formik.values.serverUrl !== '' && formik.errors.serverUrl === undefined)
          && (formik.values.apiKey !== '' && formik.errors.apiKey === undefined)
          && (formik.values.email !== '' && formik.errors.email === undefined)
          && taskType !== '',
        allowContinue: taskType !== '',
        error: firstStepError,
        loading: false,
      },
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'Task details',
        fields: [],
      },
    ],
    onSubmit: () => onCreateButtonClick(),
    allowContinue: taskType !== '',
    loading: creatingTask,
    initActiveStep: 0,
  };

  if (taskType === TASK_CONVERTER_PALIGO_IMPORT) {
    config.steps[1] = {
      isValid: (formik.values.remotePath !== '' && formik.errors.remotePath === undefined)
        && (formik.values.type !== '' && formik.errors.type === undefined),
      fields: [
        {
          item: item => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>,
          onChange: event => formik.handleChange(event),
          renderValue: selected => {
            if (selected === '') return <Placeholder>Type of import</Placeholder>;

            let returnValue = selected;
            ALLOWED_PALIGO_IMPORT_TYPES.forEach(i => {
              if (selected === i.key) returnValue = i.value;
            });

            return returnValue;
          },
          items: ALLOWED_PALIGO_IMPORT_TYPES,
          value: formik.values.type,
          error: formik.errors.type,
          label: 'Type of import',
          key: 'type',
        },
        {
          tooltip: 'It is a path to the folder on the remote server where the content will be imported',
          onChange: event => formik.handleChange(event),
          value: formik.values.remotePath,
          error: formik.errors.remotePath,
          placeholder: 'Remote path',
          label: 'Remote path',
          key: 'remotePath',
        },
        {
          tooltip: 'Use this setting if you want to mark your imported topics with a prefix. Enter the prefix in the field. If you do not want a prefix, leave the field blank.',
          onChange: event => formik.handleChange(event),
          value: formik.values.titlePrefix,
          error: formik.errors.titlePrefix,
          placeholder: 'Title prefix',
          label: 'Adding a title prefix',
          key: 'titlePrefix',
        },
        {
          tooltip: 'Use to control the maximum number of folder levels Paligo will create for your imported content. By default, Paligo will create a folder structure that matches the structure of your import content down to the second level. For example, if you have a manual with chapters and sub-sections, the import will create a folder for each "chapter" and the first level of sub-sections.',
          onChange: event => formik.handleChange(event),
          value: formik.values.folderLevels,
          error: formik.errors.folderLevels,
          placeholder: 'Max folder levels',
          label: 'Max folder levels',
          key: 'folderLevels',
        },
        {
          tooltip: 'Paligo can create variable sets from DITA, MadCap Flare, Paligo Export File (PEF) and DocBook5 imported content. This setting only applies to those.',
          onChange: event => formik.handleChange(event),
          value: formik.values.useVarsetId,
          error: formik.errors.useVarsetId,
          placeholder: 'Use varset id',
          label: 'Match with existing variable set',
          key: 'useVarsetId',
        },
        {
          item: item => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>,
          onChange: event => setSourceDataType(event.target.value),
          items: Object.values(ALLOWED_SOURCE_TYPES),
          label: 'Type of source',
          value: sourceDataType,
          error: undefined,
        },
      ],
      configurations: [
        {
          tooltip: 'When importing content the system can try to match content to already existing content. Check this option if you want Paligo to perform such a matching.',
          onChange: event => formik.handleChange(event),
          value: formik.values.matchComponents,
          label: 'Match components',
          key: 'matchComponents',
        },
        {
          tooltip: 'When selected, admonitions (notes, warnings, etc) will be imported as separate components. These are then reused in the parent component.',
          onChange: event => formik.handleChange(event),
          value: formik.values.generateHazard,
          label: 'Generate hazard',
          key: 'generateHazard',
        },
        {
          tooltip: 'When selected, warning messages about uploaded XML are ignored. Only use this ifthere are issues.',
          onChange: event => formik.handleChange(event),
          value: formik.values.ignoreWarnings,
          label: 'Ignore warnings',
          key: 'ignoreWarnings',
        },
        {
          tooltip: 'Use to add sequential numbering to the folders for your imported content.',
          onChange: event => formik.handleChange(event),
          value: formik.values.folderNumbering,
          label: 'Enable folder numbering',
          key: 'folderNumbering',
        },
        {
          tooltip: (
            <>
              <p>
                If the folder path does not exist on the remote server,
                the folders will be created automatically.
              </p>
              <p>
                The value should equal
                {' '}
                <b>true</b>
                {' '}
                if the type of source is
                {' '}
                <b>&apos;Folder&apos;</b>
                .
              </p>
            </>
          ),
          onChange: event => formik.handleChange(event),
          value: formik.values.createFolders,
          label: 'Create folders',
          key: 'createFolders',
        },
      ],
      onSubmit: () => Promise.resolve(true),
      type: STEP_FIELD_TYPE_MULTIPLE,
      title: 'Task details',
      allowContinue: true,
    };

    if (sourceDataType === ALLOWED_SOURCE_TYPES.folder.key) {
      config.steps.push({
        title: 'Source folder path',
        type: STEP_FIELD_TYPE_PATH,
        pathField: {
          objects: filterObjects(
            formik.values.sourceFolderPathObjects, formik.values.sourceFolderPathSearchValue,
          ),
          onChange: value => onChangePathValue(value, 'sourceFolderPath', 'sourceFolderPathSearchValue'),
          value: formik.values.sourceFolderPath !== ''
            ? `${formik.values.sourceFolderPath}${formik.values.sourceFolderPath.endsWith('/') ? '' : '/'}${formik.values.sourceFolderPathSearchValue}`
            : formik.values.sourceFolderPathSearchValue,
          error: formik.errors.sourceFolderPath,
        },
        allowContinue: formik.values.sourceFolderPath !== '',
        isValid: formik.values.sourceFolderPath !== '' && formik.errors.sourceFolderPath === undefined,
        onSubmit: () => Promise.resolve(true),
        loading: loadingSourceFolderObjects,
      });
    }

    if (sourceDataType === ALLOWED_SOURCE_TYPES.file.key) {
      config.steps.push({
        title: 'Source file path',
        type: STEP_FIELD_TYPE_PATH,
        pathField: {
          objects: filterObjects(
            formik.values.sourceFilePathObjects, formik.values.sourceFilePathSearchValue,
          ),
          onChange: value => onChangePathValue(value, 'sourceFilePath', 'sourceFilePathSearchValue'),
          value: formik.values.sourceFilePath !== ''
            ? `${formik.values.sourceFilePath}${formik.values.sourceFilePath.endsWith('/') ? '' : '/'}${formik.values.sourceFilePathSearchValue}`
            : formik.values.sourceFilePathSearchValue,
          error: formik.errors.sourceFilePath,
          loading: false,
        },
        allowContinue: formik.values.sourceFilePath !== '',
        isValid: formik.errors.sourceFilePath === undefined,
        onSubmit: () => Promise.resolve(true),
        loading: loadingSourceFileObjects,
      });
    }
  }

  if (taskType === TASK_CONVERTER_PALIGO_PUBLISH) {
    let allowCreateFolders = false;
    if (formik.errors.outputFolderPath !== undefined) {
      if (formik.errors.outputFolderPath === 'Output folder does not exist or is not a folder entity') {
        allowCreateFolders = true;
      }
    }

    config.steps[1] = {
      isValid: formik.values.publishSetting !== '' && formik.errors.publishSetting === undefined,
      fields: [
        {
          item: e => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>,
          renderValue: selected => {
            if (selected === '') return <Placeholder>Select</Placeholder>;

            let returnValue = selected;
            publishSettings.forEach(i => {
              if (selected === i.id) returnValue = i.name;
            });

            return returnValue;
          },
          tooltipOnClick: () => window.open(`${formik.values.serverUrl}${document.link}`, '_blank'),
          value: formik.values.publishSetting,
          error: formik.errors.publishSetting,
          onChange: onPublishSettingChange,
          tooltip: buildTooltipPublish(),
          label: 'Publish settings',
          items: publishSettings,
          key: 'publishSetting',
          onlyActive: true,
        },
        {
          label: 'Publish settings name',
          value: publishSetting.name,
          key: 'publishSettingName',
          onlyInactive: true,
          error: undefined,
        },
        {
          label: 'Publishing format',
          value: publishSetting.format,
          key: 'publishSettingFormat',
          onlyInactive: true,
          error: undefined,
        },
        {
          label: 'Document name',
          value: document.name,
          key: 'publishDocumentName',
          onlyInactive: true,
          error: undefined,
        },
      ],
      onSubmit: () => Promise.resolve(true),
      type: STEP_FIELD_TYPE_MULTIPLE,
      title: 'Task details',
      allowContinue: true,
    };

    config.steps.push({
      title: 'Output file path',
      type: STEP_FIELD_TYPE_PATH,
      displayValue: `${outputFolder}${formik.values.outputFilename}`,
      displayNode: (
        <span
          dangerouslySetInnerHTML={{__html: `${outputFolder.replaceAll(' ', '&nbsp;').replaceAll('/', ' / ')}<b>${formik.values.outputFilename.replaceAll(' ', '&nbsp;')}</b>`}}  // eslint-disable-line
        />
      ),
      fields: [
        {
          onChange: event => formik.handleChange(event),
          value: formik.values.outputFilename,
          error: formik.errors.outputFilename,
          placeholder: 'Example.zip',
          label: 'Output file name',
          key: 'outputFilename',
        },
      ],
      pathField: {
        label: 'Output folder path',
        placeholder: 'Search a folder by name',
        objects: filterObjects(
          formik.values.outputFolderPathObjects, formik.values.outputFolderPathSearchValue,
        ).filter(o => o.type === 'folder'),
        onChange: value => {
          onChangePathValue(value, 'outputFolderPath', 'outputFolderPathSearchValue');
          formik.setFieldValue('createOutputFolders', false);
        },
        value: formik.values.outputFolderPath !== ''
          ? `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}${formik.values.outputFolderPathSearchValue}`
          : formik.values.outputFolderPathSearchValue,
      },
      configurations: allowCreateFolders ? [
        {
          tooltip: (
            <p>
              <p>
                The output folder does not exist. If you select this parameter,
                the folder will be created automatically.
              </p>
              <p>
                <b>Notice. </b>
                Use this parameter carefully.
              </p>
              <p>
                THE PARENT FOLDER MUST EXIST.
              </p>
            </p>
          ),
          onChange: event => formik.handleChange(event),
          value: formik.values.createOutputFolders,
          key: 'createOutputFolders',
          label: 'Create Folders',
        },
      ] : [],
      allowContinue: formik.values.outputFolderPath !== '' && formik.values.outputFilename !== ''
        && (formik.errors.outputFolderPath === undefined || formik.values.createOutputFolders),
      isValid: formik.values.outputFolderPath !== ''
        && (formik.values.outputFilename !== '' && formik.errors.outputFilename === undefined),
      onSubmit: () => Promise.resolve(true),
      error: formik.errors.outputFolderPath,
      loading: loadingOutputFolderObjects,
      warning: outputFileWarning,
    });
  }

  useEffect(() => {
    const fileId = `${outputFolder}${formik.values.outputFilename}`;

    if (formik.values.outputFolderPath === outputFolder) {
      const exist = formik.values.outputFolderPathObjects.some(file => file.id === fileId);

      if (exist) setOutputFileWarning('The output file already exists');
      else setOutputFileWarning(undefined);
    } else {
      dispatch(checkFileExist(fileId))
        .then(() => setOutputFileWarning('The output file already exists'))
        .catch(() => setOutputFileWarning(undefined));
    }
  }, [
    formik.values.outputFolderPath,
    formik.values.outputFolderPathSearchValue,
    formik.values.outputFolderPathObjects,
    formik.values.outputFilename,
  ]);

  useEffect(() => {
    if (open) formik.validateField('folderLevels');
  }, [formik.values.folderLevels]);

  useEffect(() => {
    if (open && formik.values.outputFilename !== '') formik.validateField('outputFilename');
  }, [formik.values.outputFilename]);

  useEffect(() => {
    if (open && formik.values.serverUrl !== '') formik.validateField('serverUrl');
  }, [formik.values.serverUrl]);

  useEffect(() => {
    if (open && formik.values.remotePath !== '') formik.validateField('remotePath');
  }, [formik.values.remotePath]);

  useEffect(() => {
    if (open && formik.values.apiKey !== '') formik.validateField('apiKey');
  }, [formik.values.apiKey]);

  useEffect(() => {
    if (open && formik.values.email !== '') formik.validateField('email');
  }, [formik.values.email]);

  return (
    <CreateTaskDialog
      open={open}
      config={config}
      onClose={() => {
        onClose();

        if (resetOnClose) {
          setTaskType('');
          setFirstStepError(undefined);
          formik.setValues(generateInitValues({}));
        }
      }}
      resetOnClose={resetOnClose}
    />
  );
}

CreatePaligoDialog.defaultProps = { rerunTask: {}, resetOnClose: true };

CreatePaligoDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  resetOnClose: PropTypes.bool,
  rerunTask: PropTypes.object,
};

export default CreatePaligoDialog;
