import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Button,
  CircularProgress,
} from '@material-ui/core';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import ErrorIcon from '@material-ui/icons/Error';
import { MdPadding } from 'react-icons/md';
import { MAIN_UI_COLOR } from '../../config';
import { deletePathObject } from '../../redux/actions/fileStorageActions';

function BulkDeleteDialog(props) {
  const { open, pathes, onClose } = props;

  const [failedPathes, setFailedPathes] = useState([]);
  const [processedPathes, setProcessedPathes] = useState([]);
  const [processingPathes, setProcessingPathes] = useState([]);

  useEffect(() => {
    pathes.forEach(path => {
      setProcessingPathes(value => [...value, path]);

      deletePathObject(path).then(() => setProcessedPathes(value => [...value, path]))
        .catch(() => setFailedPathes(value => [...value, path]));
    });
  }, [open]);

  const statusDisplayValue = path => {
    if (processedPathes.indexOf(path) !== -1) {
      return (
        <div style={{ display: 'flex' }}>
          <span>Deleted</span>
          <CheckCircleTwoToneIcon color="primary" style={{ fontSize: 18, marginLeft: '5px' }} />
        </div>
      );
    }

    if (failedPathes.indexOf(path) !== -1) {
      return (
        <div style={{ display: 'flex' }}>
          <span>Failed</span>
          <ErrorIcon style={{ fontSize: 18, marginLeft: '5px' }} />
        </div>
      );
    }

    if (processingPathes.indexOf(path) !== -1) {
      return (
        <div style={{ display: 'flex' }}>
          <span>Deleting</span>
          <CircularProgress size={20} style={{ marginLeft: '5px' }} />
        </div>
      );
    }

    return (
      <div style={{ display: 'flex' }}>
        <span>Pending</span>
        <MdPadding size={20} style={{ marginLeft: '5px' }} />
      </div>
    );
  };

  const handleClose = () => {
    setProcessingPathes([]);
    setProcessedPathes([]);
    setFailedPathes([]);

    onClose();
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle style={{ background: MAIN_UI_COLOR, color: '#ffffff' }}>
        Deleting selected objects
      </DialogTitle>

      <DialogContent style={{ padding: '12px 24px', background: '#F8F8F8' }}>
        {pathes.length > 0 && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell width="112px">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pathes.map(path => (
                <TableRow key={path}>
                  <TableCell>{path}</TableCell>
                  <TableCell>{statusDisplayValue(path)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>

      <DialogActions style={{ padding: '0 24px 24px 24px', background: '#F8F8F8' }}>
        <Grid container>
          <Grid item xs={12}>
            <Button
              disabled={failedPathes.length + processedPathes.length !== pathes.length}
              onClick={handleClose}
              variant="contained"
              className="button"
              color="primary"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

BulkDeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  pathes: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BulkDeleteDialog;
