import React from 'react';
import PropTypes from 'prop-types';
import { TaskEntity } from '../../core/entities';
import EvaluationReports from '../evaluations/EvaluationReports';

function TaskDetailsReports({ task }) {
  return (
    <>
      {task.converter === 'evaluation' && (
        <EvaluationReports taskId={task.id} />
      )}
    </>
  );
}

TaskDetailsReports.propTypes = {
  task: PropTypes.oneOfType([
    PropTypes.instanceOf(TaskEntity),
    PropTypes.exact({}),
  ]).isRequired,
};

export default TaskDetailsReports;
