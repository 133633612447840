import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  makeStyles,
  createStyles,
} from '@material-ui/core';

import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';

import { NavLink } from 'react-router-dom';
import { MAIN_UI_COLOR } from '../../config';

const useStyles = makeStyles(() => createStyles({
  menuItem: {
    '&.active': {
      color: '#000000',
      background: '#ffffff',
      '& .MuiListItemIcon-root': {
        color: '#000000',
      },
    },
  },
  menuItemIcon: {
    minWidth: '40px',
    fontSize: '18px',
    color: '#fff',
  },
  expandableMenuItem: {
    backgroundColor: MAIN_UI_COLOR,
    color: '#fff',
  },
  expandableMenuItemIcon: {
    color: '#fff !important',
  },
}));

function AppMenuItemComponent({
  className, onClick, link, children,
}) {
  if (!link || typeof link !== 'string') {
    return (
      <ListItem key={link} button className={className} onClick={onClick}>
        {children}
      </ListItem>
    );
  }

  return (
    <ListItem
      button
      to={link}
      key={link}
      className={className}
      component={forwardRef((props, ref) => <NavLink exact {...props} innerRef={ref} />)}
    >
      {children}
    </ListItem>
  );
}

AppMenuItemComponent.defaultProps = {
  link: undefined,
};

AppMenuItemComponent.propTypes = {
  link: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

function AppMenuItem(props) {
  const {
    name, link, Icon, items,
  } = props;
  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <>
      <AppMenuItemComponent className={classes.menuItem} link={link} onClick={() => handleClick()}>
        {!!Icon && (
        <ListItemIcon className={`${classes.menuItemIcon} ${props.isSubLink ? classes.expandableMenuItemIcon : ''}`}>
          <Icon />
        </ListItemIcon>
        )}

        <ListItemText primary={name} style={!Icon ? { marginLeft: '40px' } : {}} />

        {isExpandable && !open && <IconExpandMore />}
        {isExpandable && open && <IconExpandLess />}
      </AppMenuItemComponent>

      {isExpandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit className={classes.expandableMenuItem}>
          <List component="div" style={{ padding: 0 }}>
            {items.map(item => (
              <AppMenuItem {...item} key={item.name} isSubLink />
            ))}
          </List>
        </Collapse>
      ) : null}
    </>
  );
}

AppMenuItem.defaultProps = {
  items: [],
  link: undefined,
  Icon: undefined,
  isSubLink: false,
};

AppMenuItem.propTypes = {
  Icon: PropTypes.any,
  link: PropTypes.string,
  isSubLink: PropTypes.bool,
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()),
};

export default AppMenuItem;
