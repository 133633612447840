import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  placeholder: {
    color: '#aaa',
  },
});

function Placeholder(props) {
  const { children } = props;
  const classes = useStyles();

  return <div className={classes.placeholder}>{children}</div>;
}

Placeholder.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Placeholder;
