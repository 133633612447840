import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import Loader from './Loader';
import {
  ACTION_TYPE_CHECKBOX,
  ACTION_TYPE_RADIO,
  ACTION_TYPE_NONE,
  ACTION_TYPES,
  ROWS_PER_PAGE_DEFAULT,
  ROWS_PER_PAGE_OPTIONS,
} from '../../core/constants';
import {
  splitStringValue,
} from '../../core/services/commonService';

const SELECTION_MODE_SINGLE = 'single';
const SELECTION_MODE_MULTIPLE = 'multiple';

function applySorting(array, order, orderBy) {
  if (!order || !orderBy) {
    return array;
  }
  const getValue = (obj, property) => (
    obj[property].value !== undefined ? obj[property].value : obj[property]
  );

  const numbersComparator = (a, b) => (order === 'asc' ? a - b : b - a);
  const stringsComparator = (a, b) => (order === 'asc' ? a.localeCompare(b) : -a.localeCompare(b));

  return array.sort((objA, objB) => {
    const a = getValue(objA, orderBy);
    const b = getValue(objB, orderBy);

    if (!a) {
      return 1;
    }

    if (!b) {
      return -1;
    }

    let comparator = numbersComparator;
    if (typeof a === 'string' && typeof b === 'string') {
      comparator = stringsComparator;
    }

    return comparator(a, b);
  });
}

const generateNewSelected = (selected, rowId, selectionMode) => {
  const selectedIndex = selected.indexOf(rowId);
  let newSelected = [];

  if (selectionMode === SELECTION_MODE_SINGLE) {
    if (selectedIndex === -1) {
      newSelected = [rowId];
    }
  } else if (selectionMode === SELECTION_MODE_MULTIPLE) {
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
  }

  return newSelected;
};

const getDefaultOrderBy = columns => {
  const firstSortableColumn = columns.find(c => c.sortable);
  if (firstSortableColumn) {
    return firstSortableColumn.field;
  }

  return '';
};

function RyffineTable(props) {
  const {
    rows,
    columns,
    loading,
    onSelectionChange,
    actionType,
    initSorting,
    selectedRowIds,
    tableProps,
    tableContainerProps,
    displayPagination,
    tableCellClassName,
    lastRecordWithoutBorder,
    localStorageTableKey,
    resetPageOnSorting,
    pageLoading,
  } = props;

  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState(initSorting.order || 'asc');
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_DEFAULT);
  const [orderBy, setOrderBy] = useState(initSorting.field || getDefaultOrderBy(columns));

  useEffect(() => {
    if (!pageLoading) setPage(0);
  }, [rows]);

  useEffect(() => {
    if (localStorageTableKey) {
      const tableState = JSON.parse(localStorage.getItem(localStorageTableKey) || '{}');

      setOrderBy(tableState.orderBy || initSorting.field || getDefaultOrderBy(columns));
      setRowsPerPage(tableState.rowsPerPage || ROWS_PER_PAGE_DEFAULT);
      setOrder(tableState.order || initSorting.order || 'asc');
      setPage(tableState.page || 0);
    }
  }, []);

  useEffect(() => {
    if (localStorageTableKey) {
      const tableState = {
        rowsPerPage,
        orderBy,
        order,
        page,
      };

      localStorage.setItem(localStorageTableKey, JSON.stringify(tableState));
    }
  }, [page, rowsPerPage, order, orderBy]);

  const withActions = actionType !== ACTION_TYPE_NONE;
  const selectionMode = actionType === ACTION_TYPE_RADIO
    ? SELECTION_MODE_SINGLE
    : SELECTION_MODE_MULTIPLE;

  const sortedRows = useMemo(
    () => applySorting(rows, order, orderBy),
    [rows, order, orderBy],
  );
  const tableRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const rowsIds = JSON.stringify(rows.map(r => r.id).sort());
  useEffect(() => {
    resetTable();
  }, [rowsIds]); // eslint-disable-line

  const selectedIds = JSON.stringify(selectedRowIds);
  useEffect(() => {
    if (selectedRowIds.length > 0) {
      setSelected(selectedRowIds);

      onSelectionChange(selectedRowIds);
    }
  }, [selectedIds]); // eslint-disable-line

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectionChange = row => {
    const newSelected = generateNewSelected(selected, row.id, selectionMode);

    setSelected(newSelected);
    onSelectionChange(newSelected);
  };

  const handleSorting = column => {
    const isAsc = orderBy === column && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
  };

  function resetTable() {
    if (!localStorageTableKey) {
      setPage(0);
    }

    setSelected([]);
    onSelectionChange([]);
  }

  const isSelected = rowId => selected.indexOf(rowId) !== -1;

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <TableContainer {...tableContainerProps}>
        <Table {...tableProps}>
          <TableHead>
            <TableRow>
              {withActions && <TableCell padding="checkbox" />}
              {columns.map(column => {
                if (column.sortable) {
                  return (
                    <TableCell
                      className={`${tableCellClassName} pl-0`}
                      key={column.field}
                      sortDirection={orderBy === column.field ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === column.field}
                        direction={orderBy === column.field ? order : 'asc'}
                        onClick={() => {
                          handleSorting(column.field);
                          if (resetPageOnSorting) setPage(0);
                        }}
                      >
                        {column.headerName}
                      </TableSortLabel>
                    </TableCell>
                  );
                }
                return (
                  <TableCell key={column.field} className={`${tableCellClassName} pl-0`}>
                    {column.headerName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>

            {rows.length === 0 && (
              <TableRow>
                <TableCell
                  className={`${tableCellClassName} pl-0`}
                  colSpan={withActions ? columns.length + 1 : columns.length}
                  align="center"
                >
                  <Typography variant="body1" align="center">No items</Typography>
                </TableCell>
              </TableRow>
            )}

            {tableRows.map((row, index) => (
              <TableRow key={row.id}>

                {withActions && (
                  <TableCell padding="checkbox" className={`${tableCellClassName} pl-0`}>
                    {actionType === ACTION_TYPE_CHECKBOX && (
                      <Checkbox
                        disabled={row.selectionDisabled}
                        checked={isSelected(row.id)}
                        onChange={() => handleSelectionChange(row)}
                      />
                    )}

                    {actionType === ACTION_TYPE_RADIO && (
                      <Radio
                        disabled={row.selectionDisabled}
                        checked={isSelected(row.id)}
                        onChange={() => handleSelectionChange(row)}
                      />
                    )}
                  </TableCell>
                )}

                {columns.map(column => {
                  let displayValue = row[column.field].displayValue || row[column.field];

                  if (typeof displayValue === 'string' && column.isPath) {
                    displayValue = splitStringValue(displayValue, '/', 60);
                  }

                  return (
                    <TableCell
                      className={`${tableCellClassName}${lastRecordWithoutBorder && index === tableRows.length - 1 ? ' last-table-record' : ''} pl-0`}
                      style={{ whiteSpace: 'pre' }}
                      key={column.field}
                    >
                      {displayValue}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {displayPagination
        && (
          <TablePagination
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            count={rows.length}
            component="div"
            page={page}
          />
        )}
    </div>
  );
}

RyffineTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool,
  selectedRowIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  onSelectionChange: PropTypes.func,
  actionType: PropTypes.oneOf(ACTION_TYPES),
  initSorting: PropTypes.shape({
    order: PropTypes.oneOf(['asc', 'desc']),
    field: PropTypes.string,
  }),
  tableProps: PropTypes.object, // eslint-disable-line
  tableContainerProps: PropTypes.object, // eslint-disable-line
  displayPagination: PropTypes.bool,
  tableCellClassName: PropTypes.string,
  lastRecordWithoutBorder: PropTypes.bool,
  localStorageTableKey: PropTypes.string,
  resetPageOnSorting: PropTypes.bool,
  pageLoading: PropTypes.bool,
};

RyffineTable.defaultProps = {
  loading: false,
  actionType: ACTION_TYPE_NONE,
  initSorting: {},
  selectedRowIds: [],
  tableProps: {},
  tableContainerProps: {
    className: 'pl-4 pr-4 pt-2',
    component: Paper,
  },
  onSelectionChange: () => { },
  displayPagination: true,
  tableCellClassName: '',
  lastRecordWithoutBorder: false,
  localStorageTableKey: undefined,
  resetPageOnSorting: false,
  pageLoading: false,
};

export default RyffineTable;
