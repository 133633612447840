import { isUserAuthenticated } from '../../core/services/authService';
import { USER_SIGN_IN, USER_SIGN_OUT } from '../actionTypes';

const initialState = {
  username: 'Joe Dou', // todo -> use real name
  isSignedIn: isUserAuthenticated(),
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case USER_SIGN_IN:
      return { ...state, ...action.payload };

    case USER_SIGN_OUT:
      return {
        ...state,
        username: '',
        isSignedIn: false,
      };

    default:
      return state;
  }
}
