import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Grid,
  Link, makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { fetchTaskLogs, getTaskLogs } from '../../redux/actions/taskActions';
import { formatDateStr, isObjectEmpty } from '../../core/services/commonService';
import { TaskEntity, TASK_STATUS_RUNNING } from '../../core/entities';
import Loader from '../common/Loader';

const sleep = ms => new Promise(r => setTimeout(r, ms));

const useStyles = makeStyles(theme => ({
  tableHeadRow: {
    '& > :first-child': {
      width: 200,
    },
  },
  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const MAX_RECALLS_DURRING_FIRST_LOADING = 20;

function LoadingLink({
  loading, onClick, children, ...rest
}) {
  const handleClick = () => {
    if (!loading) {
      onClick();
    }
  };

  return (
    <Link
      className="ml-1"
      onClick={handleClick}
      {...rest}
    >
      {loading && (
      <div>
        Loading
        {' '}
        <CircularProgress size={15} color="inherit" />
      </div>
      )}
      {!loading && children}
    </Link>
  );
}

LoadingLink.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

function TaskDetailsLogs({ task }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [events, setEvents] = useState([]);
  const [firstLoading, setFirstLoading] = useState(false);

  const [loadingOlderEvents, setLoadingOlderEvents] = useState(false);
  const [loadingNewerEvents, setLoadingNewerEvents] = useState(false);

  const [nextForwardToken, setNextForwardToken] = useState('');
  const [nextBackwardToken, setNextBackwardToken] = useState('');

  const [olderEventsExist, setOlderEventsExist] = useState(true);
  const [newerEventsExist, setNewerEventsExist] = useState(true);

  const logsExist = events.length > 0;

  const fetchLogs = token => dispatch(fetchTaskLogs(task.id, token));

  const firstLogsLoad = delay => {
    setFirstLoading(true);

    if (!isObjectEmpty(task)) {
      fetchLogs()
        .then(async res => {
          let response = res;

          if (res.events.length <= 0) {
            let counter = 0;

            while (
              counter < MAX_RECALLS_DURRING_FIRST_LOADING
                && response.events && response.events.length <= 0
            ) {
              /* eslint-disable no-await-in-loop */
              response = await getTaskLogs(task.id, response.nextBackwardToken);

              if (delay) await sleep(delay);
              /* eslint-enable no-await-in-loop */

              counter += 1;
            }
          }

          setNextBackwardToken(response.nextBackwardToken);
          setNextForwardToken(response.nextForwardToken);
          setEvents(response.events);
        })
        .finally(() => setFirstLoading(false));
    }
  };

  useEffect(() => {
    if (task.status === TASK_STATUS_RUNNING) firstLogsLoad(1000);
  }, [task.status]); // eslint-disable-line

  useEffect(() => {
    if (task.started) firstLogsLoad();
  }, []); // eslint-disable-line

  const handleGetOlderEvents = () => {
    setLoadingOlderEvents(true);

    fetchLogs(nextBackwardToken)
      .then(res => {
        setEvents([...res.events, ...events]);

        if (nextBackwardToken === res.nextBackwardToken) {
          setOlderEventsExist(false);
        }

        setNextBackwardToken(res.nextBackwardToken);
      })
      .finally(() => setLoadingOlderEvents(false));
  };

  const handleGetNewerEvents = () => {
    setLoadingNewerEvents(true);

    fetchLogs(nextForwardToken)
      .then(res => {
        setEvents([...events, ...res.events]);

        if (nextForwardToken === res.nextForwardToken) {
          setNewerEventsExist(false);
        }

        setNextForwardToken(res.nextForwardToken);
      })
      .finally(() => setLoadingNewerEvents(false));
  };

  if (firstLoading) {
    return <Loader />;
  }

  return (
    <Grid className="pt-4" container>

      <Table size="small">
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell>Time</TableCell>
            <TableCell>Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {!logsExist && (
            <>
              {task.created && !task.started && !task.finished && (
                <TableRow className={classes.tableRow}>
                  <TableCell />
                  <TableCell>
                    The task is not running yet. Please wait.
                  </TableCell>
                </TableRow>
              )}

              {task.finished && !task.started && (
                <TableRow className={classes.tableRow}>
                  <TableCell />
                  <TableCell>
                    The task was interrupted before it was started. The logs will be empty.
                  </TableCell>
                </TableRow>
              )}

              {task.started && (
                <TableRow className={classes.tableRow}>
                  <TableCell />
                  <TableCell>
                    There are no logs yet
                  </TableCell>
                </TableRow>
              )}

            </>
          )}

          {logsExist && (
            <TableRow className={classes.tableRow}>
              <TableCell />
              <TableCell>
                {!olderEventsExist && <div>There no older events</div>}
                {olderEventsExist && (
                  <div className="df-center-y">
                    <div>There are older events to load.</div>
                    <LoadingLink loading={loadingOlderEvents} onClick={handleGetOlderEvents}>
                      Load more
                    </LoadingLink>
                  </div>
                )}
              </TableCell>
            </TableRow>
          )}

          {logsExist && events.map(event => (
            <TableRow className={classes.tableRow} key={event.id}>
              <TableCell component="th">{formatDateStr(new Date(event.timestamp))}</TableCell>
              <TableCell><span style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>{event.message}</span></TableCell>
            </TableRow>
          ))}

          {logsExist && (
            <TableRow className={classes.tableRow}>
              <TableCell />
              <TableCell>
                {!newerEventsExist && <div>There no newer events</div>}
                {newerEventsExist && (
                  <div className="df-center-y">
                    <div>There are newer events to load.</div>
                    <LoadingLink loading={loadingNewerEvents} onClick={handleGetNewerEvents}>
                      Load more
                    </LoadingLink>
                  </div>
                )}
              </TableCell>
            </TableRow>
          )}

        </TableBody>
      </Table>

    </Grid>
  );
}

TaskDetailsLogs.propTypes = {
  task: PropTypes.oneOfType([
    PropTypes.instanceOf(TaskEntity),
    PropTypes.exact({}),
  ]).isRequired,
};

export default TaskDetailsLogs;
