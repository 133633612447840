import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, Link,
} from '@material-ui/core';

function LoadingLink({
  loading, onClick, children, ...rest
}) {
  const handleClick = () => {
    if (!loading) {
      onClick();
    }
  };

  return (
    <Link
      className="ml-1"
      onClick={handleClick}
      {...rest}
    >
      {loading && (
        <div>
          Loading
          <CircularProgress size={15} color="inherit" />
        </div>
      )}
      {!loading && children}
    </Link>
  );
}

LoadingLink.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LoadingLink;
