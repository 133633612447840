import React from 'react';
import PropTypes from 'prop-types';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { Grid, Link } from '@material-ui/core';
import { BasePathEntity, ENTITY_TYPE_FILE } from '../../core/entities';

function NameLinkCell({ pathEntity, onNameClick, maxCharacters }) {
  const isFile = pathEntity.type === ENTITY_TYPE_FILE;
  const icon = isFile
    ? <InsertDriveFileOutlinedIcon className="mr-2" />
    : <FolderOpenIcon className="mr-2" />;

  const regex = new RegExp(`.{1,${maxCharacters}}`, 'g');

  return (
    <Grid container alignItems="center" style={{ width: '100%' }}>
      {icon}
      <Link onClick={() => onNameClick(pathEntity)}>
        {pathEntity.name.match(regex).join('\n')}
      </Link>
    </Grid>
  );
}

NameLinkCell.propTypes = {
  pathEntity: PropTypes.instanceOf(BasePathEntity).isRequired,
  onNameClick: PropTypes.func.isRequired,
  maxCharacters: PropTypes.number,
};

NameLinkCell.defaultProps = {
  maxCharacters: 60,
};

export default NameLinkCell;
