import { combineReducers } from 'redux';
import app from './appReducer';
import auth from './authReducer';
import evaluation from './evaluationReducer';
import fs from './fileStorageReducer';
import task from './taskReducer';
import iam from './iamReducer';

export default combineReducers({
  app,
  auth,
  evaluation,
  fs,
  task,
  iam,
});
