import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { AiOutlineMenu } from 'react-icons/ai';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { FaExclamationCircle } from 'react-icons/fa';
import { xml2js } from 'xml-js';
import { MENU_CLOSE, MENU_OPEN } from '../../redux/actionTypes';
import { showSuccessSnackbar } from '../../redux/actions/appActions';
import { createTask, fetchConversionTags, fetchConversionTypes } from '../../redux/actions/taskActions';
import { checkFileExist, getPathEntity, readFile } from '../../redux/actions/fileStorageActions';
import FileStorageDialog from '../fileStorage/FileStorageDialog';
import { ENTITY_TYPE_FOLDER, ENTITY_TYPE_FILE, TASK_ENGINE_XSLT } from '../../core/entities';
import { TextLabel } from '../common/Utility';
import LoadingButton from '../common/LoadingButton';
import { parsePath } from '../../core/services/fileStorageService';
import { formatDateStr, isObjectEmpty } from '../../core/services/commonService';
import { TASKS_ROUTE } from '../../core/constants';
import AddParameterDialog from './AddParameterDialog';
import InfoDisplayer from '../common/InfoDisplayer';
import { MAIN_UI_COLOR } from '../../config';

const USE_DEFAULT_VALUE = -1;
// const cpuOptions = [256, 512, 1024, 2048, 4096];
// const ramOptions = [256, 512, 1024, 2048, 4096];

const useStyles = makeStyles(theme => ({
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  inputContainer: {
    minWidth: 400,
    maxWidth: 400,
  },
  pathInputWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiTextField-root': {
      flex: 1,
      marginRight: theme.spacing(1),
    },
    '& .Mui-disabled': {
      color: 'unset',
    },
  },
  paramContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  createButton: {
    minWidth: 80,
  },
  removeButton: {
    minWidth: 40,
  },
}));

const validationSchema = yup.object({
  conversionType: yup.string().required('Conversion type is required'),
  tag: yup.string().required('Tag is required'),
  sourceFilePath: yup.string()
    .required('Source file path is required')
    .test(
      'allowed-characters',
    <>
      Source file name can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
    </>,
    v => /^[a-zA-Z0-9\s/\-._]+$/.test(v),
    )
    .test('not-new-lines', 'Source file name cannot contain the new lines', v => v && !v.includes('\n'))
    .test('file-exist', 'Source file does not exist', async v => {
      if (v) {
        const response = await getPathEntity(v);

        return response.status === 200 && response.data.data.type === ENTITY_TYPE_FILE;
      }

      return false;
    }),
  outputFolderPath: yup.string()
    .required('Output folder path is required')
    .test(
      'allowed-characters',
    <>
      Output folder can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
    </>,
    v => /^[a-zA-Z0-9\s/\-._]+$/.test(v),
    )
    .test('not-new-lines', 'Output folder name cannot contain the new lines', v => v && !v.includes('\n'))
    .test('folder-exist', 'Output folder does not exist', async v => {
      if (v && v !== '/') {
        const response = await getPathEntity(v);

        return response.status === 200 && response.data.data.type !== ENTITY_TYPE_FILE;
      }

      return false;
    }),
  outputFilename: yup.string()
    .required('Output file name is required')
    .test(
      'allowed-characters',
    <>
      Output file name can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
    </>,
    v => /^[a-zA-Z0-9\s/\-._]+$/.test(v),
    )
    .test('not-new-lines', 'Output file name cannot contain the new lines', v => v && !v.includes('\n'))
    .test('is-zip', "Output file name should end with '.zip'", v => v && v.endsWith('.zip'))
    .test('not-starts-with-slash', "Output file name cannot contain '/'", v => v && !v.includes('/'))
    .test('is-file', 'Output file name cannot consist of an extension only', v => v && v !== '.zip'),
  expectedResultPath: yup.string().nullable()
    .test(
      'allowed-characters',
    <>
      Comparing file name can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
    </>,
    v => {
      if (!(v === null || v === undefined) && !/^[a-zA-Z0-9\s/\-._]+$/.test(v)) return false;

      return true;
    },
    )
    .test('not-new-lines', 'Comparing file name cannot contain the new lines', v => {
      if (!(v === null || v === undefined) && v.includes('\n')) return false;

      return true;
    })
    .test('not-file-exist', 'Comparing file does not exist', async v => {
      if (!(v === null || v === undefined)) {
        const response = await getPathEntity(v);

        return response.status === 200 && response.data.data.type === ENTITY_TYPE_FILE;
      }

      return true;
    }),
  overwriteOutput: yup.boolean(),
});

const generateInitValues = task => {
  const [outputFolderPath, outputFilename] = parsePath(task.output || '');
  const config = { ...(task.config ? JSON.parse(task.config) : {}) };

  return {
    conversionType: task.converter || '',
    tag: task.tag || '',
    sourceFilePath: task.source || '',
    outputFolderPath,
    outputFilename,
    overwriteOutput: false,
    cpu: USE_DEFAULT_VALUE,
    ram: USE_DEFAULT_VALUE,
    expectedResultPath: task.expectedResult || null,
    env: task.env || {},
    config: config.parameters || {},
  };
};

const prepareAdditionalFieldTitle = value => {
  const correctedValue = value.replaceAll('_', ' ');

  return correctedValue.charAt(0).toUpperCase() + correctedValue.slice(1);
};

function CreateTask() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [conversionTypes, setConversionTypes] = useState([]);
  const [conversionTags, setConversionTags] = useState([]);
  const [fetchingConversionTags, setFetchingConversionTags] = useState(false);
  const [sourcePathDialogOpen, setSourcePathDialogOpen] = useState(false);
  const [outputFolderDialogOpen, setOutputFolderDialogOpen] = useState(false);
  const [expectedResultDialogOpen, setExpectedResultDialogOpen] = useState(false);
  const [creatingTask, setCreatingTask] = useState(false);
  const [outputFileChecked, setOutputFileChecked] = useState(false);
  const [outputFileExist, setOutputFileExist] = useState(false);

  const [additionalFields, setAdditionalFields] = useState([]);
  const [additionalFieldsCounter, setAdditionalFieldsCounter] = useState(1);

  const [additionalParameters, setAdditionalParameters] = useState({});
  const [openAddNewParameterDialog, setOpenAddNewParameterDialog] = useState(false);

  const [antConfigParameters, setAntConfigParameters] = useState([]);
  const [loadAntConfiguration, setLoadAntConfiguration] = useState(false);

  const isMobile = useSelector(state => state.app.isMobile);
  const isMenuOpen = useSelector(state => state.app.isMenuOpen);

  const { task: rerunTask } = history.location.state || {};

  const onFormSubmit = values => {
    const selectedConversionType = getSelectedConversionType();
    const task = {
      output: `${values.outputFolderPath}/${values.outputFilename}`,
      engine: selectedConversionType.engine,
      converter: values.conversionType,
      source: values.sourceFilePath,
      tag: values.tag,
    };

    if (values.cpu !== USE_DEFAULT_VALUE) {
      task.cpu = values.cpu;
    }

    if (values.ram !== USE_DEFAULT_VALUE) {
      task.ram = values.ram;
    }

    if (values.expectedResultPath) {
      task.expected_result = values.expectedResultPath;
    }

    if (selectedConversionType.engine === TASK_ENGINE_XSLT) {
      if (values.config && values.config && Object.keys(values.config).length > 0) {
        const parameters = {};

        Object.keys(additionalParameters).forEach(p => {
          if (values.config[p].value !== '') parameters[p] = values.config[p];
        });

        task.config = JSON.stringify({ parameters });
      }
    }

    dispatch(createTask(task, values.env))
      .then(res => {
        dispatch(showSuccessSnackbar(`Task ${res.id} has been created`));
        history.push(`${TASKS_ROUTE}/${res.id}`);
      })
      .catch(() => setCreatingTask(false));
  };

  const formik = useFormik({
    initialValues: generateInitValues(rerunTask || {}),
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onFormSubmit,
  });

  const onClickAddNewFieldButton = () => {
    if (creatingTask) return;

    setAdditionalFields([...additionalFields, `field_${additionalFieldsCounter}`]);
    setAdditionalFieldsCounter(additionalFieldsCounter + 1);
  };

  const onClickRemoveNewFieldButton = field => {
    if (creatingTask) return;

    setAdditionalFields(additionalFields.filter(item => item !== field));
    delete formik.values.env[field];
  };

  const onChangeAdditionalFieldValue = (field, event) => {
    formik.values.env[field] = event.target.value;
  };

  const onClickCreateButton = () => {
    if (creatingTask) return;
    setCreatingTask(true);

    formik.validateForm()
      .then(errors => {
        const customErrors = errors;

        if (formik.values.config && formik.values.config) {
          Object.keys(formik.values.config).forEach(field => {
            const { value } = formik.values.config[field];

            if (additionalParameters[field] && additionalParameters[field].paramType === 'required' && value === '') {
              if (customErrors.config) customErrors.config[field] = 'The value is required';
              else customErrors.config = { [field]: 'The value is required' };
            }
          });
        }

        if (isObjectEmpty(customErrors)) onFormSubmit(formik.values);
        else {
          formik.setTouched(customErrors);
          formik.setErrors(customErrors);

          setCreatingTask(false);
        }
      });
  };

  const tryToLoadConfigParams = (name, tag) => {
    setLoadAntConfiguration(true);

    dispatch(readFile(`repo-xsl-converters-params/${name}/release-${tag}-params.xml`))
      .then(res => {
        const paramsNames = [];
        const parsedXml = xml2js(res.data.data, { compact: true });

        const parameters = parsedXml.parameters.parameter.map(param => {
          if (paramsNames.includes(param._attributes.name)) return Promise.reject();

          return {
            required: param._attributes.required === 'yes',
            description: param._attributes.description,
            default: param._attributes.default,
            name: param._attributes.name,
            type: param._attributes.type,
          };
        });

        setAntConfigParameters(parameters);
      })
      .catch(() => setAntConfigParameters([]))
      .finally(() => setLoadAntConfiguration(false));
  };

  useEffect(() => {
    const selectedConversionType = getSelectedConversionType();
    if (selectedConversionType && selectedConversionType.engine === TASK_ENGINE_XSLT && formik.values.tag !== '') {
      tryToLoadConfigParams(selectedConversionType.name, formik.values.tag);
    } else {
      setLoadAntConfiguration(false);
      setAntConfigParameters([]);
    }
  }, [dispatch, formik.values.tag]);

  useEffect(() => {
    const params = additionalParameters;

    if (antConfigParameters.length <= 0) {
      Object.keys(params).forEach(n => {
        if (params[n].paramType !== 'custom') delete params[n];
      });

      setAdditionalParameters(params);
    } else {
      antConfigParameters.forEach(param => {
        params[param.name] = {
          paramType: param.required ? 'required' : 'optional',
          description: param.description,
          type: param.type || 'text',
          required: param.required,
          default: param.default,
          dialogOpen: false,
        };

        const value = formik.values.config[param.name]
          ? formik.values.config[param.name].value : undefined;

        formik.setFieldValue(
          `config.${param.name}`,
          { value: value || param.default || '', type: param.type || 'text' },
        );
      });
    }

    if (rerunTask) {
      if (rerunTask.config) {
        const config = JSON.parse(rerunTask.config);

        if (config.parameters) {
          Object.keys(config.parameters).forEach(paramName => {
            if (!Object.keys(params).includes(paramName)) {
              params[paramName] = {
                type: config.parameters[paramName].type,
                paramType: 'custom',
                dialogOpen: false,
              };
            }
          });
        }
      }
    }

    setAdditionalParameters(params);
  }, [antConfigParameters]);

  useEffect(() => {
    dispatch(fetchConversionTypes())
      .then(types => {
        setConversionTypes(types);

        if (rerunTask) {
          const type = types.find(v => v.name === rerunTask.converter);

          if (type && type.engine === TASK_ENGINE_XSLT) {
            tryToLoadConfigParams(rerunTask.converter, rerunTask.tag);
          }

          if (rerunTask.env) {
            const rerunEnv = Object.keys(rerunTask.env);

            setAdditionalFieldsCounter(rerunEnv.length + 1);
            setAdditionalFields(rerunEnv);
          }
        }
      });
  }, [dispatch]);

  useEffect(() => {
    if (formik.values.conversionType) {
      setFetchingConversionTags(true);
      setConversionTags([]);

      const selectedConversionType = getSelectedConversionType();
      const engine = (selectedConversionType && selectedConversionType.engine) || rerunTask.engine;
      const converter = (
        selectedConversionType && selectedConversionType.name
      ) || rerunTask.converter;

      dispatch(fetchConversionTags(engine, converter))
        .then(tags => setConversionTags(tags.sort((a, b) => -a.updated.localeCompare(b.updated))))
        .finally(() => setFetchingConversionTags(false));
    }
  }, [dispatch, formik.values.conversionType]); // eslint-disable-line

  function checkOutputFileExist(outputFolderPath, outputFilename) {
    setOutputFileChecked(false);

    dispatch(checkFileExist(`${outputFolderPath || formik.values.outputFolderPath}/${outputFilename || formik.values.outputFilename}`))
      .then(() => setOutputFileExist(true))
      .catch(() => setOutputFileExist(false))
      .finally(() => setOutputFileChecked(true));
  }

  useEffect(() => {
    if (!isObjectEmpty(rerunTask)) checkOutputFileExist();
  }, []);

  const handleFileStorageDialogSubmit = (field, selectedItem) => {
    formik.setFieldValue(field, selectedItem.id);

    if (field === 'outputFolderPath') checkOutputFileExist(selectedItem.id);
  };

  function getSelectedConversionType() {
    return conversionTypes.find(v => v.name === formik.values.conversionType);
  }

  const onClickAddNewParameterButton = () => {
    if (creatingTask) return;

    setOpenAddNewParameterDialog(true);
  };

  let addNewParameterButtonActive = false;
  const selectedConversionType = getSelectedConversionType();
  if (selectedConversionType && selectedConversionType.engine === TASK_ENGINE_XSLT) {
    addNewParameterButtonActive = true;
  }

  const handleAddNewParameter = (name, type, defaultValue) => {
    setAdditionalParameters(
      Object.assign(
        additionalParameters,
        { [name]: { type, dialogOpen: false, paramType: 'custom' } },
      ),
    );

    formik.setFieldValue(`config.${name}`, { value: defaultValue || '', type });
  };

  const onClickRemoveNewParameterButton = name => {
    if (creatingTask) return;

    delete formik.values.config[name];
    delete additionalParameters[name];

    setAdditionalParameters({ ...additionalParameters });
  };

  const setParameterFileTypeDialogValue = (name, val) => {
    additionalParameters[name].dialogOpen = val;

    setAdditionalParameters({ ...additionalParameters });
  };

  const buildHelpAntParameterText = name => {
    const param = additionalParameters[name];

    return `
      ${param.description ? `<p><b>Description</b></p><p>${param.description}</p>` : ''}
      <p><b>Required</b></p><p>${param.required ? 'Yes' : 'No'}</p>
      ${param.default ? `<p><b>Default</b></p><p>${param.default}</p>` : ''}
    `;
  };

  const renderAntParameters = (title, parameters) => {
    if (parameters.length <= 0) return '';

    return (
      <>
        { parameters.map(name => (
          <Grid container spacing={4} key={name}>
            <Grid className={classes.labelContainer} item xs={6}>
              <TextLabel>
                {`${name}${additionalParameters[name].required ? ' * ' : ' '}`}
                <InfoDisplayer
                  title={`Parameter '${name}'`}
                  message={buildHelpAntParameterText(name)}
                />
                :
              </TextLabel>
            </Grid>
            <Grid item>
              <FormControl
                size="small"
                className={classes.inputContainer}
                error={
                  formik.errors.config && Boolean(formik.errors.config[name])
                }
              >
                {formik.values.config[name].type === 'file' && (
                  <div className={classes.pathInputWrapper}>
                    <TextField
                      size="small"
                      id={name}
                      name={name}
                      variant="outlined"
                      value={formik.values.config[name].value}
                      disabled={formik.values.config[name].type === 'file'}
                      multiline={formik.values.config[name].type === 'file'}
                      onChange={event => formik.setFieldValue(`config.${name}.value`, event.target.value)}
                      error={
                        formik.errors.config && Boolean(formik.errors.config[name])
                      }
                    />

                    <Button
                      variant="contained"
                      color={
                        formik.errors.config && Boolean(formik.errors.config[name])
                          ? 'secondary' : 'default'
                      }
                      onClick={() => setParameterFileTypeDialogValue(name, true)}
                    >
                      Browse
                    </Button>

                    <FileStorageDialog
                      title={`File for the parameter '${name}'`}
                      open={additionalParameters[name].dialogOpen}
                      isRowSelectionDisabledFn={obj => obj.type === ENTITY_TYPE_FOLDER}
                      onClose={() => setParameterFileTypeDialogValue(name, false)}
                      onSubmit={val => handleFileStorageDialogSubmit(`config.${name}.value`, val)}
                    />
                  </div>
                )}

                {formik.values.config[name].type !== 'file'
                && (
                <TextField
                  size="small"
                  id={name}
                  name={name}
                  variant="outlined"
                  value={formik.values.config[name].value}
                  disabled={formik.values.config[name].type === 'file'}
                  multiline={formik.values.config[name].type === 'file'}
                  onChange={event => formik.setFieldValue(`config.${name}.value`, event.target.value)}
                  error={
                    formik.errors.config && Boolean(formik.errors.config[name])
                  }
                />
                )}

                <FormHelperText>
                  {formik.errors.config && formik.errors.config[name]}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )) }
      </>
    );
  };

  const renderCustomAntParameters = () => {
    const params = [];

    Object.keys(additionalParameters).forEach(name => {
      if (additionalParameters[name].paramType === 'custom') params.push(name);
    });

    if (params.length > 0) {
      return (
        <>
          <Grid container spacing={4} className="mt-4">
            <Grid className={classes.paramContainer} item xs={6} />
            <Grid className={classes.paramContainer} item xs={6}>
              <TextLabel>
                Custom parameters
              </TextLabel>
            </Grid>
          </Grid>

          {params.map(paramName => (
            <Grid container spacing={4} key={paramName}>
              <Grid className={classes.labelContainer} item xs={6}>
                <TextLabel>
                  Parameter
                </TextLabel>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item>
                    <FormControl size="small" className={classes.inputContainer}>
                      <div className={classes.pathInputWrapper}>
                        <TextField
                          size="small"
                          id={paramName}
                          name={paramName}
                          value={paramName}
                          variant="outlined"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">Name:</InputAdornment>,
                          }}
                          disabled
                        />

                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={() => onClickRemoveNewParameterButton(paramName)}
                        >
                          Remove
                        </Button>

                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl
                      size="small"
                      className={classes.inputContainer}
                      error={
                        formik.errors.config && Boolean(formik.errors.config[paramName])
                      }
                    >
                      {formik.values.config[paramName].type === 'file' && (
                      <div className={classes.pathInputWrapper}>
                        <TextField
                          size="small"
                          id={paramName}
                          name={paramName}
                          variant="outlined"
                          value={formik.values.config[paramName].value}
                          disabled={formik.values.config[paramName].type === 'file'}
                          multiline={formik.values.config[paramName].type === 'file'}
                          onChange={event => formik.setFieldValue(`config.${paramName}.value`, event.target.value)}
                          error={
                            formik.errors.config && Boolean(formik.errors.config[paramName])
                          }
                        />

                        <Button
                          variant="contained"
                          color={
                            formik.errors.config && Boolean(formik.errors.config[paramName])
                              ? 'secondary' : 'default'
                          }
                          onClick={() => setParameterFileTypeDialogValue(paramName, true)}
                        >
                          Browse
                        </Button>

                        <FileStorageDialog
                          open={additionalParameters[paramName].dialogOpen}
                          title={`File for the parameter '${paramName}'`}
                          isRowSelectionDisabledFn={obj => obj.type === ENTITY_TYPE_FOLDER}
                          onClose={() => setParameterFileTypeDialogValue(paramName, false)}
                          onSubmit={val => handleFileStorageDialogSubmit(`config.${paramName}.value`, val)}
                        />
                      </div>
                      )}

                      {formik.values.config[paramName].type !== 'file' && (
                        <TextField
                          size="small"
                          id={paramName}
                          name={paramName}
                          variant="outlined"
                          value={formik.values.config[paramName].value}
                          disabled={formik.values.config[paramName].type === 'file'}
                          multiline={formik.values.config[paramName].type === 'file'}
                          onChange={event => formik.setFieldValue(`config.${paramName}.value`, event.target.value)}
                          error={
                            formik.errors.config && Boolean(formik.errors.config[paramName])
                          }
                        />
                      )}

                      <FormHelperText>
                        {formik.errors.config && formik.errors.config[paramName]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </>
      );
    } return '';
  };

  const renderRequiredAndOptionalParameters = () => {
    const requiredParams = [];
    const optionalParams = [];

    Object.keys(additionalParameters).forEach(name => {
      if (additionalParameters[name].paramType === 'required') requiredParams.push(name);
      if (additionalParameters[name].paramType === 'optional') optionalParams.push(name);
    });

    return (
      <>
        {renderAntParameters(
          'Required ant parameters',
          requiredParams.sort((a, b) => b.length - a.length),
        )}

        {renderAntParameters(
          'Optional ant parameters',
          optionalParams.sort((a, b) => b.length - a.length),
        )}
      </>
    );
  };

  return (
    <>
      <div style={{
        borderBottom: '1px solid rgba(51, 51, 51, 0.3)',
        paddingBottom: '30px',
        paddingRight: '60px',
        paddingLeft: '60px',
        paddingTop: '48px',
        background: 'white',
      }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography
              variant="h4"
              style={{
                alignItems: 'center',
                display: 'flex',
                fontWeight: 500,
                lineHeight: 1,
              }}
            >
              {(!isMenuOpen || isMobile) && (
              <IconButton
                style={{
                  fontSize: '2.125rem',
                  color: MAIN_UI_COLOR,
                  marginRight: '12px',
                  padding: 0,
                }}
                onClick={() => dispatch({ type: !isMenuOpen ? MENU_OPEN : MENU_CLOSE })}
              >
                <AiOutlineMenu />
              </IconButton>
              )}

              <IconButton
                onClick={() => history.push(TASKS_ROUTE)}
                style={{
                  fontSize: '2.125rem',
                  color: MAIN_UI_COLOR,
                  marginRight: '12px',
                  padding: 0,
                }}
              >
                <HiOutlineArrowLeft />
              </IconButton>
              Create Conversion
            </Typography>
          </Grid>

          <Grid container item xs={6} justifyContent="flex-end">
            {addNewParameterButtonActive && (
            <Button
              disabled={formik.values.tag === '' || loadAntConfiguration}
              onClick={onClickAddNewParameterButton}
              variant="outlined"
              className="button"
              color="primary"
            >
              Add New Parameter
            </Button>
            )}

            <Button
              onClick={onClickAddNewFieldButton}
              style={{ marginLeft: '16px' }}
              variant="outlined"
              className="button"
              color="primary"
            >
              Add New Field
            </Button>
          </Grid>
        </Grid>
      </div>

      <div style={{
        paddingBottom: '20px',
        paddingRight: '20px',
        paddingLeft: '20px',
        paddingTop: '24px',
      }}
      >
        <form>
          <Grid container spacing={4}>
            <Grid className={classes.labelContainer} item xs={6}>
              <TextLabel>Conversion type:</TextLabel>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                size="small"
                className={classes.inputContainer}
                error={formik.touched.conversionType && Boolean(formik.errors.conversionType)}
              >
                <Select
                  disabled={conversionTypes.length === 0}
                  variant="outlined"
                  name="conversionType"
                  id="conversionType"
                  value={formik.values.conversionType}
                  onChange={event => {
                    formik.setFieldValue('conversionType', event.target.value);
                    formik.setFieldValue('tag', '');
                  }}
                >
                  {conversionTypes.sort((a, b) => ((a.name > b.name) ? 1 : -1)).map(type => (
                    <MenuItem
                      key={type.name}
                      value={type.name}
                    >
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.touched.conversionType && formik.errors.conversionType}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid className={classes.labelContainer} item xs={6}>
              <TextLabel>Release:</TextLabel>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                size="small"
                className={classes.inputContainer}
                error={formik.touched.tag && Boolean(formik.errors.tag)}
              >
                <Select
                  id="tag"
                  name="tag"
                  variant="outlined"
                  value={formik.values.tag}
                  onChange={formik.handleChange}
                  disabled={
                  conversionTags.length === 0
                  || fetchingConversionTags
                  || loadAntConfiguration
                }
                >
                  {conversionTags.map(tag => (
                    <MenuItem
                      key={tag.name}
                      value={tag.name}
                    >
                      <Typography>{tag.name}</Typography>
                      <Typography className="ml-1" variant="caption" color="textSecondary">
                        (
                        {formatDateStr(tag.updated)}
                        )
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formik.touched.tag && formik.errors.tag}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid className={classes.labelContainer} item xs={6}>
              <TextLabel>Source file path:</TextLabel>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                className={classes.inputContainer}
                error={formik.touched.sourceFilePath && Boolean(formik.errors.sourceFilePath)}
              >
                <div className={classes.pathInputWrapper}>
                  <TextField
                    multiline
                    size="small"
                    variant="outlined"
                    id="sourceFilePath"
                    name="sourceFilePath"
                    value={formik.values.sourceFilePath}
                    onChange={event => formik.setFieldValue('sourceFilePath', event.target.value, false)}
                    error={formik.touched.sourceFilePath && Boolean(formik.errors.sourceFilePath)}
                  />
                  <Button
                    variant="contained"
                    onClick={() => setSourcePathDialogOpen(true)}
                  >
                    Browse
                  </Button>
                </div>
                <FormHelperText>
                  {formik.touched.sourceFilePath && formik.errors.sourceFilePath}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid className={classes.labelContainer} item xs={6}>
              <TextLabel>Output folder path:</TextLabel>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                className={classes.inputContainer}
                error={formik.touched.outputFolderPath && Boolean(formik.errors.outputFolderPath)}
              >
                <div className={classes.pathInputWrapper}>
                  <TextField
                    id="outputFolderPath"
                    name="outputFolderPath"
                    variant="outlined"
                    size="small"
                    multiline
                    value={formik.values.outputFolderPath}
                    onChange={event => formik.setFieldValue('outputFolderPath', event.target.value, false)}
                    error={
                      formik.touched.outputFolderPath && Boolean(formik.errors.outputFolderPath)
                    }
                  />
                  <Button
                    variant="contained"
                    onClick={() => setOutputFolderDialogOpen(true)}
                  >
                    Browse
                  </Button>
                </div>
                <FormHelperText>
                  {formik.touched.outputFolderPath && formik.errors.outputFolderPath}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid className={classes.labelContainer} item xs={6}>
              <TextLabel>Output file name:</TextLabel>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                size="small"
                className={classes.inputContainer}
                error={formik.touched.outputFilename && Boolean(formik.errors.outputFilename)}
              >
                <TextField
                  id="outputFilename"
                  name="outputFilename"
                  variant="outlined"
                  size="small"
                  multiline
                  value={formik.values.outputFilename}
                  onChange={event => {
                    formik.handleChange(event);
                    checkOutputFileExist(formik.values.outputFolderPath, event.target.value);
                  }}
                  error={formik.touched.outputFilename && Boolean(formik.errors.outputFilename)}
                />
                <FormHelperText>
                  {formik.touched.outputFilename && formik.errors.outputFilename}
                </FormHelperText>

                {outputFileExist && (
                <FormHelperText style={{ color: 'red' }}>
                  <FaExclamationCircle />
                  {' '}
                  The output file already exists
                </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid className={classes.labelContainer} item xs={6}>
              <TextLabel>Comparing file:</TextLabel>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                className={classes.inputContainer}
                error={
                formik.touched.expectedResultPath && Boolean(formik.errors.expectedResultPath)
              }
              >
                <div className={classes.pathInputWrapper}>
                  <TextField
                    id="expectedResultPath"
                    name="expectedResultPath"
                    variant="outlined"
                    size="small"
                    multiline
                    value={formik.values.expectedResultPath}
                    onChange={event => formik.setFieldValue('expectedResultPath', event.target.value, false)}
                    error={
                    formik.touched.expectedResultPath && Boolean(formik.errors.expectedResultPath)
                  }
                  />
                  <Button
                    variant="contained"
                    onClick={() => setExpectedResultDialogOpen(true)}
                  >
                    Browse
                  </Button>
                </div>
                <FormHelperText>
                  {formik.touched.expectedResultPath && formik.errors.expectedResultPath}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          {antConfigParameters.length > 0
          && (
          <Grid container spacing={4} className="mt-4">
            <Grid className={classes.labelContainer} item xs={6} />
            <Grid className={classes.paramContainer} item xs={6}>
              <TextLabel>Parameters</TextLabel>
            </Grid>
          </Grid>
          )}

          {renderRequiredAndOptionalParameters()}

          {renderCustomAntParameters()}

          { additionalFields.length > 0
          && additionalFields.map(field => (
            <Grid container spacing={4} key={field}>
              <Grid className={classes.labelContainer} item xs={6}>
                <TextLabel>
                  {prepareAdditionalFieldTitle(field)}
                  :
                </TextLabel>
              </Grid>
              <Grid item xs={6}>
                <FormControl size="small" className={classes.inputContainer}>
                  <div className={classes.pathInputWrapper}>
                    <TextField
                      multiline
                      id={field}
                      name={field}
                      size="small"
                      variant="outlined"
                      value={formik.values.env[field]}
                      onChange={event => onChangeAdditionalFieldValue(field, event)}
                    />
                    <Button
                      color="secondary"
                      variant="outlined"
                      className={classes.removeButton}
                      onClick={() => onClickRemoveNewFieldButton(field)}
                    >
                      Remove
                    </Button>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          ))}

          <Grid className="my-3" container justifyContent="center" spacing={4}>
            <Grid className={classes.labelContainer} item xs={6}>
              <LoadingButton
                className={`${classes.createButton} button`}
                onClick={onClickCreateButton}
                disabled={!outputFileChecked}
                loading={creatingTask}
                variant="contained"
                color="primary"
              >
                Create
              </LoadingButton>
            </Grid>

            <Grid item xs={6}>
              <Button
                onClick={() => history.push(TASKS_ROUTE)}
                style={{ color: '#ffffff' }}
                variant="contained"
                className="button"
                color="secondary"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>

        </form>

        <FileStorageDialog
          title="Input path"
          open={sourcePathDialogOpen}
          onClose={() => setSourcePathDialogOpen(false)}
          onSubmit={val => handleFileStorageDialogSubmit('sourceFilePath', val)}
          isRowSelectionDisabledFn={obj => obj.type === ENTITY_TYPE_FOLDER}
        />

        <FileStorageDialog
          title="Output path"
          open={outputFolderDialogOpen}
          onClose={() => setOutputFolderDialogOpen(false)}
          onSubmit={val => handleFileStorageDialogSubmit('outputFolderPath', val)}
          filterObjectsFn={obj => obj.type === ENTITY_TYPE_FOLDER}
        />

        <FileStorageDialog
          title="Comparing file"
          open={expectedResultDialogOpen}
          onClose={() => setExpectedResultDialogOpen(false)}
          onSubmit={val => handleFileStorageDialogSubmit('expectedResultPath', val)}
          isRowSelectionDisabledFn={obj => obj.type === ENTITY_TYPE_FOLDER}
        />

        <AddParameterDialog
          open={openAddNewParameterDialog}
          onClose={() => setOpenAddNewParameterDialog(false)}
          alreadyCreatedParams={Object.keys(formik.values.config)}
          onSubmit={val => handleAddNewParameter(val.name, val.type, val.default)}
        />
      </div>
    </>
  );
}

export default CreateTask;
