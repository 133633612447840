import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  FormControl,
  FormControlLabel,
  Typography,
  Checkbox,
  Button,
  DialogActions,
  DialogContent,
  Grid,
} from '@material-ui/core';
import RyffineDialogTitle from '../common/RyffineDialogTitle';

const allowedFilters = [
  {
    key: 'objectTypes',
    displayValue: 'Object types',
  },
  {
    key: 'context',
    displayValue: 'Context',
  },
  {
    key: 'status',
    displayValue: 'Status',
  },
  {
    key: 'checkedOutBy',
    displayValue: 'Checked out by',
  },
  {
    key: 'lastModifiedBy',
    displayValue: 'Last modified by',
  },
  {
    key: 'lastModificationDate',
    displayValue: 'Last modification date',
  },
  {
    key: 'assignedBy',
    displayValue: 'Assigned by',
  },
  {
    key: 'assignedTo',
    displayValue: 'Assigned to',
  },
];

function toCamelCase(str) {
  let newStr = '';

  if (str) {
    const wordArr = str.split(/[-_]/g);

    wordArr.forEach(w => {
      if (newStr !== '') newStr += w.charAt(0).toUpperCase() + w.slice(1);
      else newStr += w;
    });
  } else {
    return newStr;
  }
  return newStr;
}

function ExportFiltersDialog(props) {
  const {
    open,
    onClose,
    onSubmit,
    rerunTask,
  } = props;

  const [state, setState] = useState({});

  const handleChange = filter => {
    setState({
      ...state,
      [filter]: Object.prototype.hasOwnProperty.call(state, filter) ? !state[filter] : true,
    });
  };

  const handleClose = () => {
    const filters = [];

    Object.entries(state).forEach(([key, value]) => { if (value) filters.push(key); });

    onSubmit(filters);
    onClose();
  };

  useEffect(() => {
    if (Object.keys(rerunTask || {}).length !== 0) {
      const config = JSON.parse(rerunTask.config);
      const stateValue = {};

      Object.keys(config).forEach(key => {
        const fieldName = toCamelCase(key);

        if (Array.isArray(config[key]) && config[key].length > 0) stateValue[fieldName] = true;
        else if (typeof config[key] === 'string' && (config[key] !== '')) stateValue[fieldName] = true;
      });

      if (Array.isArray(config.branches) && config.branches.length > 0) stateValue.context = true;
      if (Array.isArray(config.products) && config.products.length > 0) stateValue.context = true;

      setState(stateValue);

      const filters = [];
      Object.entries(stateValue).forEach(([key, value]) => { if (value) filters.push(key); });
      onSubmit(filters);
    }
  }, []);

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <RyffineDialogTitle onClose={() => onClose()}>
        Export filters
      </RyffineDialogTitle>

      <DialogContent style={{ padding: '10px 24px', background: '#F8F8F8' }}>
        <Grid container>
          {
            allowedFilters.map(filter => (
              <Grid xs={6} item>
                <FormControl size="small">
                  <FormControlLabel
                    label={(
                      <Typography style={{ fontSize: '14px', marginTop: '4px' }}>
                        {filter.displayValue}
                      </Typography>
                    )}
                    control={(
                      <Checkbox
                        key={filter.key}
                        onChange={() => handleChange(filter.key)}
                        style={{ marginLeft: '6px', padding: '3px' }}
                        checked={
                          Object.prototype.hasOwnProperty.call(state, filter.key)
                            ? state[filter.key] : false
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              // <Grid item xs={6}>
              //   <ListItem key={filter.key} button onClick={() => handleChange(filter.key)}>
              //     <Checkbox
              //       checked={
              //         Object.prototype.hasOwnProperty.call(state, filter.key)
              //           ? state[filter.key] : false
              //       }
              //     />
              //     <ListItemText primary={filter.displayValue} />
              //   </ListItem>
              // </Grid>
            ))
          }
        </Grid>
      </DialogContent>

      <DialogActions style={{ padding: '10px 24px 24px 24px', background: '#F8F8F8' }}>
        <Grid container>
          <Grid item xs={6}>
            <Button
              onClick={handleClose}
              color="primary"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

ExportFiltersDialog.defaultProps = {
  rerunTask: {},
};

ExportFiltersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  rerunTask: PropTypes.object, // eslint-disable-line
};

export default ExportFiltersDialog;
