import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const SEARCH_VALUE_KEY = 'searchValue';

export function useSearch(initValue) {
  const history = useHistory();
  const location = useLocation();

  const searchValue = new URLSearchParams(location.search).get(SEARCH_VALUE_KEY) || initValue || '';

  const setSearchValue = value => {
    history.push({ search: value ? `?${SEARCH_VALUE_KEY}=${value}` : '' });
  };

  useEffect(() => {
    if (searchValue !== '') {
      history.push({ search: searchValue ? `?${SEARCH_VALUE_KEY}=${searchValue}` : '' });
    }
  }, []);

  return [searchValue, setSearchValue];
}
