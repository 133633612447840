import React from 'react';
import Typography from '@material-ui/core/Typography';

function NotFound() {
  return (
    <Typography variant="h2" align="center" style={{ marginTop: 100 }}>
      404 Not found
    </Typography>
  );
}

export default NotFound;
