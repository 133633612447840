import React from 'react';
import { Typography } from '@material-ui/core';

function AdminRegression() {
  return (
    <div>
      <Typography variant="h5">
        Admin Regression page is coming soon.
      </Typography>
    </div>
  );
}

export default AdminRegression;
