import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, Link } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadFile } from '../../redux/actions/fileStorageActions';
import { FILE_STORAGE_ROUTE } from '../../core/constants';
import HtmlTooltip from './HtmlTooltip';

function DownloadLink({ filepath, displayValue }) {
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState(false);
  const linkToValue = `${FILE_STORAGE_ROUTE}/${filepath}`;

  const handleClick = () => {
    setDownloading(true);
    dispatch(downloadFile(filepath.replace('s3://', '')))
      .finally(() => setDownloading(false));
  };

  return (
    <Grid container alignItems="center">
      <Link className="mr-2" to={linkToValue} component={RouterLink} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <span
          dangerouslySetInnerHTML={{ __html: displayValue || filepath || '' }}  // eslint-disable-line
        />
      </Link>
      {!downloading
        && (
        <HtmlTooltip title="Download file">
          <GetAppIcon color="primary" onClick={handleClick} style={{ cursor: 'pointer' }} />
        </HtmlTooltip>
        )}
      {downloading && <CircularProgress size={20} />}
    </Grid>
  );
}

DownloadLink.defaultProps = {
  displayValue: undefined,
};

DownloadLink.propTypes = {
  filepath: PropTypes.string.isRequired,
  displayValue: PropTypes.string,
};

export default DownloadLink;
