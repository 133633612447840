import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import RyffineDialogTitle from '../common/RyffineDialogTitle';
import { createFolder } from '../../redux/actions/fileStorageActions';
import LoadingButton from '../common/LoadingButton';
import { showSuccessSnackbar } from '../../redux/actions/appActions';
import { parseFolderEntity } from '../../core/parsers';

const validationSchema = yup.object({
  folderName: yup.string()
    .required('The value is required')
    .test(
      'start_end_with_space',
      'The name can not start or end with spaces',
      v => !(v.startsWith(' ') || v.endsWith(' ')),
    )
    .test(
      'allowed-characters',
    <>
      The name can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'_\' and \'-\'.'}
    </>,
    v => /^[a-zA-Z0-9-._\s]+$/.test(v),
    ),
});

function CreateFolderDialog(props) {
  const dispatch = useDispatch();
  const {
    open, onClose, currentPath, onSubmit,
  } = props;
  const [errors, setErrors] = useState([]);
  const [creating, setCreating] = useState(false);

  const formik = useFormik({
    initialValues: { folderName: undefined },
    validationSchema,
    onSubmit: onFormSubmit,
  });

  const isError = Boolean(formik.errors.folderName) || errors.length > 0;

  function onFormSubmit(values) {
    setCreating(true);

    dispatch(createFolder(currentPath, values.folderName))
      .then(res => {
        const folder = parseFolderEntity(res);
        closeDialog();
        dispatch(showSuccessSnackbar(`Folder "${folder.name}" has been created`));

        onSubmit();
      })
      .catch(err => setErrors(err.errors.map(e => e.detail)))
      .finally(() => setCreating(false));
  }

  function closeDialog() {
    formik.resetForm();
    onClose();
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <RyffineDialogTitle onClose={() => closeDialog()}>
        Create Folder
      </RyffineDialogTitle>
      <form style={{ background: '#F8F8F8' }} onSubmit={formik.handleSubmit}>
        <DialogContent style={{ margin: '0px 40px 32px 40px', padding: 0 }}>
          <Grid container style={{ paddingTop: '24px' }}>
            <Typography style={{ fontSize: '16px', fontWeight: 500 }}>
              Folder name
            </Typography>
          </Grid>
          <Grid container>
            <FormControl fullWidth size="small" error={isError}>
              <TextField
                fullWidth
                size="small"
                id="folderName"
                name="folderName"
                variant="outlined"
                placeholder="Example"
                style={{ paddingTop: '4px' }}
                value={formik.values.folderName}
                onChange={event => {
                  formik.setFieldValue('folderName', event.target.value);
                  setErrors([]);
                }}
                InputProps={{ style: { borderRadius: '4px' } }}
                error={isError}
                autoFocus
              />

              <FormHelperText>
                {formik.errors.folderName && <span>{formik.errors.folderName}</span>}

                {errors.map(err => <span key={err}>{err}</span>)}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid container style={{ marginTop: '24px' }}>
            <LoadingButton
              disabled={!formik.values.folderName || formik.values.folderName === ''}
              style={{ minWidth: 85 }}
              variant="contained"
              loading={creating}
              className="button"
              color="primary"
              type="submit"
            >
              Create
            </LoadingButton>
            <Button style={{ marginLeft: '16px' }} className="button" color="primary" onClick={() => closeDialog()}>
              Cancel
            </Button>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
}

CreateFolderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateFolderDialog;
