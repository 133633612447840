import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import RyffineDialogTitle from './RyffineDialogTitle';

function ConfirmButtonDialog({
  buttonText, buttonProps, confirmTitle, confirmText, onConfirm,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();

    handleClose();
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} {...buttonProps}>
        {buttonText}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <RyffineDialogTitle onClose={handleClose}>{confirmTitle}</RyffineDialogTitle>

        <DialogContent style={{ background: '#F8F8F8' }}>
          <DialogContentText style={{ color: '#000000' }}>
            {confirmText}
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ padding: '0 24px 24px 24px', background: '#F8F8F8' }}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                style={{ color: '#ffffff' }}
                onClick={handleConfirm}
                variant="contained"
                className="button"
                color="secondary"
                autoFocus
              >
                Confirm
              </Button>
              <Button
                style={{ marginLeft: '10px' }}
                onClick={handleClose}
                className="button"
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

ConfirmButtonDialog.defaultProps = {
  buttonProps: {},
};

ConfirmButtonDialog.propTypes = {
  buttonText: PropTypes.string.isRequired,
  confirmTitle: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  buttonProps: PropTypes.object, // eslint-disable-line
};

export default ConfirmButtonDialog;
