import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import prettyBytes from 'pretty-bytes';
import { HiOutlineSearch } from 'react-icons/hi';
import {
  Box, Grid, TextField, InputAdornment,
} from '@material-ui/core';
import { listZipFile } from '../../redux/actions/fileStorageActions';
import { FileEntity } from '../../core/entities';
import Loader from '../common/Loader';
import RyffineTable from '../common/RyffineTable';
import { formatDateStr } from '../../core/services/commonService';
import PathBreadcrumbs from '../common/PathBreadcrumbs';
import { applySearch } from '../../core/services/fileStorageService';
import NameLinkCell from './NameLinkCell';
import { useQuery } from '../common/Utility';
import { MAIN_UI_COLOR } from '../../config';

const columns = [
  {
    field: 'name', headerName: 'Name', sortable: true, type: 'string',
  },
  {
    field: 'type', headerName: 'Type', sortable: true, type: 'string',
  },
  {
    field: 'size', headerName: 'Size', sortable: true, type: 'number',
  },
  { field: 'updated', headerName: 'Last modified', sortable: true },
];

const generateTableRows = (objects, onNameClick) => objects.map(obj => ({
  id: obj.id,
  name: {
    value: obj.name,
    displayValue: <NameLinkCell pathEntity={obj} onNameClick={onNameClick} />,
  },
  type: obj.type,
  size: {
    value: obj.size || 0,
    displayValue: obj.size ? prettyBytes(obj.size) : '-',
  },
  updated: {
    value: obj.updated,
    displayValue: obj.updated ? formatDateStr(obj.updated) : '-',
  },
}));

function FileDetailsZip({ file }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const { objects, loading } = useSelector(state => state.fs.zip);
  const [dirname, setDirname] = useState('/');
  const [searchValue, setSearchValue] = useState('');

  if (query.get('location') && query.get('location') !== dirname) {
    setDirname(query.get('location'));
  }

  useEffect(() => {
    if (!query.get('location')) {
      history.push(`${location.pathname}#tab=${query.get('tab')}&location=/`);
    }

    dispatch(listZipFile(file.id, query.get('location') || '/'));
  }, [dispatch, file.id, dirname]);

  const filteredObjects = useMemo(
    () => applySearch(objects, searchValue),
    [objects, searchValue],
  );

  const onLocationChange = value => {
    setDirname(value);

    history.push(`${location.pathname}#tab=${query.get('tab')}&location=${value === '' ? '/' : value}`);
  };

  const tableRows = useMemo(() => {
    const onNameClick = pathEntity => {
      if (pathEntity.isFile()) {
        return;
      }
      onLocationChange(`/${pathEntity.id}`);
    };
    return generateTableRows(filteredObjects, onNameClick);
  }, [filteredObjects]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Grid container className="pt-3">
      <Grid item xs={12} className="pt-3">
        <Box sx={{ boxShadow: 0 }}>
          <TextField
            onChange={event => setSearchValue(event.target.value)}
            placeholder="Search folder, owner, etc."
            InputProps={{
              style: {
                paddingBottom: '2.5px',
                background: '#FFFFFF',
                paddingTop: '2.5px',
              },
              startAdornment: (
                <InputAdornment position="start">
                  <HiOutlineSearch style={{ color: 'grey' }} />
                </InputAdornment>
              ),
            }}
            value={searchValue}
            variant="outlined"
            size="small"
            id="search"
            fullWidth
          />
        </Box>
      </Grid>

      <Grid className="pt-3" item xs={12} container>
        <PathBreadcrumbs
          path={dirname}
          onItemClick={value => onLocationChange(value)}
          namesMap={{ '': file.filename }}
          itemsProp={{ style: { color: MAIN_UI_COLOR, textDecoration: 'underline' } }}
        />
      </Grid>

      <Grid className="pt-3" item xs={12}>
        <RyffineTable rows={tableRows} columns={columns} tableContainerProps={{}} />
      </Grid>
    </Grid>
  );
}

FileDetailsZip.propTypes = {
  file: PropTypes.instanceOf(FileEntity).isRequired,
};

export default FileDetailsZip;
