import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';

function LoadingButton({
  loading, onClick, children, ...rest
}) {
  return (
    <Button
      onClick={() => onClick()}
      {...rest}
    >
      {loading && <CircularProgress size={20} color="inherit" />}
      {!loading && children}
    </Button>
  );
}

LoadingButton.defaultProps = {
  onClick: () => {},
};

LoadingButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default LoadingButton;
