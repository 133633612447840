import axios from 'axios';

import {
  FETCH_IAM_CURRENT_USER_START,
  FETCH_IAM_CURRENT_USER_SUCCESS,
  FETCH_IAM_CURRENT_USER_FAIL,
} from '../actionTypes';

import { actionFail } from './appActions';

import { IAM_API_URL } from '../../config';

const IAM_USERS_API_URL = `${IAM_API_URL}/users`;

export function fetchUserCurrent() {
  return function (dispatch) {
    dispatch({ type: FETCH_IAM_CURRENT_USER_START });

    return axios.get(`${IAM_USERS_API_URL}/current`)
      .then(res => {
        const payload = res.data.data;
        dispatch({ type: FETCH_IAM_CURRENT_USER_SUCCESS, payload });
      })
      .catch(error => {
        dispatch(actionFail(FETCH_IAM_CURRENT_USER_FAIL, error.response));
      });
  };
}
