import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Grid, Typography, Button, IconButton, Snackbar,
} from '@material-ui/core';
import { FiLink2 } from 'react-icons/fi';
import { MdDownload, MdDeleteForever } from 'react-icons/md';
import { AiOutlineMenu } from 'react-icons/ai';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import FileDetailsZip from './FileDetailsZip';
import FileDetailsInfo from './FileDetailsInfo';
import FileDetailsTags from './FileDetailsTags';
import FileDetailsTasks from './FileDetailsTasks';
import HashedTabs from '../common/HashedTabs';
import ConfirmButtonV2Dialog from '../common/ConfirmButtonV2';
import { FileEntity } from '../../core/entities';
import { FILE_STORAGE_ROUTE } from '../../core/constants';
import { FETCH_TASK_SUCCESS, MENU_CLOSE, MENU_OPEN } from '../../redux/actionTypes';
import {
  downloadFile,
  fetchFileTags,
  deletePathObject,
} from '../../redux/actions/fileStorageActions';
import { getTask } from '../../redux/actions/taskActions';
import { ENABLE_TASKS_SERVICE, MAIN_UI_COLOR } from '../../config';
import { showErrorSnackbar } from '../../redux/actions/appActions';
import Loader from '../common/Loader';

function FileDetails({ file }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [deleting, setDeleting] = useState(false);
  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);

  const { downloading, configuration } = useSelector(state => state.fs);
  const { protectedFolders } = configuration.obj;

  const [task, setTask] = useState({});
  const { list: tags, tagsLoading } = useSelector(state => state.fs.tags);

  const path = useLocation().pathname.substr(FILE_STORAGE_ROUTE.length);

  const isMobile = useSelector(state => state.app.isMobile);
  const isMenuOpen = useSelector(state => state.app.isMenuOpen);

  const isFileZip = file.id.endsWith('.zip');

  const handleDownload = () => dispatch(downloadFile(file.id));

  let currentPath = path;
  if (currentPath.endsWith('/')) currentPath = currentPath.slice(0, -1);

  const backPath = `${FILE_STORAGE_ROUTE}${currentPath.substring(0, currentPath.lastIndexOf('/'))}/`;

  const tabs = [
    { key: 'details', label: 'Details', component: <FileDetailsInfo file={file} task={task} /> },
    { key: 'tags', label: 'Tags', component: <FileDetailsTags tags={tags} loading={tagsLoading} /> },
  ];

  if (ENABLE_TASKS_SERVICE) {
    tabs.push({ key: 'tasks', label: 'Tasks', component: <FileDetailsTasks file={file} /> });
  }

  if (isFileZip) {
    tabs.push({ key: 'file-list', label: 'File list', component: <FileDetailsZip file={file} /> });
  }

  if (copySnackbarOpen) setTimeout(() => setCopySnackbarOpen(false), 2500);

  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopySnackbarOpen(true);
  };

  useEffect(() => {
    dispatch({ type: FETCH_TASK_SUCCESS, payload: undefined });

    dispatch(fetchFileTags(file.id));
  }, [dispatch, file.id]);

  useEffect(() => {
    if (ENABLE_TASKS_SERVICE) {
      let id;
      tags.forEach(t => {
        if (t.key === 'TASK_ID') id = t.value;
      });

      if (id) getTask(id).then(setTask);
      else setTask({});
    }
  }, [dispatch, tags]);

  const onConfirmDelete = () => {
    setDeleting(true);

    deletePathObject(file.id).then(() => history.push(backPath))
      .catch(err => dispatch(showErrorSnackbar(err.data.error)))
      .finally(() => setDeleting(false));
  };

  if (deleting || configuration.loading) {
    return <Loader />;
  }

  return (
    <>
      <div style={{
        paddingBottom: '30px',
        paddingRight: '60px',
        paddingLeft: '60px',
        paddingTop: '48px',
        background: 'white',
      }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h4"
              style={{
                overflowWrap: 'break-word',
                alignItems: 'start',
                display: 'flex',
                fontWeight: 500,
                lineHeight: 1,
              }}
            >
              {(!isMenuOpen || isMobile) && (
              <IconButton
                style={{
                  fontSize: '2.125rem',
                  color: MAIN_UI_COLOR,
                  marginRight: '12px',
                  padding: 0,
                }}
                onClick={() => dispatch({ type: !isMenuOpen ? MENU_OPEN : MENU_CLOSE })}
              >
                <AiOutlineMenu />
              </IconButton>
              )}

              <IconButton
                onClick={() => history.push(backPath)}
                style={{
                  fontSize: '2.125rem',
                  color: MAIN_UI_COLOR,
                  marginRight: '12px',
                  padding: 0,
                }}
              >
                <HiOutlineArrowLeft />
              </IconButton>

              <span style={{ fontSize: '28px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {currentPath.split('/').slice(-1).pop()}
              </span>
            </Typography>
          </Grid>

          <Grid className="buttons-container" container item xs={12} sm={6} justifyContent="flex-end">
            <Button
              onClick={handleCopyButtonClick}
              variant="outlined"
              className="button"
              color="primary"
            >
              <IconButton className="outlined-button-icon"><FiLink2 /></IconButton>
              Copy link
            </Button>

            <Button
              onClick={handleDownload}
              disabled={downloading}
              variant="contained"
              className="button"
              color="primary"
            >
              <IconButton className="contained-button-icon">
                <MdDownload style={{ margin: '0 0 0 -5px' }} />
              </IconButton>
              Download
            </Button>

            <ConfirmButtonV2Dialog
              buttonProps={{
                disabled: protectedFolders.indexOf((path.startsWith('/') ? path.slice(1) : path).split('/')[0]) > -1 || !configuration.obj.allowsToDelete,
                style: { color: '#ffffff' },
                variant: 'contained',
                className: 'button',
                color: 'secondary',
              }}
              buttonText={(
                <>
                  <IconButton className="contained-button-icon">
                    <MdDeleteForever style={{ margin: '0 0 0 -5px' }} />
                  </IconButton>
                  Remove
                </>
              )}
              confirmText="Permanently delete this file?"
              onConfirm={() => onConfirmDelete()}
              confirmButtonText="Remove"
            />
          </Grid>
        </Grid>
      </div>

      <Box fullWidth>
        <HashedTabs tabs={tabs} />
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message="The URL link of this file has been copied!"
        onClose={() => setCopySnackbarOpen(false)}
        open={copySnackbarOpen}
      />
    </>
  );
}

FileDetails.propTypes = {
  file: PropTypes.instanceOf(FileEntity).isRequired,
};

export default FileDetails;
