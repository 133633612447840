import React from 'react';
import { capitalize } from 'lodash';
import prettyBytes from 'pretty-bytes';
import { formatDateStr, isInclude } from './commonService';
import NameLinkCell from '../../components/fileStorage/NameLinkCell';

export const FS_TABLE_COLUMNS = [
  {
    field: 'name', headerName: 'Name', sortable: true, type: 'string',
  },
  {
    field: 'type', headerName: 'Type', sortable: true, type: 'string',
  },
  {
    field: 'size', headerName: 'Size', sortable: true, type: 'number',
  },
  { field: 'updated', headerName: 'Last modified', sortable: true },
];

export const generateTableRows = (objects, onNameClick, isRowSelectionDisabledFn) => objects.map(
  obj => ({
    id: obj.id,
    selectionDisabled: (
      isRowSelectionDisabledFn && isRowSelectionDisabledFn(obj)
    ) || false,
    name: {
      value: obj.name,
      displayValue: (
        <NameLinkCell pathEntity={obj} onNameClick={onNameClick} maxCharacters={165} />
      ),
    },
    type: capitalize(obj.type),
    size: {
      value: obj.size || 0,
      displayValue: obj.size ? prettyBytes(obj.size) : '-',
    },
    updated: {
      value: obj.updated,
      displayValue: obj.updated ? formatDateStr(obj.updated) : '-',
    },
  }),
);

export const applySearch = (objects, searchValue) => objects.filter(
  obj => isInclude(obj.name, searchValue),
);

export const parsePath = path => {
  const lastDelimiterIndex = path.lastIndexOf('/');

  return [
    path.substring(0, lastDelimiterIndex),
    path.substring(lastDelimiterIndex + 1),
  ];
};
