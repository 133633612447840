import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, Tab, Tabs } from '@material-ui/core';
import TabPanel from './TabPanel';
import { useQuery } from './Utility';

const getTabIndex = (key = null, tabs) => {
  // if key equals null return 0
  if (!key) {
    return 0;
  }

  const index = tabs.findIndex(tab => tab.key === key);

  return index === -1 ? 0 : index;
};

function HashedTabs({ tabs }) {
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();

  const handleTabChange = (_, newTabValue) => {
    history.push(`${location.pathname}#tab=${tabs[newTabValue].key}`);
  };

  return (
    <>
      <Box
        style={{ background: 'white', padding: '0 60px 0 60px', borderBottom: '1px solid rgba(51, 51, 51, 0.3)' }}
      >
        <Tabs
          textColor="primary"
          indicatorColor="primary"
          value={getTabIndex(query.get('tab'), tabs)}
          onChange={handleTabChange}
        >
          {tabs.map(item => (<Tab key={`${item.label}_${item.key}`} label={item.label} style={{ fontSize: '18px', textTransform: 'none' }} />))}
        </Tabs>
      </Box>

      <div style={{ paddingRight: '60px', paddingLeft: '60px' }}>
        {tabs.map((item, index) => (
          <TabPanel key={item.key} value={getTabIndex(query.get('tab'), tabs)} index={index}>
            {item.component}
          </TabPanel>
        ))}
      </div>
    </>
  );
}

HashedTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default HashedTabs;
