import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogContent, Grid,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import RyffineDialogTitle from '../common/RyffineDialogTitle';
import PathBreadcrumbs from '../common/PathBreadcrumbs';
import { ACTION_TYPE_RADIO } from '../../core/constants';
import { applySearch, generateTableRows, FS_TABLE_COLUMNS } from '../../core/services/fileStorageService';
import RyffineTable from '../common/RyffineTable';
import { fetchObjectsFromPath } from '../../redux/actions/fileStorageActions';
import SearchField from '../common/SearchField';

const filterObjects = (objects, searchValue, filterFn) => applySearch(
  (filterFn ? objects.filter(filterFn) : objects),
  searchValue,
);

function FileStorageDialog(props) {
  const {
    title,
    open,
    onClose,
    onSubmit,
    filterObjectsFn,
    isRowSelectionDisabledFn,
  } = props;
  const dispatch = useDispatch();
  const [path, setPath] = useState('');
  const [selected, setSelected] = useState([]);
  const [selectedObject, setSelectedObject] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const { objects, loading } = useSelector(state => state.fs);

  useEffect(() => {
    if (open) {
      setSearchValue('');
      dispatch(fetchObjectsFromPath(path));
    }
  }, [dispatch, path, open]);

  const filteredObjects = useMemo(
    () => filterObjects(objects, searchValue, filterObjectsFn),
    [objects, searchValue, filterObjectsFn],
  );

  const tableRows = useMemo(
    () => {
      const onNameClick = pathEntity => {
        if (pathEntity.isFile()) {
          setSelectedObject(pathEntity.id);
        } else {
          setPath(`/${pathEntity.id}`);
        }
      };
      return generateTableRows(filteredObjects, onNameClick, isRowSelectionDisabledFn);
    },
    [filteredObjects, isRowSelectionDisabledFn],
  );

  const handleChangeSelectionState = selectedIds => {
    const selectedObjects = filteredObjects.filter(obj => selectedIds.includes(obj.id));
    setSelected(selectedObjects);

    if (selectedObjects.length === 1) {
      setSelectedObject(selectedObjects[0].id);
    }
  };

  const handleSubmit = () => {
    if (selected.length === 1) {
      onSubmit(selected[0]);
    }
    closeDialog();
  };

  function closeDialog() {
    onClose();
  }

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <RyffineDialogTitle onClose={() => closeDialog()}>
        {title}
      </RyffineDialogTitle>
      <DialogContent>

        <Grid container>
          <PathBreadcrumbs
            path={path}
            onItemClick={value => setPath(`/${value}`)}
            namesMap={{ '': 'File storage' }}
          />
        </Grid>

        <Grid className="mt-3" container>
          <Grid item xs={6}>
            <SearchField
              value={searchValue}
              onChange={event => setSearchValue(event.target.value)}
              placeholder="Find objects by name"
            />
          </Grid>

          <Grid className="mt-3" item xs={12}>
            <RyffineTable
              rows={tableRows}
              columns={FS_TABLE_COLUMNS}
              loading={loading}
              initSorting={{ field: 'updated', order: 'desc' }}
              actionType={ACTION_TYPE_RADIO}
              onSelectionChange={handleChangeSelectionState}
              selectedRowIds={selectedObject ? [selectedObject] : []}
            />
          </Grid>
        </Grid>

        <Grid className="buttons-container my-4" container justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            disabled={selected.length === 0}
            onClick={handleSubmit}
          >
            OK
          </Button>
          <Button color="default" variant="contained" onClick={() => closeDialog()}>
            Cancel
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

FileStorageDialog.defaultProps = {
  isRowSelectionDisabledFn: null,
  filterObjectsFn: null,
};

FileStorageDialog.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isRowSelectionDisabledFn: PropTypes.func,
  filterObjectsFn: PropTypes.func,
};

export default FileStorageDialog;
