import {
  FETCH_IAM_CURRENT_USER_FAIL,
  FETCH_IAM_CURRENT_USER_START,
  FETCH_IAM_CURRENT_USER_SUCCESS,
} from '../actionTypes';

import {
  parseUserEntity,
  parseErrorEntity,
} from '../../core/parsers';

const initialState = {
  current_user: {
    user: {},
    loading: false,
    errors: [],
  },
};

export default function iamReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_IAM_CURRENT_USER_START:
      return {
        ...state,
        current_user: {
          user: {},
          loading: true,
          errors: [],
        },
      };

    case FETCH_IAM_CURRENT_USER_SUCCESS:
      return {
        ...state,
        current_user: {
          user: parseUserEntity(action.payload),
          loading: false,
          errors: [],
        },
      };

    case FETCH_IAM_CURRENT_USER_FAIL:
      return {
        ...state,
        current_user: {
          user: {},
          loading: false,
          errors: action.payload.map(parseErrorEntity),
        },
      };

    default:
      return state;
  }
}
