import { USER_SIGN_IN, USER_SIGN_OUT } from '../actionTypes';
import { clearAccessToken } from '../../core/services/authService';

export function userSignIn(payload) {
  return function (dispatch) {
    dispatch({ type: USER_SIGN_IN, payload });
  };
}

export function userSignOut() {
  return function (dispatch) {
    clearAccessToken();
    dispatch({ type: USER_SIGN_OUT });
  };
}
