import { deleteCookie, getCookie, setCookie } from './cookieService';

const TOKEN_KEY_NAME = 'token';

export function isUserAuthenticated() {
  return !!getToken();
}

export function setToken(token, expiresIn) {
  setCookie(TOKEN_KEY_NAME, token, { path: '/', 'max-age': expiresIn });
}

export function getToken() {
  return getCookie(TOKEN_KEY_NAME);
}

export function clearAccessToken() {
  deleteCookie(TOKEN_KEY_NAME);
}
