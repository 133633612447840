import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  Button,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useFormik } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { MdRefresh, MdOutlineDateRange } from 'react-icons/md';
import Placeholder from '../common/Placeholder';
import RyffineDialogTitle from '../common/RyffineDialogTitle';
import { fetchConversionTags, fetchConversionTypes } from '../../redux/actions/taskActions';
import { TASK_CONVERTER_NORMALIZATION, TASK_ENGINE_PYTHON } from '../../core/entities';
import {
  TYPE_NORMALIZATION, TYPE_CONVERSION, TYPES_FILTER, STATUSES_FILTER, DATE_FORMAT,
} from '../../core/constants';

const useStyles = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        padding: '8px 0px 8px 16px',
      },
      '& .MuiButtonBase-root': {
        padding: '8px',
      },
      background: '#FFFFFF',
      borderRadius: '4px',
      padding: 0,
    },
  },
});

const initialValues = () => ({
  normalizationTag: '',
  conversionType: '',
  conversionTag: '',
  dateFrom: null,
  dateTo: null,
  statuses: [],
  types: [],
});

function FiltersDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentDate = new Date();
  const lastMonthDate = new Date();
  lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

  const {
    init, open, onClose, onSubmit,
  } = props;

  const [conversionTags, setConversionTags] = useState([]);
  const [conversionTypes, setConversionTypes] = useState([]);
  const [normalizationTags, setNormalizationTags] = useState([]);

  const formik = useFormik({
    initialValues: initialValues(),
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => formik.setValues({ ...initialValues(), ...init }), [init]);

  function getSelectedConversionType() {
    return conversionTypes.find(v => v.name === formik.values.conversionType);
  }

  useEffect(() => {
    if (formik.values.conversionType !== '' && conversionTypes.length > 0) {
      setConversionTags([]);

      const selectedConversionType = getSelectedConversionType();
      const engine = selectedConversionType && selectedConversionType.engine;
      const converter = selectedConversionType && selectedConversionType.name;

      dispatch(fetchConversionTags(engine, converter))
        .then(tags => setConversionTags(tags.sort((a, b) => -a.updated.localeCompare(b.updated))));
    } else {
      setConversionTags([]);
    }
  }, [dispatch, formik.values.conversionType, open]); // eslint-disable-line\

  useEffect(() => {
    setNormalizationTags([]);

    dispatch(fetchConversionTypes()).then(types => setConversionTypes(types));

    dispatch(fetchConversionTags(TASK_ENGINE_PYTHON, TASK_CONVERTER_NORMALIZATION))
      .then(tags => setNormalizationTags(tags.sort((a, b) => -a.updated.localeCompare(b.updated))));
  }, [dispatch]);

  const onClickSelectType = type => {
    const { types } = formik.values;

    if (types.indexOf(type) === -1) types.push(type);
    else {
      types.splice(types.indexOf(type), 1);

      switch (type) {
        case TYPE_NORMALIZATION:
          formik.setValues(Object.assign(formik.values, { normalizationTag: '' }));
          break;

        case TYPE_CONVERSION:
          formik.setValues(
            Object.assign(formik.values, { conversionType: '', conversionTag: '' }),
          );
          setConversionTags([]);
          break;

        default:
          break;
      }
    }

    formik.setFieldValue('types', types);
  };

  const onClickSelectStatus = status => {
    const { statuses } = formik.values;

    if (statuses.indexOf(status) === -1) statuses.push(status);
    else statuses.splice(statuses.indexOf(status), 1);

    formik.setFieldValue('statuses', statuses);
  };

  const onClickApply = () => {
    const filters = {};

    Object.keys(formik.values).forEach(key => {
      const value = formik.values[key];

      if (typeof (value) === 'string' && (value || value !== '')) filters[key] = value;
      else if (value instanceof Date && !Number.isNaN(value)) filters[key] = value;
      else if (Array.isArray(value) && value.length > 0) filters[key] = value;
    });

    onSubmit(filters);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={() => onClose()} open={open}>
      <RyffineDialogTitle onClose={() => onClose()}>
        Filter Tasks
      </RyffineDialogTitle>

      <DialogContent style={{ padding: '10px 24px', background: '#F8F8F8' }}>
        <Grid container>
          {/* TASK TYPE */}
          <Grid item xs={12}>
            <Typography style={{ fontSize: '16px', fontWeight: 500 }}>
              Task Type
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} style={{ margin: '0px -8px 20px -8px' }}>
              {TYPES_FILTER.map(key => (
                <Grid item key={key}>
                  <Button
                    color={formik.values.types && formik.values.types.includes(key) ? 'primary' : 'default'}
                    onClick={() => onClickSelectType(key)}
                    className="status-button"
                    variant="contained"
                  >
                    {key}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* CONVERSION TYPE AND RELEASE */}
          {formik.values.types && formik.values.types.includes(TYPE_CONVERSION)
            && (
            <>
              <Grid item xs={6}>
                <Typography style={{ paddingRight: '12px', fontSize: '16px', fontWeight: 500 }}>
                  Conversion type
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography style={{ paddingLeft: '12px', fontSize: '16px', fontWeight: 500 }}>
                  Conversion release
                </Typography>
              </Grid>

              <Grid item xs={6} style={{ paddingRight: '12px', marginBottom: '20px' }}>
                <TextField
                  inputProps={{
                    renderValue: formik.values.conversionType !== '' ? undefined : () => <Placeholder>Select</Placeholder>,
                    displayEmpty: true,
                  }}
                  disabled={conversionTypes.length <= 0}
                  value={formik.values.conversionType}
                  onChange={formik.handleChange}
                  className={classes.root}
                  name="conversionType"
                  id="conversionType"
                  variant="outlined"
                  fullWidth
                  select
                >
                  <MenuItem value="" style={{ minHeight: '36px' }}>{' '}</MenuItem>
                  {conversionTypes.sort((a, b) => ((a.name > b.name) ? 1 : -1)).map(type => (
                    <MenuItem key={type.name} value={type.name}>{type.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6} style={{ paddingLeft: '12px', marginBottom: '20px' }}>
                <TextField
                  inputProps={{
                    renderValue: formik.values.conversionTag !== '' ? undefined : () => <Placeholder>Select</Placeholder>,
                    displayEmpty: true,
                  }}
                  disabled={conversionTags.length <= 0}
                  value={formik.values.conversionTag}
                  onChange={formik.handleChange}
                  className={classes.root}
                  name="conversionTag"
                  id="conversionTag"
                  variant="outlined"
                  fullWidth
                  select
                >
                  <MenuItem value="" style={{ minHeight: '36px' }}>{' '}</MenuItem>
                  {conversionTags.map(tag => (
                    <MenuItem
                      key={tag.name}
                      value={tag.name}
                    >
                      {tag.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </>
            )}

          {/* NORMALIZATION RELEASE */}
          {formik.values.types && formik.values.types.includes(TYPE_NORMALIZATION)
            && (
            <>
              <Grid item xs={12}>
                <Typography style={{ fontSize: '16px', fontWeight: 500 }}>
                  Normalization release
                </Typography>
              </Grid>

              <Grid item xs={6} style={{ paddingRight: '12px', marginBottom: '20px' }}>
                <TextField
                  inputProps={{
                    renderValue: formik.values.normalizationTag !== '' ? undefined : () => <Placeholder>Select</Placeholder>,
                    displayEmpty: true,
                  }}
                  disabled={normalizationTags.length <= 0}
                  value={formik.values.normalizationTag}
                  onChange={formik.handleChange}
                  className={classes.root}
                  name="normalizationTag"
                  id="normalizationTag"
                  variant="outlined"
                  fullWidth
                  select
                >
                  <MenuItem value="" style={{ minHeight: '36px' }}>{' '}</MenuItem>
                  {normalizationTags.map(t => (
                    <MenuItem key={t.name} value={t.name}>{t.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            </>
            )}

          {/* STATUS */}
          <Grid item xs={12}>
            <Typography style={{ fontSize: '16px', fontWeight: 500 }}>
              Status
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} style={{ margin: '0px -8px 20px -8px' }}>
              {Object.keys(STATUSES_FILTER).map(key => (
                <Grid item key={key}>
                  <Button
                    color={formik.values.statuses && formik.values.statuses.includes(key) ? 'primary' : 'default'}
                    onClick={() => onClickSelectStatus(key)}
                    className="status-button"
                    variant="contained"
                  >
                    {STATUSES_FILTER[key]}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* DATE FROM and DATE TO */}
          <Grid item xs={6}>
            <Typography style={{ paddingRight: '12px', fontSize: '16px', fontWeight: 500 }}>
              Date from
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography style={{ paddingLeft: '12px', fontSize: '16px', fontWeight: 500 }}>
              Date to
            </Typography>
          </Grid>

          <Grid item xs={6} style={{ paddingRight: '12px' }}>
            <FormControl size="small" fullWidth>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  maxDate={formik.values.dateTo ? formik.values.dateTo : currentDate}
                  onChange={v => formik.setFieldValue('dateFrom', v)}
                  keyboardIcon={<MdOutlineDateRange />}
                  value={formik.values.dateFrom}
                  className={classes.root}
                  minDate={lastMonthDate}
                  inputVariant="outlined"
                  format={DATE_FORMAT}
                  placeholder="Select"
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>

          <Grid item xs={6} style={{ paddingLeft: '12px' }}>
            <FormControl size="small" fullWidth>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  minDate={formik.values.dateFrom ? formik.values.dateFrom : lastMonthDate}
                  onChange={v => formik.setFieldValue('dateTo', v)}
                  keyboardIcon={<MdOutlineDateRange />}
                  value={formik.values.dateTo}
                  className={classes.root}
                  inputVariant="outlined"
                  maxDate={currentDate}
                  format={DATE_FORMAT}
                  placeholder="Select"
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{ padding: '10px 24px 24px 24px', background: '#F8F8F8' }}>
        <Grid container>
          <Grid item xs={6}>
            <Button
              onClick={onClickApply}
              variant="contained"
              className="button"
              color="primary"
            >
              Apply
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              onClick={() => onClose()}
              className="button"
              color="primary"
            >
              Cancel
            </Button>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <Button
              onClick={() => formik.setValues(initialValues())}
              className="button"
              variant="outlined"
              color="primary"
            >
              <IconButton className="outlined-button-icon"><MdRefresh size={18} /></IconButton>
              Reset
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

FiltersDialog.defaultProps = { init: {} };

FiltersDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  init: PropTypes.object,
};

export default FiltersDialog;
