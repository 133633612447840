import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
  TextField,
  ListItem,
  List,
  Button,
  Grid,
  Box,
  makeStyles,
} from '@material-ui/core';
import { HiOutlineSearch } from 'react-icons/hi';
import { AiOutlineFolder } from 'react-icons/ai';
import { MdTextSnippet, MdOutlineArrowForwardIos } from 'react-icons/md';
import Loader from '../common/Loader';
import RyffineDialogTitle from '../common/RyffineDialogTitle';
import { applySearch } from '../../core/services/fileStorageService';
import { getObjectsFromPath, getPathEntity } from '../../redux/actions/fileStorageActions';
import { ENTITY_TYPE_FOLDER, ENTITY_TYPE_FILE } from '../../core/entities';

const useStyles = makeStyles(() => ({
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 150,
    minHeight: 150,
    height: 150,
  },
  notchedOutline: {
    border: '1px solid #bdbdbd',
  },
}));

function filterObjects(objects, searchValue) {
  return applySearch(objects, searchValue).sort((a, b) => b.type.localeCompare(a.type));
}

function SelectParameterFile(props) {
  const classes = useStyles();

  const {
    open, field, onClose,
  } = props;

  const [allowApply, setAllowApply] = useState(false);

  const [value, setValue] = useState('');

  const [path, setPath] = useState('/');
  const [search, setSearch] = useState('');

  const [loading, setLoading] = useState(false);
  const [objects, setObjects] = useState([]);

  useEffect(() => {
    if (open) {
      setLoading(true);

      getObjectsFromPath(path.startsWith('/') ? path : `/${path}`)
        .then(items => setObjects(items.sort((a, b) => b.type.localeCompare(a.type))))
        .then(() => setLoading(false));
    } else setObjects([]);
  }, [open, path]);

  useEffect(() => {
    if (open) {
      const split = value.split('/');

      const searchValue = split[split.length - 1];
      const pathValue = `${split.slice(0, -1).join('/')}`;

      if (path !== '/' || pathValue !== path) setPath(pathValue);
      setSearch(searchValue);
    }
  }, [value, open]);

  useEffect(() => {
    if (open) {
      setAllowApply(false);

      getPathEntity(value)
        .then(res => {
          if (res.status === 200 && res.data.data.type === ENTITY_TYPE_FILE) {
            setAllowApply(true);
          }
        });
    }
  }, [value, open]);

  const filteredObjects = filterObjects(objects, search);

  const onSubmit = () => {
    field.onChange(value);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={() => onClose()} open={open}>
      <RyffineDialogTitle onClose={() => onClose()}>
        {`Select file for '${field.label}' parameter`}
      </RyffineDialogTitle>

      <DialogContent style={{ padding: '10px 24px 0px 24px', background: '#F8F8F8' }}>
        <Grid
          container
          style={{
            marginBottom: '20px',
            borderRadius: '4px',
            background: 'white',
          }}
        >
          <Grid item xs={12}>
            <Box sx={{ boxShadow: 0 }}>
              <TextField
                onChange={event => {
                  setValue(event.target.value);
                }}
                placeholder="Search a file or a folder by name"
                InputProps={{
                  style: {
                    borderRadius: '4px 4px 0 0',
                    background: '#FFFFFF',
                  },
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <HiOutlineSearch style={{ color: 'grey' }} />
                    </InputAdornment>
                  ),
                  fullWidth: true,
                }}
                variant="outlined"
                value={value}
                size="small"
                fullWidth
              />
            </Box>
          </Grid>

          <Grid item xs={12} style={{ border: '1px solid rgba(51, 51, 51, 0.3)', borderTop: 0, borderRadius: '0 0 4px 4px' }}>
            {loading
              && (
              <Grid style={{ minHeight: 150, maxHeight: 150, height: 150 }}>
                <Loader />
              </Grid>
              )}

            {!loading
              && (
              <List className={classes.list}>
                {!loading && filteredObjects.map(obj => {
                  if (obj.type === ENTITY_TYPE_FOLDER) {
                    return (
                      <ListItem key={`item-${obj.name}`}>
                        <Grid container onClick={() => setValue(`${obj.id}/`)}>
                          <Grid item xs={11}>
                            <AiOutlineFolder style={{ marginRight: '5px' }} />
                            {obj.name}
                          </Grid>

                          <Grid container item xs={1} justifyContent="flex-end">
                            <IconButton style={{ fontSize: '14px', padding: 0 }}>
                              <MdOutlineArrowForwardIos />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </ListItem>
                    );
                  }

                  return (
                    <ListItem key={`item-${obj.name}`}>
                      <Grid container onClick={() => setValue(obj.id)}>
                        <MdTextSnippet style={{ marginRight: '5px' }} />
                        {obj.name}
                      </Grid>
                    </ListItem>
                  );
                })}
              </List>
              )}

          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{ padding: '0px 24px 24px 24px', background: '#F8F8F8' }}>
        <Grid container>
          <Grid item xs={6}>
            <Button
              onClick={() => onSubmit(value)}
              disabled={!allowApply}
              variant="contained"
              className="button"
              color="primary"
            >
              Apply
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              onClick={() => onClose()}
              className="button"
              color="primary"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

SelectParameterFile.propTypes = {
  onClose: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

export default SelectParameterFile;
