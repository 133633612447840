import {
  CLOSE_SNACKBAR,
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
} from '../actionTypes';

export function actionFail(actionType, ErrorResponse) {
  return function (dispatch) {
    const DEFAULT_ERROR = {
      status: '500',
      detail: 'Something went wrong',
    };

    const errors = (
      ErrorResponse && ErrorResponse.data && ErrorResponse.data.errors
    ) || [DEFAULT_ERROR];

    if (actionType) {
      dispatch({ type: actionType, payload: errors });
    }

    errors.forEach(error => {
      dispatch(showErrorSnackbar(error.detail));
    });
  };
}

export function showErrorSnackbar(errorMessage, options = {}) {
  return function (dispatch) {
    dispatch(enqueueSnackbar({
      message: errorMessage,
      options: {
        ...options,
        variant: 'error',
      },
    }));
  };
}

export function showSuccessSnackbar(message, options = {}) {
  return function (dispatch) {
    dispatch(enqueueSnackbar({
      message,
      options: {
        ...options,
        variant: 'success',
      },
    }));
  };
}

export function enqueueSnackbar(notification) {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
}

export function closeSnackbar(key) {
  return {
    type: CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
  };
}

export function removeSnackbar(key) {
  return {
    type: REMOVE_SNACKBAR,
    key,
  };
}
